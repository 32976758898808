import React from "react";
import { Link } from "react-router-dom";

function SixthSection({ sixthsection }) {
  return (
    <div className="sixth-section-hp">
      <div className="main-wrapper homepage-sixth-section">
        <h1>{sixthsection.heading}</h1>
        {/* <p>
          Parents care more about whether something will be loved by the kids
          rather than whether they are collectibles.
        </p> */}
        <img src={sixthsection.image.url} alt="img" />
        <p>{sixthsection.paragraph}</p>
      </div>
      <Link
        to={
          window.location.pathname.includes("homeschooling")
            ? "/homeschooling/subscribe"
            : "/subscribe"
        }
        className="hoverable"
      >
        GET STARTED!
      </Link>
    </div>
  );
}

export default SixthSection;
