export const HeaddingBar = ({ title, description }) => {
  return (
    <div className='as_container as_title_banner'>
      <h3 className='as_headingbar_title'>{title}</h3>
      {description ? (
        <p className='as_p as_headingbar_desc'>{description}</p>
      ) : null}
    </div>
  );
};
