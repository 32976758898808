import React, { useRef } from 'react';

function FourthSection({ promise1, section4 }) {
  return (
    <div
      className='fourth-section-pk'
      style={{ background: `#bb3f6e ` }}
      ref={promise1}
    >
      <div className='main-wrapper pk-fourth-section'>
        <div className='fourth-section-pk-text'>
          <h1>{section4.subheader}</h1>
          <h2>{section4.header}</h2>
          <h3>{section4.header2}</h3>
          <p className='cooper_promise_pg'>{section4.header3}</p>
          <img src={section4.gif} alt='img' className='mobile-animation' />

          <h3>
            {section4.header4} <span>{section4.header4yellow}</span>
          </h3>
          <h3>{section4.header5}</h3>
        </div>
        <img src={section4.gif} alt='img' className='desktop-animation' />
      </div>
    </div>
  );
}

export default FourthSection;
