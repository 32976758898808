import { useState } from 'react';
import box1 from '../Components/Shared/Assets/box1.png';
import box2 from '../Components/Shared/Assets/box2.png';
import box3 from '../Components/Shared/Assets/box3.png';
import ref from '../Components/Shared/Assets/ref.svg';
import { BsArrowRightShort } from 'react-icons/bs';
function LandingPage(props) {
  // console.log("landing13",props.landing.acf.steps)
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabs = [
    {
      id: 1,
      label: 'Every Month',
      links: [
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=355&subscriptionPlanId=249',
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=353&subscriptionPlanId=247',
        ' https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=351&subscriptionPlanId=245',
      ],
      content: [
        {
          image: box1,
          title: 'Homeschool Flexi Plan',
          price: '69.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          free: '+ Free Shipping!',
          content:
            'Cancel ANY TIME. Get a new Cooper Kit learning adventure according to your chosen delivery frequency. Billed at time of each shipment.<span> Savings of $10.</span>',
        },
        {
          image: box2,
          title: 'Homeschool Pay-as-you-go Yearly Plan',
          price: '64.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          bonus: '+ Bonus Free Cooper Kit!',
          free: '+ Free Shipping!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency, billed in 4 discounted installments.<span> Savings of $30.</span>',
        },
        {
          image: box3,
          title: 'Homeschool Super Saver Prepay Plan',
          price: '59.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          pick: '+ Pick your Kit themes!',
          bonus: '+ Bonus Free Cooper Kit!',
          free: '+ Free Shipping!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency. Prepay of $239.96 less show discount.<span> Savings of $50.</span>',
        },
      ],
    },
    {
      id: 2,
      label: 'Every 2 Month',
      links: [
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=355&subscriptionPlanId=249',
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=353&subscriptionPlanId=247',
        ' https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=351&subscriptionPlanId=245',
      ],
      content: [
        {
          title: 'Homeschool Flexi Plan',
          image: box1,
          price: '69.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          free: '+ Free Shipping!',
          content:
            'Cancel ANY TIME. Get a new Cooper Kit learning adventure according to your chosen delivery frequency. Billed at time of each shipment.<span> Savings of $10.</span>',
        },
        {
          title: 'Homeschool Pay-as-you-go Yearly Plan',
          image: box2,
          price: '64.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          bonus: '+ Bonus Free Cooper Kit!',
          free: '+ Free Shipping!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency, billed in 4 discounted installments.<span> Savings of $30.</span>',
        },
        {
          image: box3,
          title: 'Homeschool Super Saver Prepay Plan',
          price: '59.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          bonus: '+ Bonus Free Cooper Kit!',
          pick: '+ Pick your Kit themes!',
          free: '+ Free Shipping!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency. Prepay of $239.96 less show discount.<span> Savings of $50.</span>',
        },
      ],
    },
    {
      id: 3,
      label: 'Every 3 Month',
      links: [
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=355&subscriptionPlanId=249',
        'https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=353&subscriptionPlanId=247',
        ' https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=351&subscriptionPlanId=245',
      ],
      content: [
        {
          title: 'Homeschool Flexi Plan',
          image: box1,
          price: '69.99 per kit',
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          free: '+ Free Shipping!',
          content:
            'Cancel ANY TIME. Get a new Cooper Kit learning adventure according to your chosen delivery frequency. Billed at time of each shipment.<span> Savings of $10.</span>',
        },
        {
          title: 'Homeschool Pay-as-you-go Yearly Plan',
          price: '64.99 per kit',
          image: box2,
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          bonus: '+ Bonus Free Cooper Kit!',
          free: '+ Free Shipping!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency, billed in 4 discounted installments.<span>Savings of $30.</span> ',
        },
        {
          title: 'Homeschool Super Saver Prepay Plan',
          price: '59.99 per kit',
          image: box3,
          firstOff: '+ $10 off your first kit! Use Code IOWA',
          bonus: '+ Bonus Free Cooper Kit!',
          free: '+ Free Shipping!',
          pick: '+ Pick your Kit themes!',
          content:
            'Renewing Discounted subscription of 4 Cooper Kit learning adventures, sent according to your chosen delivery frequency. Prepay of $239.96 less show discount.<span>Savings of $50.</span> ',
        },
      ],
    },
  ];

  return (
    <div>
      <ul className='landing-tabs'>
        {tabs.map((tab) => (
          <div>
            <p className={activeTab === tab.id ? 'selected-landing' : ''}>
              Selected
            </p>
            <li
              key={tab.id}
              className={activeTab === tab.id ? 'active-landing' : ''}
              onClick={() => handleTabClick(tab.id)}
            >
              {tab.label}
            </li>
          </div>
        ))}
      </ul>
      {tabs.map((tab) => {
        return (
          activeTab === tab.id && (
            <div key={tab.id} className='landing-box'>
              {tab.content.map((item, index) => (
                <a key={index} href={tab.links[index]} target='_blank'>
                  <img src={ref} alt='#' className='ref' />
                  <div>
                    <img src={item.image} alt='box' />
                    <div className='landig-arrow'>
                      <h1>{item.title}</h1>
                      <BsArrowRightShort />
                    </div>
                    <div>
                      <p>{item.price}</p>
                      <div className='landin-description'>
                        <p>{item.firstOff}</p>
                        <p>{item.bonus}</p>
                        <p>{item.free}</p>
                        <p>{item.pick}</p>
                      </div>
                      <p>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      </p>
                      {/* <p>{}</p> */}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          )
        );
      })}
    </div>
  );
}

export default LandingPage;
