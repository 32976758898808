import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import '../Components/Shared/Styles/peekinside.css';
import FirstSection from '../Components/PeekInside/FirstSection';
import SecondSection from '../Components/PeekInside/SecondSection';
import ThirdSection from '../Components/PeekInside/ThirdSection';
import FourthSection from '../Components/PeekInside/FourthSection';
import FifthSection from '../Components/PeekInside/FifthSection';
import ReuseableSection from '../Components/PeekInside/ReuseableSection';
import EighthSection from '../Components/PeekInside/EighthSection';
import NinthSection from '../Components/PeekInside/NinthSection';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Footer from '../Components/Shared/Footer';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import Loader from '../Components/Shared/Loader';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function PeekInside({ peekinside }) {
  const promise1 = useRef(null);
  const promise2 = useRef(null);
  const promise3 = useRef(null);
  const promise4 = useRef(null);
  const promise5 = useRef(null);
  const promise6 = useRef(null);
  const promise7 = useRef(null);
  const promise8 = useRef(null);

  return peekinside ? (
    <>
      <Helmet>
        <title>Cooper and Kid: Our 8 Promises in Every Kids Box</title>
        <meta
          name='title'
          content='Cooper and Kid: Our 8 Promises in Every Kids Box'
        />
        <meta
          name='description'
          content='Each Cooper Kit is a story-based, themed family adventure explored through learning projects, science, crafts, games, storybooks, cooking, social emotional development and more!'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='privacyPolicy'
        containerClasses='cookie'
        debug={false}
        style={{
          background: '#5c2945',
          fontSize: '12px',
          padding: '10px 0px',
          height: 'fit-content',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '13px',
          backgroundColor: '#90dae5',
          padding: '10px 50px',
          borderRadius: '5px',
        }}
        expires={false}
      >
        <p className='cookie-paragraph'>
          {' '}
          We use cookies to provide website functionality, to analyze traffic on
          our Cooper & Kid Site, personalize content, serve targeted
          advertisements and to enable social media functionality. Our Privacy
          Policy provides more information and explains how to update your
          privacy policy. View our{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable cookie-link'
          >
            privacy-policy
          </Link>
        </p>
      </CookieConsent>
      <Navbar />

      <FirstSection section1={peekinside.acf.firstsection} />
      <SecondSection
        promise1={promise1}
        promise2={promise2}
        promise3={promise3}
        promise4={promise4}
        promise5={promise5}
        promise6={promise6}
        promise7={promise7}
        promise8={promise8}
        section2={peekinside.acf.promises}
      />
      <ThirdSection section3={peekinside.acf.secondsection} />
      <FourthSection promise1={promise1} section4={peekinside.acf.promise1} />
      <FifthSection promise2={promise2} section5={peekinside.acf.promise2} />
      {/* <SixthSection promise3={promise3} /> */}
      <ReuseableSection
        mainClass={'seventh-section-pk'}
        id={''}
        textClass={'seventh-section-pk-text'}
        promiseNr={peekinside.acf.promise3.subheading}
        title={peekinside.acf.promise3.heading}
        parapgraph={peekinside.acf.promise3.paragraph}
        image={peekinside.acf.promise3.image}
        promise={promise3}
        mainWrapper={'main-wrapper pk-seventh-section-inverse'}
        imageClass={'reusable-section-image'}
      />
      <ReuseableSection
        mainClass={'seventh-section-pk'}
        id={''}
        textClass={'seventh-section-pk-text'}
        promiseNr={peekinside.acf.promise4.subheading}
        title={peekinside.acf.promise4.heading}
        parapgraph={peekinside.acf.promise4.paragraph}
        image={peekinside.acf.promise4.image}
        promise={promise4}
        mainWrapper={'main-wrapper pk-seventh-section'}
      />
      <ReuseableSection
        mainClass={'seventh-section-pk seventh-inverse'}
        id={''}
        textClass={'seventh-section-pk-text'}
        promiseNr={peekinside.acf.promise5.subheading}
        title={peekinside.acf.promise5.heading}
        parapgraph={peekinside.acf.promise5.paragraph}
        image={peekinside.acf.promise5.image}
        promise={promise5}
        mainWrapper={'main-wrapper pk-seventh-section-inverse'}
      />
      <EighthSection promise={promise6} section6={peekinside.acf.promise6} />
      <ReuseableSection
        mainClass={'seventh-section-pk'}
        id={''}
        textClass={'seventh-section-pk-text'}
        promiseNr={peekinside.acf.promise7.subheading}
        title={peekinside.acf.promise7.heading}
        parapgraph={peekinside.acf.promise7.paragraph}
        image={peekinside.acf.promise7.image}
        promise={promise7}
        mainWrapper={'main-wrapper pk-seventh-section'}
      />
      <ReuseableSection
        mainClass={'seventh-section-pk seventh-inverse'}
        id={''}
        textClass={'seventh-section-pk-text'}
        promiseNr={peekinside.acf.promise8.subheading}
        title={peekinside.acf.promise8.heading}
        parapgraph={peekinside.acf.promise8.paragraph}
        image={peekinside.acf.promise8.image}
        promise={promise8}
        mainWrapper={'main-wrapper pk-seventh-section-inverse'}
      />
      <NinthSection lastsection={peekinside.acf.lastsection} />
      <Footer />
    </>
  ) : (
    <>
      <Loader />
    </>
  );
}

export default PeekInside;
