import React, { useState } from 'react';
import '../Components/Shared/Styles/fathersday2018.css';
import image from '../Components/Shared/Assets/Keep Pages/daddinosaur.png';
import image1 from '../Components/Shared/Assets/Keep Pages/Dad-Carry-Homepage1-skinny.jpg';
import image2 from '../Components/Shared/Assets/Keep Pages/5-Core-Activities-V2.jpg';
import image3 from '../Components/Shared/Assets/Keep Pages/Book-collection-Recolored.jpg';
import image4 from '../Components/Shared/Assets/Keep Pages/b8c48d4f2e4bad10463baac5cdd36b1f.jpg';
import image5 from '../Components/Shared/Assets/Keep Pages/Cookbook-v2.jpg';
import image6 from '../Components/Shared/Assets/Keep Pages/Medallions-Recolored.jpg';
import image7 from '../Components/Shared/Assets/Keep Pages/Quiet-on-the-Set-Box-Transparent-Background.png';
import image8 from '../Components/Shared/Assets/Keep Pages/Quiet-on-the-Set-Touched-Up-cropped.jpg';
import image9 from '../Components/Shared/Assets/Keep Pages/Machines-in-Motion-300-dpi-cleaned.jpg';
import image10 from '../Components/Shared/Assets/Keep Pages/Machines-in-Motion-Box-Transparent-Background-Recolored.jpg';
import image11 from '../Components/Shared/Assets/Keep Pages/Deep-Box-Transparent-Background.png';
import image12 from '../Components/Shared/Assets/Keep Pages/Deep-V3-Kit-Photo-cropped.jpg';
import check from '../Components/Shared/Assets/Keep Pages/check.svg';
import image13 from '../Components/Shared/Assets/Keep Pages/End-thin.jpg';
import image14 from '../Components/Shared/Assets/Keep Pages/Got-It-Resized.jpg';
import image15 from '../Components/Shared/Assets/Keep Pages/Deep-Resized.jpg';
import Logo from '../Components/Shared/Assets/Logo.svg';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function FathersDay2018() {
  const [open, setOpen] = useState({
    1: false,
    2: false,
    3: false,
  });
  return (
    <>
      <OfferBanner data={bannerData} />
      <div className='first-f2018' id='f2018-top'>
        <h1>DON’T LET THE KIDS GIVE DAD A BORING GIFT</h1>
        <p>
          A Father’s Day Gift That Actually Has Something To Do About Being A
          Father
        </p>
        <img src={image} alt='img' />
      </div>
      <div className='second-f2018'>
        <h1>IT'S THE GIFT FOR DADS</h1>
        <p>
          So it’s Father’s Day soon, and you’re looking for a gift for the dad
          in your family. Well, don’t worry, we’ve got you covered. In each
          award-winning Cooper Kit, we include a variety of activities for dads
          and kids to do together! We design, test, and curate them all to bring
          you maximum father-child engagement time. We do the work so that they
          can spend their time focusing on the kids.
        </p>
        <div className='second-2-f2018'>
          <img src={image1} alt='img' />
          <div className='second-2-f2018-text'>
            <h1>YOU'VE GOT IT ALL COVERED WITH THE COOPER KIT</h1>
            <p>
              The Cooper Kit is an award-winning, themed activity kit for dads
              and kids to do together. Every 3 months you’ll get over 6 hours of
              adventuresome and meaningful moments to teach character building
              to your kids (ages 5 to 12). The kits can be done with multiple
              children, and they’re gender neutral so whether you have sons or
              daughters or both, the Cooper Kit will make him the coolest dad on
              the block.
            </p>
          </div>
        </div>
      </div>
      <div className='third-f2018'>
        <h1>SO, WHAT'S IN A COOPER KIT?</h1>
        <h2>FIVE CORE PROJECTS IN EVERY KIT</h2>
        <img src={image2} alt='image' />
        <p>
          There's a collection of <strong>FIVE</strong> core awesome activities
          and projects in <strong>every kit</strong>. Among those, you'll always
          find building projects, creative expression projects, and more! Plus
          the box always turns into a toy. That's right. Even the BOX turns into
          a toy. Plus there's other items found in every box such as:
        </p>

        <div className='f2018-main-card'>
          <div className='f2018-card'>
            <img src={image3} alt='img' />
            <p>
              <strong>Bedtime Storybooks</strong>
              <br></br> Kids like being read to, even at older ages. Every
              Cooper Kit includes a book to increase that bonding time.
            </p>
          </div>
          <div className='f2018-card'>
            <img src={image4} alt='img' />
            <p>
              <strong>Character Building</strong>
              <br></br> Integrated into the activities are teachable moments to
              give kids virtues, values, and life skills.
            </p>
          </div>
          <div className='f2018-card'>
            <img src={image5} alt='img' />
            <p>
              {' '}
              <strong>Themed Cookbook</strong>
              <br></br> Take the theme into the kitchen to even make meal times
              part of the fun!
            </p>
          </div>
          <div className='f2018-card'>
            <img src={image6} alt='img' />
            <p>
              <strong>Collectible Medallions</strong>
              <br></br> Found in each Cooper Kit are military grade challenge
              coins for your kids to remember your time together for ever and
              ever.
            </p>
          </div>
        </div>
        <div className='fifth-f2018'>
          <h1>ALL THESE EXPERIENCES FOR ONLY $65!</h1>
          <p>
            Every 3 months you get the latest and greatest Cooper Kit shipped
            straight to your front door! Whew, takes the work out of shopping
            and it arrives just in time for Father's Day, back to school, the
            holiday season, and Spring Break. Could it get any better? Why, yes,
            it can because you get to pick your first Cooper Kit now, too!
            <br></br>
            <br></br>
            That's right. You get to choose from 3 of our most recent kits which
            you'd like to be your first. After that, you'll receive the newest
            surprise kit every 3 months.
          </p>
        </div>
        <div className='sixth-f2018'>
          <h1>HERE ONLY, CHOOSE YOUR FIRST COOPER KIT</h1>
          <div className='sixth-f2018-main-card'>
            <div className='sixth-f2018-card'>
              <div className='sixth-f2018-card-header'>
                <img src={image7} alt='img' />
                <p>Quiet On The Set</p>
              </div>
              <img src={image8} alt='img' />
              <button
                className='button-to-top'
                onClick={() => {
                  setOpen({ ...open, 1: !open[1], 2: false, 3: false });
                }}
              >
                Learn More About Quiet on the Set
              </button>
              <div
                className={
                  open[1] ? 'f2018-show-div transition' : 'f2018-show-div'
                }
                style={open[1] ? { display: 'flex' } : { display: 'none' }}
              >
                <p>
                  Dive in to the silent film era to help Jack Hammer, the howler
                  monkey, find a way of entertaining people without waking up
                  the neighbors.{' '}
                </p>
                <ul>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Make a
                    Old-School Family Movie Together
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Teach
                    Your Kids About Confidence
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Build a
                    Remote Controlled Magic Lantern Projector
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Box
                    Turns into a Scene Clapper
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Learn
                    How to (Safely) Do Stunts!
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> And a
                    whole lot more!
                  </li>
                </ul>
                <img src={image13} className='f2018-show-div-img' alt='img' />
              </div>
              <a
                href='https://aw135.infusionsoft.app/app/orderFormRendering/showLockDownOrderForm?styleThemeId=124&trackInventory=false'
                className='f2018-button1'
                target='_blank'
              >
                Get Quiet on the Set!
              </a>
            </div>
            <div className='sixth-f2018-card'>
              <div className='sixth-f2018-card-header'>
                <img src={image10} alt='img' />
                <p>Machines In Motion</p>
              </div>
              <img src={image9} alt='img' />
              <button
                href=''
                className='button-to-top button-to-top2'
                onClick={() => {
                  setOpen({ ...open, 1: false, 2: !open[2], 3: false });
                }}
              >
                Learn More About Machines in Motion
              </button>
              <div
                className={
                  open[2]
                    ? 'f2018-show-div f2018-show-div2 transition'
                    : 'f2018-show-div f2018-show-div2'
                }
                style={open[2] ? { display: 'flex' } : { display: 'none' }}
              >
                <p>
                  Help Elmer, the Amish mad-scientist mouse, use simple machines
                  and complex contraptions to try to win the dessert contest at
                  the county fair.{' '}
                </p>
                <ul>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Combine
                    All Activities Together to Create a Rube Goldberg Machine
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Teach
                    Your Kids About Simplicity vs. Complexity
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' />
                    Build a Spinning Music Box Ferris Wheel!
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Box
                    Turns into a Zipline Cable Car
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> And a
                    whole lot more!
                  </li>
                </ul>
                <img src={image14} className='f2018-show-div-img' alt='img' />
              </div>
              <a
                href='https://aw135.infusionsoft.app/app/orderFormRendering/showLockDownOrderForm?styleThemeId=124&trackInventory=false'
                className='f2018-button1 f2018-button2'
                target='_blank'
                rel='noreferrer'
              >
                Get Machines in Motion
              </a>
            </div>
            <div className='sixth-f2018-card'>
              <div className='sixth-f2018-card-header'>
                <img src={image11} alt='img' />
                <p>Deep</p>
              </div>
              <img src={image12} alt='img' />
              <button
                href=''
                className='button-to-top button-to-top3'
                onClick={() => {
                  setOpen({ ...open, 1: false, 2: false, 3: !open[3] });
                }}
              >
                Learn More About Deep
              </button>
              <div
                className={
                  open[3]
                    ? 'f2018-show-div f2018-show-div3 transition'
                    : 'f2018-show-div f2018-show-div3'
                }
                style={open[3] ? { display: 'flex' } : { display: 'none' }}
              >
                <p>
                  Witness a crazy talent show at the bottom of the ocean. Learn
                  about all the bizarre sea creatures while they face off
                  head-to-head.
                </p>
                <ul>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Explore
                    the Weirdest Fish Under the Sea
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Teach
                    Your Kids How to Be Adaptable
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' />
                    Build a Light Up Anglerfish Hat
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> Box
                    Turns into a Submarine Drone Mobile
                  </li>
                  <li>
                    <img src={check} className='check-img' alt='img' /> And a
                    whole lot more!
                  </li>
                </ul>
                <img src={image15} className='f2018-show-div-img' alt='img' />
              </div>
              <a
                href='https://aw135.infusionsoft.app/app/orderFormRendering/showLockDownOrderForm?styleThemeId=124&trackInventory=false'
                className='f2018-button1 f2018-button3'
                target='_blank'
                rel='noreferrer'
              >
                Get Deep!
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='f2018-footer'>
        <img src={Logo} alt='img' />
      </div>
    </>
  );
}

export default FathersDay2018;
