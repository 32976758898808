import React from "react";

function EleventhSection({ eleventhsection }) {
  return (
    <div className="elevent-section-hp">
      <div className="main-wrapper homepage-eleventh-section">
        {eleventhsection.map((e) => (
          <img src={e.image} alt="award" />
        ))}
      </div>
    </div>
  );
}

export default EleventhSection;
