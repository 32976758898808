import image1 from '../../assets/slider/slider_01.png';
import image2 from '../../assets/slider/slider_02.png';
import image3 from '../../assets/slider/slider_03.png';
import image4 from '../../assets/slider/slider_04.png';
import image5 from '../../assets/slider/slider_05.png';
import image6 from '../../assets/slider/slider_06.png';
import image7 from '../../assets/slider/slider_07.png';
import image8 from '../../assets/slider/slider_08.png';
import image9 from '../../assets/slider/slider_09.png';
import image10 from '../../assets/slider/slider_10.png';
import image11 from '../../assets/slider/slider_11.png';
import image12 from '../../assets/slider/slider_12.png';
// import coin from '../../assets/slider/what-you_coins.png';
// import storyBook from '../../assets/slider/what-you_story-book.jpg';
// import dinnerRecipies from '../../assets/slider/what-you_dinner-recipies.jpg';
// import activityGuide from '../../assets/slider/what-you_activity-guide.jpg';
// import emotional from '../../assets/slider/what-you_emotional.png';
// import themed1 from '../../assets/slider/what-you_themed-project-1.jpg';
// import themed2 from '../../assets/slider/what-you_themed-project-2.jpg';
// import themed3 from '../../assets/slider/what-you_themed-project-3.png';

export const slides = [
  { image: image1, title: 'Time Travel CooperKit' },
  { image: image2 },
  { image: image3 },
  { image: image4 },
  { image: image5 },
  { image: image6 },
  { image: image7 },
  { image: image8 },
  { image: image9 },
  { image: image10 },
  { image: image11 },
  { image: image12 },
];
export const title = ['Inside the Kit'];

export const slidesContent = [
  {
    title: '',
    desc: "Each Cooper Kit is a fun story-based adventure that comes to life through educational projects and games for the whole family.  Explore what's inside a Cooper Kit!",
  },
  {
    title: 'Activity Guide',
    desc: "Beautifully designed booklet that introduces you to the kit's adventure storyline, cartoon characters and activity instructions",
  },
  {
    title: 'Themed Project: 1 of 5',
    desc: 'Your adventure unfolds through 5 projects that follow the storyline of your Cooper Kit adventure',
  },
  {
    title: 'Themed Project: 2 of 5',
    desc: 'Each Cooper Kit includes BUILDING projects',
  },
  {
    title: 'Themed Project: 3 of 5',
    desc: 'Each Cooper Kit includes CREATIVE projects',
  },
  {
    title: 'Themed Project: 4 of 5',
    desc: 'Cooper Kits include COLLABORATIVE projects and games',
  },
  {
    title: 'Themed Project: 5 of 5',
    desc: "Each Cooper Kit includes BOX ENGINEERING where the actual shipping box is converted into something new! It's fun, ingenious and earth friendly!",
  },
  {
    title: 'Character and Social Emotional Building',
    desc: 'Each kit has an interwoven character development or social emotional topic with parenting tips for implementing at home.',
  },
  {
    title: 'Bedtime Storybook',
    desc: "Companion children's book that highlights the kit theme or feature character or social emotional lesson",
  },
  {
    title: 'Themed Dinner Recipes',
    desc: 'Take the adventure to the kitchen with 4 themed dinner recipes',
  },
  {
    title: 'Digital Resources',
    desc: 'Access kit specific digital resources, such as printables, additional activities, educational videos and parenting articles',
  },
  {
    title: 'Collectible Medallion',
    desc: "To commemorate these great family moments, each Cooper Kit contains a unique, challenge coin medallion. Kids collect and love 'em!",
  },
];
