import fieldTripDesktop from './images/field-trip-desktop.mp4';
import kidsGpsDesktop from './images/4g-kids-desktop.png';
import meaningfullFamilyTimeDesktop from './images/meaningfull-family-time-desktop.jpg';
import oneBoxDesktop from './images/one-box-desktop.mp4';

import meaningfullFamilyTimeTab from './images/meaningfull-family-time-tab.jpg';
import fieldTripTab from './images/field-trip-tab.mp4';
import kidsGpsTab from './images/4g-kids-tab.jpg';
import oneBoxTab from './images/one-box-tab.mp4';

import fieldTripMobile from './images/field-trip-mobile.mp4';
import kidsGpsMobile from './images/4g-kids-mobile.png';
import oneBoxMobile from './images/one-box-mobile.mp4';
import meaningfullFamilyTimeMobile from './images/meaningfull-family-time-mobile.jpg';

export const slidesDesktopData = [
  { image: meaningfullFamilyTimeDesktop },
  { video: fieldTripDesktop },
  { image: kidsGpsDesktop },
  { video: oneBoxDesktop },
];

export const slidesTabData = [
  { image: meaningfullFamilyTimeTab },
  { video: fieldTripTab },
  { image: kidsGpsTab },
  { video: oneBoxTab },
];

export const slidesMobileData = [
  { image: meaningfullFamilyTimeMobile },
  { video: fieldTripMobile },
  { image: kidsGpsMobile },
  { video: oneBoxMobile },
];

export const slidesDataContent = [
  {
    title: 'Meaningful Family Time. Delivered Every 3 Months.',
    desc: 'Meet the Cooper Kit, a high-quality, quarterly subscription box designed for families to do together.',
    button: 'Subscribe now',
    link: '/subscribe',
  },
  {
    title: "It's Like a Field Trip in a Box!",
    desc: 'Each box is a fun story-based adventure that comes to life through educational projects and games.',
    button: 'Subscribe now',
    link: '/subscribe',
  },
  {
    title: 'Free 4G Kids GPS Watch in your 1st box!',
    desc: 'Hurry - limited time offer.',
    button: 'Claim Offer',
    link: '/subscribe',
  },
  {
    title: 'One box = Many Awesome Projects.',
    desc: 'Packed with 5 fascinating STEM and creativity activities, books, recipes and more! Plus each explores character building and social emotional lessons.',
    button: 'Get Started',
    link: '/subscribe',
  },
];
