import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/order.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function MajorEuAsiaLatin() {
  return (
    <div className='white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='content'>
        <div className='inside'>
          {/* Start the Loop. */}
          <div className='post' id='post-15476'>
            {/* Display the Title as a link to the Post's permalink. */}
            <h2 className='pageTitle'>
              {/* <a
                href="https://cooperkid.com/major-eu-asia-latin/"
                rel="bookmark"
                title="Permanent Link to Major EU, Asia, & Latin America Order Forms"
              >
                Major EU, Asia, &amp; Latin America Order Forms
              </a> */}
            </h2>
            <div className='content'>
              <div id='tve_flt' className='tve_flt tcb-style-wrap'>
                <div
                  id='tve_editor'
                  className='tve_shortcode_editor tar-main-content'
                  data-post-id={15476}
                >
                  <div className='thrv_wrapper thrv_heading' data-tag='h1'>
                    <h1 style={{ textAlign: 'center' }}>
                      MAJOR EU, ASIA, LATIN COUNTRY ORDER FORMS
                    </h1>
                  </div>
                  <div
                    className='thrv_wrapper thrv_text_element tve_empty_dropzone'
                    data-css='tve-u-163035a4fe2'
                  >
                    <p data-css='tve-u-163035a3e1b'>
                      <strong>
                        These order forms are for the following countries:
                      </strong>{' '}
                      Brazil, Croatia, Estonia, Finland, Gibraltar, Hong Kong,
                      Hungary, Ireland, Israel, Latvia, Lithuania, Luxembourg,
                      Malaysia, Malta, New Zealand, Portugal, Singapore, Sweden,
                      &amp; Switzerland.
                    </p>
                  </div>
                  <div className='thrv_wrapper thrv-columns'>
                    <div className='tcb-flex-row tcb--cols--3'>
                      <div className='tcb-flex-col'>
                        <div className='tcb-col tve_empty_dropzone'>
                          <div
                            className='thrv_wrapper tve_image_caption'
                            data-css='tve-u-162daedeb11'
                          >
                            <span
                              className='tve_image_frame'
                              style={{ width: '100%' }}
                            >
                              <img
                                className='tve_image wp-image-13837 colorbox-15476'
                                alt=''
                                width={300}
                                height={299}
                                title='1-quarterly-subscription-cooper-kid-300x299'
                                data-id={13837}
                                src='https://cms.cooperkid.com/wp-content/uploads/2022/06/1-quarterly-subscription-cooper-kid-300x299-5.jpg'
                                style={{ width: '100%' }}
                                sizes='(max-width: 300px) 100vw, 300px'
                              />
                            </span>
                          </div>
                          <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                            <p style={{ textAlign: 'center' }}>
                              <strong>
                                <span
                                  data-css='tve-u-1630357c1d5'
                                  style={{ fontSize: 18 }}
                                >
                                  $65 per Quarter
                                </span>
                                <br />
                                <span
                                  data-css='tve-u-16303579d58'
                                  style={{ fontSize: 16 }}
                                >
                                  $31 Shipping.
                                </span>
                              </strong>
                            </p>
                            <p style={{ textAlign: 'center' }}>
                              Recurring subscription where you get 1 Cooper Kit
                              every 3 months billed quarterly. Cancel anytime.
                            </p>
                            <p
                              data-css='tve-u-1630357e3f5'
                              style={{ textAlign: 'center' }}
                            >
                              <strong>Total price: $96 per quarter.</strong>
                              <br />
                              <strong>​</strong>
                            </p>
                          </div>
                          <div
                            className='thrv_wrapper thrv-button'
                            data-css='tve-u-162daf144f5'
                            data-tcb_hover_state_parent=''
                            data-button-style='ghost'
                          >
                            <a
                              href='https://aw135.infusionsoft.com/app/orderForms/major-euasialatin-international-quarterly'
                              className='tcb-button-link'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <span className='tcb-button-texts'>
                                <span
                                  className='tcb-button-text thrv-inline-text'
                                  data-css='tve-u-1632baf5670'
                                >
                                  <strong>BUY NOW</strong>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='tcb-flex-col'>
                        <div className='tcb-col tve_empty_dropzone'>
                          <div
                            className='thrv_wrapper tve_image_caption'
                            data-css='tve-u-162daee1640'
                          >
                            <span className='tve_image_frame'>
                              <img
                                className='tve_image wp-image-14143 colorbox-15476'
                                alt=''
                                width={300}
                                height={337}
                                title='yearly-best value'
                                data-id={14143}
                                src='https://cms.cooperkid.com/wp-content/uploads/2022/06/yearly-best-value-4.png'
                                sizes='(max-width: 300px) 100vw, 300px'
                              />
                            </span>
                          </div>
                          <div
                            className='tcb-clear'
                            data-css='tve-u-162f9b5e0a3'
                          >
                            <div
                              className='thrv_wrapper thrv_text_element tve_empty_dropzone'
                              data-css='tve-u-162f9b5da34'
                            >
                              <p style={{ textAlign: 'center' }}>
                                <strong>
                                  <span
                                    data-css='tve-u-16303581047'
                                    style={{ fontSize: 18 }}
                                  >
                                    $59 per Kit.
                                  </span>
                                  <br />
                                  <span
                                    data-css='tve-u-16303582d57'
                                    style={{ fontSize: 16 }}
                                  >
                                    $31 Shipping per Kit.
                                  </span>
                                </strong>
                              </p>
                              <p style={{ textAlign: 'center' }}>
                                Recurring subscription where you get 1 Cooper
                                Kit every 3 months billed yearly.
                              </p>
                              <p style={{ textAlign: 'center' }}>
                                <strong>
                                  <span
                                    data-css='tve-u-163035891ad'
                                    style={{ fontSize: 18 }}
                                  >
                                    Total price: $360 per year
                                  </span>
                                  <br />
                                  <span
                                    data-css='tve-u-1630358a462'
                                    style={{ fontSize: 16 }}
                                  >
                                    (paid at once)
                                  </span>
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div
                            className='thrv_wrapper thrv-button'
                            data-css='tve-u-162daf170d6'
                            data-tcb_hover_state_parent=''
                            data-button-style='ghost'
                          >
                            <a
                              href='https://aw135.infusionsoft.com/app/orderForms/major-euasialatin-international-yearly'
                              className='tcb-button-link'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <span className='tcb-button-texts'>
                                <span
                                  className='tcb-button-text thrv-inline-text'
                                  data-css='tve-u-1632baf68ce'
                                >
                                  <strong>BUY NOW</strong>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className='tcb-flex-col'>
                        <div className='tcb-col tve_empty_dropzone'>
                          <div
                            className='thrv_wrapper tve_image_caption'
                            data-css='tve-u-162daee45f9'
                          >
                            <span className='tve_image_frame'>
                              <img
                                className='tve_image wp-image-13839 colorbox-15476'
                                alt=''
                                width={300}
                                height={299}
                                title='gift-one-year-cooper-kid-300x299'
                                data-id={13839}
                                src='https://cms.cooperkid.com/wp-content/uploads/2022/06/gift-one-year-cooper-kid-300x299-6.jpg'
                                sizes='(max-width: 300px) 100vw, 300px'
                              />
                            </span>
                          </div>
                          <div
                            className='tcb-clear'
                            data-css='tve-u-162f9b5e0a3'
                          >
                            <div
                              className='thrv_wrapper thrv_text_element tve_empty_dropzone'
                              data-css='tve-u-162f9b5da34'
                            >
                              <p style={{ textAlign: 'center' }}>
                                <em>​</em>
                                <strong>
                                  <span
                                    data-css='tve-u-16303584de1'
                                    style={{ fontSize: 18 }}
                                  >
                                    $59 per Kit.
                                  </span>
                                  <br />
                                  <span
                                    data-css='tve-u-16303583ddb'
                                    style={{ fontSize: 16 }}
                                  >
                                    $31 Shipping per Kit.
                                  </span>
                                </strong>
                              </p>
                              <p style={{ textAlign: 'center' }}>
                                One-time fee, no subscription. Receive or gift 1
                                Cooper Kit every 3 months for 1 year.
                              </p>
                              <p style={{ textAlign: 'center' }}>
                                <strong>
                                  <span
                                    data-css='tve-u-16303586d5b'
                                    style={{ fontSize: 18 }}
                                  >
                                    Total price: $384 per year
                                  </span>
                                  <br />
                                  <span
                                    data-css='tve-u-16303587f84'
                                    style={{ fontSize: 16 }}
                                  >
                                    (paid at once)
                                  </span>
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div
                            className='thrv_wrapper thrv-button on_hover'
                            data-css='tve-u-162daf02280'
                            data-tcb_hover_state_parent=''
                            data-button-style='ghost'
                          >
                            <a
                              href='https://aw135.infusionsoft.com/app/orderForms/major-euasialatin-international-yok'
                              className='tcb-button-link'
                              target='_blank'
                              rel='noreferrer'
                            >
                              <span className='tcb-button-texts'>
                                <span
                                  className='tcb-button-text thrv-inline-text'
                                  data-css='tve-u-162daefffe7'
                                >
                                  <strong>BUY NOW</strong>
                                </span>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='thrv_wrapper thrv_heading' data-tag='h2'>
                    <h2 style={{ textAlign: 'center' }}>
                      WHEN WILL I GET MY KITS?
                    </h2>
                  </div>
                  <div
                    className='thrv_wrapper thrv_text_element tve_empty_dropzone'
                    data-css='tve-u-163035a4fe2'
                  >
                    <p data-css='tve-u-163035a3e1b'>
                      You’ll get our newest surprise kit shipped to you in our
                      international batch shipment in March, June, September,
                      and December and they ship between the 15th and 20th of
                      each month. If you need it mailed sooner, you can select
                      expedited shipping at checkout.
                    </p>
                  </div>
                </div>
              </div>
              <div className='tcb_flag' style={{ display: 'none' }} />
              <span
                id='tve_leads_end_content'
                style={{
                  display: 'block',
                  visibility: 'hidden',
                  border: '1px solid transparent',
                }}
              />
            </div>
            {/* Stop The Loop (but note the "else:" - see next line). */}
          </div>
        </div>
        {/* /inside */}
        <div className='quote'></div>
        {/* /quote */}
        <div id='avatar_footer_credit' style={{ display: 'none' }}>
          Avatars by{' '}
          <a href='http://www.sterling-adventures.co.uk/blog/'>
            Sterling Adventures
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MajorEuAsiaLatin;
