import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper';

import './style.css';
import S from './slider.module.css';

export const MainSlider = ({ slidesData, slidesContent }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleNextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % slidesContent.length);
  };

  const handlePreviousSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === 0 ? slidesContent.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={S.as_home_header_wrap}>
      <Swiper
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        // dir='rtl'
        freeMode={true}
        loop={true}
        centeredSlides={true}
        modules={[FreeMode, Navigation]}
        breakpoints={{
          900: {
            slidesPerView: 1.7,
            spaceBetween: 30,
          },
          450: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          0: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
        }}
        className={`${S.as_home_header} as_home_header`}
      >
        {slidesData?.map((item, index) => (
          <SwiperSlide
            className={`.as_home_header_slides ${S.as_home_header_slides}`}
            key={index}
          >
            {item.image ? (
              <div className={S.as_home_header_slides_image_wrap}>
                <Link to={'/subscribe'}>
                  <img
                    className={S.as_home_header_slides_image}
                    src={item.image}
                    alt='header_image'
                  />
                </Link>
              </div>
            ) : (
              <div className={S.as_home_header_slides_image_wrap}>
                <Link to={'/subscribe'}>
                  <video
                    loop='true'
                    autoplay='autoplay'
                    playsinline
                    muted
                    className={S.as_home_header_slides_image}
                  >
                    <source src={item.video} type='video/mp4'></source>
                  </video>
                </Link>
              </div>
            )}
          </SwiperSlide>
        ))}

        <div className='swiper-button-prev' onClick={handlePreviousSlide}></div>
        <div className='swiper-button-next' onClick={handleNextSlide}></div>
      </Swiper>
      {slidesContent && (
        <div className={S.as_home_header_slides_content}>
          <h2 className={S.as_home_header_slides_content_title}>
            {slidesContent[slideIndex].title}
          </h2>
          <p className={S.as_home_header_slides_content_desc}>
            {slidesContent[slideIndex].desc}
          </p>
          <Link
            to={slidesContent[slideIndex].link}
            className={S.as_home_header_slides_content_button}
          >
            {slidesContent[slideIndex].button}
            <span
              className={S.as_home_header_slides_content_button_icon}
            ></span>
          </Link>
        </div>
      )}
    </div>
  );
};
