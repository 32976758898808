import react, { useState } from "react";
import imgbox from "../../src/Components/Shared/Assets/Homepage/advbox.svg"
import imgbox2 from "../../src/Components/Shared/Assets/Homepage/advbox2.svg"
import imgbox3 from "../../src/Components/Shared/Assets/Homepage/advbox3.svg"
import imgbox4 from "../../src/Components/Shared/Assets/Homepage/advbox4.svg"
import imgbox5 from "../../src/Components/Shared/Assets/Homepage/advbox5.svg"
import imgbox6 from "../../src/Components/Shared/Assets/Homepage/advbox6.svg"
import imgbox7 from "../../src/Components/Shared/Assets/Homepage/advbox7.svg"
import imgbox8 from "../../src/Components/Shared/Assets/Homepage/advbox8.svg"
import imgbox9 from "../../src/Components/Shared/Assets/Homepage/advbox9.svg"
import imgbox10 from "../../src/Components/Shared/Assets/Homepage/adv1.svg"

import mob1 from "../../src/Components/Shared/Assets/Homepage/advmob.svg"
import mob2 from "../../src/Components/Shared/Assets/Homepage/advmob2.svg"
import mob3 from "../../src/Components/Shared/Assets/Homepage/advmob3.svg"
import mob4 from "../../src/Components/Shared/Assets/Homepage/advmob4.svg"
import mob5 from "../../src/Components/Shared/Assets/Homepage/advmob5.svg"
import mob6 from "../../src/Components/Shared/Assets/Homepage/advmob6.svg"
import mob7 from "../../src/Components/Shared/Assets/Homepage/advmob7.svg"
import mob8 from "../../src/Components/Shared/Assets/Homepage/advmob8.svg"
import mob9 from "../../src/Components/Shared/Assets/Homepage/advmob9.svg"
import mob10 from "../../src/Components/Shared/Assets/Homepage/advmob10.svg"
import { Link } from "react-router-dom";
// import advbox from "../../src/Components/Shared/Assets/Homepage/advboxbox.svg"
const AdventureBox = () =>{
const [show,setShow] = useState(0);

    const box = [
        {
            image:imgbox,
            imagemob:mob1,
        },
        {
            image:imgbox2,
            imagemob:mob2,
        },
        {
            image:imgbox3,
            imagemob:mob3,
        },
        {
            image:imgbox4,
            imagemob:mob4,
        },
        {
            image:imgbox5,
            imagemob:mob5,
        },
        {
            image:imgbox6,
            imagemob:mob6,
        },
        {
            image:imgbox7,
            imagemob:mob7,   
        },
        {
            image:imgbox8,
            imagemob:mob8,
        },
        {
            image:imgbox9,
            imagemob:mob9,
        },
        {
            image:imgbox10,
            imagemob:mob10,
        },
    ]
    return (
      <>
         <div className="adventurebox">
            <h1>Some of the Cooper Kit Adventures Include:</h1>
            {box.map((item) =>{
                return (
                <div className="bck-adv" > 
                <img src={item.image} className="adv-desk" alt="img"/>
                <img src={item.imagemob} className="adv-mob" alt="img"/>
                </div>
                )
            })} 
            <h1 >And Many More!</h1>
        </div>
         </>
    )
}

export default AdventureBox;