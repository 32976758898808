import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../Shared/Styles/form.css";

import { Pagination, Navigation } from "swiper";

function Tab1({ upsell }) {
  return (
    <>
      <Swiper
        pagination={true}
        navigation={true}
        mousewheel={false}
        slidesPerView={1}
        modules={[Pagination, Navigation]}
        cssMode={true}
        loop={true}
        className="tab1-swiper"
      >
        {upsell.upsell.adultimages.map((e) => (
          <SwiperSlide>
            <img src={e.images} className="tab-slider-img" alt="img"/>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Tab1;
