import React from 'react';

function Tab5({ tab5 }) {
  return (
    <div className='tab1'>
      <div className='tab2-text'>
        <h1 id='tab5-h1'>{tab5.subheading}</h1>
        <h2 id='tab5-h2'> {tab5.heading} </h2>

        {/* <p>{tab5.paragraph}</p> */}
        <p
          dangerouslySetInnerHTML={{
            __html: tab5.paragraph,
          }}
        ></p>
      </div>
      <img src={tab5.image} alt='img' />
    </div>
  );
}

export default Tab5;
