import image from '../assets/founder.png';
import './about.css';

export const Abouts = ({ productRef }) => {
  const handleClick = () => {
    productRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='as_container'>
      <div className='as_abouts_wrap'>
        <div className='as_abouts_image_wrap'>
          <img className='as_image as_about_img' src={image} alt='' />
        </div>
        <div className='as_abouts_content'>
          <h2 className='as_h2 as_about_heading'>Meet the Founder</h2>
          <p className='as_p as_about_desc'>
            Nichole Smaglick is the founder of Cooper & Kid and a mother of two
            non-stop kids. Before becoming a parent she lived and worked with
            traditional communities in Africa that had strong coming-of-age
            traditions. That is where she witnessed engaged elder communities
            help youth find their power, build character and teach social
            skills. Wanting to ‘box this up’ and take it to families in the US,
            she returned to create the Cooper Kit to share with her family and
            yours.
          </p>
          <button
            onClick={handleClick}
            className='as_button as_about_button'
            type='button'
          >
            Get Your Cooper Kit
          </button>
        </div>
      </div>
    </div>
  );
};
