import React from 'react';
import { dataLayer } from '../../dataLayer';
import { v4 as uuidv4 } from 'uuid';
// import { BsArrowRightShort } from 'react-icons/bs';

// import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
function GiftBoxes({ form, setForm, page, setPage, sub }) {
  const checked = (id) => {
    const newCards = form.giftingBoxes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setForm({
      ...form,
      giftingBoxes: newCards,
    });
  };
  if (form.giftingBoxes) {
    var counter = 0;
    for (var i = 0; i < form.giftingBoxes.length; i++) {
      if (form.giftingBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }

  let priceItemOne = form.giftingBoxes[0].price;
  priceItemOne = priceItemOne.substring(1);
  priceItemOne = parseFloat(priceItemOne);
  // console.log('form', form);
  let priceItemTwo = form.giftingBoxes[1].price;
  priceItemTwo = priceItemTwo.substring(1);
  priceItemTwo = parseFloat(priceItemTwo);

  let priceItemThree = form.giftingBoxes[2].price;
  priceItemThree = priceItemThree.substring(1);
  priceItemThree = parseFloat(priceItemThree);
  let itemString = form.standardBoxes[0].id;
  let itemStringConvert = itemString.toString();
  let itemString2 = form.standardBoxes[1].id;
  let itemStringConvert2 = itemString2.toString();
  let itemString3 = form.standardBoxes[2].id;
  let itemStringConvert3 = itemString3.toString();
  const userIdd = uuidv4();
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: form.title,
      item_list_name: form.title,
      items: [
        {
          item_id: itemStringConvert,
          item_name: form.giftingBoxes[0].title,

          discount: form.giftingBoxes[0].discount,
          index: form.giftingBoxes[0].id,

          price: priceItemOne,
          quantity: 1,
        },

        {
          item_id: itemStringConvert2,
          item_name: form.giftingBoxes[1].title,

          discount: form.giftingBoxes[1].discount,
          index: form.giftingBoxes[1].discount,
          index: form.giftingBoxes[1].id,

          price: priceItemTwo,
          quantity: 1,
        },
        {
          item_id: itemStringConvert3,
          item_name: form.giftingBoxes[2].title,

          discount: form.giftingBoxes[2].discount,
          index: form.giftingBoxes[2].id,

          price: priceItemThree,
          quantity: 1,
        },
      ],
    },
  });

  var user_data = {
    user_id: userIdd, // Add the unique User ID here

    email_address: form.email,
    address: {
      first_name: form.name,
      last_name: form.lastName,
    },
  };
  window.dataLayer.push({ user_data });

  function handleClickPlansGiftit(props) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    var price = props.price.substring(1);
    price = parseFloat(price);
    const userId = uuidv4();
    let itemStrinGet = props.id;
    let itemStringConvertGet = itemStrinGet.toString();
    dataLayer.push({ ecommerce: null });

    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        item_list_id: props.title,
        item_list_name: props.title,
        items: [
          {
            item_id: itemStringConvertGet,
            item_name: props.title,

            discount: props.discount,
            index: props.id,

            price: price,
            quantity: 1,
          },
        ],
      },
    });

    var user_data = {
      user_id: userId, // Add the unique User ID here

      email_address: form.email,
      address: {
        first_name: form.name,
        last_name: form.lastName,
      },
    };
    dataLayer.push({ user_data });
  }

  return form.giftingBoxes ? (
    <>
      <div className='internationall-getit'>
        <span>Shipping Internationally? </span>
        <Link to='/select-your-country'>Click Here.</Link>
      </div>
      <div className='standard'>
        <h1 className='box-title'>
          {window.location.pathname.includes('homeschooling')
            ? sub.giftingproducts.headerHomeSchooling
            : sub.giftingproducts.header}
        </h1>
        <h4 className='box-title_subheading-plans'>
          New Cooper Kits Every 3 Months
        </h4>
        <h2 className='subheading-plans'>FREE U.S. SHIPPING FOR ALL!</h2>
        <div className='standard-boxes'>
          {form.giftingBoxes.map((e, index) => {
            // console.log(e);
            return (
              <div key={e.id}>
                <div
                  className='standard-box hoverable'
                  onClick={() => {
                    // gtag('event', "Giftit", {
                    //   event_category: "signupFlow",
                    //   event_label: "submit",
                    // });
                    handleClickPlansGiftit(e);
                    checked(e.id);
                    // ReactPixel.trackSingle("1835004036766063", "AddToCart", {
                    //   value: parseFloat(e.price.substring(1, 7)),
                    //   currency: "USD",
                    // });
                    setPage(page + 1);
                  }}
                  style={
                    e.isChecked
                      ? {
                          backgroundColor: '#E3F6F8',
                          border: '1px solid #90DAE5',
                          boxShadow: '0px 0px 65px 5px rgba(244,222,225,0.18)',
                          WebkitBoxShadow:
                            '0px 0px 65px 5px rgba(244,222,225,0.18)',
                          MozBoxShadow:
                            '0px 0px 5px 65px rgba(244,222,225,0.18)',
                        }
                      : counter < 3
                      ? { backgroundColor: '#f6f7f8' }
                      : { backgroundColor: '#f6f7f8' }
                  }
                >
                  {/* <img className='landing-value' src={e.badge} /> */}
                  {e.kit ? (
                    <p
                      className='product_card_top'
                      dangerouslySetInnerHTML={{
                        __html: e.kit,
                      }}
                    ></p>
                  ) : (
                    <p style={{ marginBottom: '44px' }}></p>
                  )}
                  {/* <div className='product-card-image'>
                  <img
                    src={e.image}
                    alt='img'
                    className={index === 0 ? 'image-1' : ''}
                  />
                </div> */}
                  <div className='product_card_images'>
                    <img src={e.image} alt='cardImage' />
                  </div>
                  <div className=''>
                    {/* <div className=''><BsArrowRightShort /></div> */}
                    {/* box-border plansoffer-display */}
                    <div className='product_card_header'>
                      {/* <div> */}
                      <p>{e.title}</p>
                      <p
                        className='product_price'
                        dangerouslySetInnerHTML={{
                          __html: e.price,
                        }}
                      ></p>
                      <p
                        className='product_discount_price'
                        dangerouslySetInnerHTML={{
                          __html: e.discount,
                        }}
                      >
                        {/* {e.discount} */}
                      </p>
                      {/* <div className="getit-position"> */}
                      {/* getit-holiday */}
                      {/* </div> */}
                      {/* </div> */}
                      {e.price === '$41.99' ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: e.kit,
                          }}
                        ></div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      // className='plans-hover-display'
                      className='product_card_points'
                      dangerouslySetInnerHTML={{
                        __html: e.bottomlabel,
                      }}
                    ></div>
                    <div className='offer-discount'>
                      {/* free-shipping */}
                      {e.offer ? (
                        <>
                          <p className=''>{e.offer}</p>
                          <i>*Not valid with any other offers or discounts.</i>
                        </>
                      ) : (
                        <p style={{ marginTop: '50px' }}></p>
                      )}
                      {/* <p className='free-shipping'>Free Shipping!</p> */}
                      {/* <p>{e.bonus}</p> */}
                    </div>
                    {/* choose-plan-box */}
                    <div className='product_card_select_button'>
                      Select Plan
                    </div>
                    <div className='product_card_bottom_text'>
                      {e.cancel_text}
                    </div>
                    <div className='plans-save'>{e.save}</div>
                    {/* <div
                      className='plans-hover-display'
                      dangerouslySetInnerHTML={{
                        __html: e.bottomlabel,
                      }}
                    ></div> */}
                  </div>
                  {/* <div className='product-title'>
                  <div className='product-arrow'>
                    <h1>{e.title}</h1>
                    <BsArrowRightShort />
                  </div>

                  <div className='box-border'>
                    <div className='plansoffer-display'>
                      <h3>{e.discount}</h3>
                      <div className="getit-position">
                      <h2>{e.price}</h2>

                      <p className='getit-holiday'>
                        First Kit<br></br> Deal
                      </p>
                      </div>
                    </div>
                    {e.price === '$45.49' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: e.kit,
                        }}
                      ></div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='offer-discount'>
                    <p className='free-shipping'>{e.offer}</p>
                    <p className='free-shipping'>Free Shipping!</p>
                    <p >{e.bonus}</p>
                  </div>
                  <div className='choose-plan-box'>Select Plan</div>

                  <div className='plans-save'>{e.save}</div>
                  <div
                    className='plans-hover-display'
                    dangerouslySetInnerHTML={{
                      __html: e.bottomlabel,
                    }}
                  ></div>
                </div> */}
                </div>
                {/* <p className="plans-hover-details">Subscription Details <AiOutlineInfoCircle></AiOutlineInfoCircle></p> */}

                <div id='shipping-info-mobile'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: e.bottomlabel,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
        {/* 
      <p className="boxes-p">{sub.giftingproducts.notice}</p>
      <p className="boxes-discount">Discounts are as compared to a regular full priced Cooper Kit.</p>
      <div class="international-link"><span>Shipping Internationally? </span><a href="/select-your-country">Click Here.</a></div> */}
        <h2 className='boxes-h2'>{sub.giftingproducts.notice2}</h2>
      </div>
    </>
  ) : null;
}

export default GiftBoxes;
