import { card } from '../components/data/header';

export const Card = () => {
  return (
    <div className='as_container as_icon_card_wrap'>
      {card?.map((item, index) => (
        <div className='as_card_item' key={index}>
          <div className='as_card_icon'>
            <div className='as_card_icon'>
              <img src={item.icon} alt='icon ' />
            </div>
          </div>
          <div className='as_p'>{item.title}</div>
        </div>
      ))}
    </div>
  );
};
