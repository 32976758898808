import React from 'react';

export const OfferBanner = ({ productRef }) => {
  const handleClick = () => {
    productRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <p className='as_container as_offere_banner'>
      Get Your First Box
      <button type='button' onClick={handleClick} className='as_ancher'>
        Free
      </button>
    </p>
  );
};
