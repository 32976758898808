import React from 'react';

import SuccessImage from '../Shared/Assets/Footer/success.jpg';

function FooterThankyou() {
  return (
    <div className='main-footer-thankyou'>
      <img src={SuccessImage} alt='img' />
      <h2>Thank you for signing up!</h2>
    </div>
  );
}

export default FooterThankyou;
