import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

export const CookieComponent = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      cookieName='privacyPolicy'
      containerClasses='cookie'
      debug={false}
      style={{
        background: '#5c2945',
        fontSize: '12px',
        padding: '10px 0px',
        height: 'fit-content',
      }}
      buttonStyle={{
        color: 'white',
        fontSize: '13px',
        backgroundColor: '#90dae5',
        padding: '10px 50px',
        borderRadius: '5px',
      }}
      expires={150}
    >
      <p className='cookie-paragraph'>
        {' '}
        We use cookies to provide website functionality, to analyze traffic on
        our Cooper & Kid Site, personalize content, serve targeted
        advertisements and to enable social media functionality. Our Privacy
        Policy provides more information and explains how to update your privacy
        policy. View our{' '}
        <Link
          to={
            window.location.pathname.includes('homeschooling')
              ? '/homeschooling/privacy-policy'
              : '/privacy-policy'
          }
          className='hoverable cookie-link'
        >
          privacy-policy
        </Link>
      </p>
    </CookieConsent>
  );
};
