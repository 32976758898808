import React from "react";

function FifthSection({ fifthsection }) {
  return (
    <div className="fifth-section-hp">
      <div className="main-wrapper homepage-fifth-section">
        <div className="fifth-section-hp-text">
          <h1>{fifthsection.heading}</h1>
          <p>{fifthsection.paragraph}</p>
        </div>
        <img src={fifthsection.gif.url} alt="fifth" />
      </div>
    </div>
  );
}

export default FifthSection;
