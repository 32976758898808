import React from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/Group 1000002775.png';
import { mobileWhatYouGetData, title } from './what-you-get-data';
import './whatYouGet.css';
import { WhatYouGetSlider } from './WhatYouGetSlider';
// import { Slider } from '../components/slider/Slider';
// import { slides, slidesContent } from './slides';

export const WhatYouGet = () => {
  return (
    <>
      {/* <h1 className='as_what_you_get_heading'>What you get every 3 months!</h1>
      <Slider slides={slides} slidesContent={slidesContent} /> */}
      <div className='as_what_you_get_container'>
        <div className='as_left'>
          <h1 className='as_h1 as_what_you_title'>{title}</h1>
          {mobileWhatYouGetData.map((item, index) => {
            return (
              <div className='as_what_title_text_wrap' key={index}>
                <h2 className='as_h2 as_what_subtitle'>{item.subTitle}</h2>
                <p className=' as_what_text'>{item.pg}</p>
                <div className='as_what_you_get_mobile_img'>
                  {item.image?.map((itemImage, index) => (
                    <div className='as_what_you_get_img_wrap'>
                      <img
                        key={index}
                        className='as_what_you_get_mobile_img_item'
                        src={itemImage}
                        alt={item.subTitle}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        <div className='as_right'>
          <div className='as_what_img_row'>
            <div className='as_what_img_warp'>
              <img className='as_image' src={image} alt='what images' />
            </div>
          </div>
        </div>
        <div className='as_what_you_get_slider'>
          <h1 className='as_h1 as_what_you_title_mobile'>
            What you get every
            <br /> 3 months!
          </h1>
          <WhatYouGetSlider />
        </div>
      </div>
      <div className='as_what_to_get_call_to_action'>
        <Link
          to={
            window.location.pathname.includes('homeschooling')
              ? '/homeschooling/subscribe'
              : '/subscribe'
          }
          className='kit-button-link hoverable'
        >
          GET YOUR KIT
        </Link>
      </div>
    </>
  );
};
