import React from 'react';
import '../Components/Shared/Styles/quietOnTheSet.css';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import image1 from '../Components/Shared/Assets/Keep Pages/father1.png';
import image2 from '../Components/Shared/Assets/Keep Pages/father2.jpg';

import projects from '../Components/Shared/Assets/Keep Pages/projects.jpg';
import books from '../Components/Shared/Assets/Keep Pages/books.jpg';
import character from '../Components/Shared/Assets/Keep Pages/character.jpg';

import cook from '../Components/Shared/Assets/Keep Pages/Cookbook-v2.jpg';
import medallions from '../Components/Shared/Assets/Keep Pages/medallions.jpg';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

const PreSchools = () => {
  return (
    <div className='white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='quite-main-wrapper'>
        <div className='quite-first-section'>
          <div>
            <h1>
              help make a great <br></br>dad this father’s day
            </h1>
          </div>
          <img src={image2} className='quite-firstsection-image2' alt='img' />
          <img src={image1} className='quite-firstsection-image1' alt='img' />
        </div>
        <p className='f-paragraph'>
          it’s the father’s day gift about actually being a father
        </p>
        <h1 className='f-heading'>
          What an awesome preschool you have, helping fathers get further
          involved outside of the classroom!
        </h1>
        <img src={image1} className='f-image' alt='img' />
        <p className='f-paragraph2'>
          Like your preschool teacher, we value the chance for busy fathers to
          be involved in helping kids continue their education and character
          building outside of the classroom. For kids, a dad’s involvement
          fosters empathy, self-esteem, self-control, psychological well-being,
          social competence, and life skills.{' '}
        </p>
        <h1 className='f-heading'>THE INSTANT DAD-IS-AWESOME BOX</h1>
        <p className='f-paragraph2'>
          The Cooper Kit, a cancel-anytime subscription service, contains
          multiple curated products and activities designed to enrich quality
          time between dads and children ages 5+. Every three months, you’ll
          receive a gender-neutral, themed, multiple-activity box delivered to
          your door. The Cooper Kit contains 6+ hours of quality time away from
          video games, tablets, and phones to connect on a meaningful level in
          the real world. Oh, and they also teach character building!
        </p>
        <h1 className='f-heading'>PRESCHOOL SPECIAL</h1>
        <p className='f-paragraph2'>
          <strong>
            {' '}
            And thanks to your preschool, you can subscribe to the Cooper Kit
            for a 30% discount if you order before Father’s Day, June 17!
          </strong>{' '}
          Enjoy giving (or receiving!) the Cooper Kit this Father’s Day… a gift
          that actually has something to do with being a father.{' '}
        </p>
        <div className='quite-first-section'>
          <div>
            <h1>
              so, What’s in a <br></br> <span>Cooper Kit?</span>
            </h1>
            <br></br>
            <br></br>
            <p>
              There’s a collection of FIVE core awesome activities and projects
              in every kit. Among those, you’ll always find building projects,
              creative expression projects, and more! Plus the box always turns
              into a toy. That’s right. Even the BOX turns into a toy. Plus
              there’s other items found in every box such as:
            </p>
          </div>
          <img
            src={projects}
            className='quite-fourth-image quite-Ffourth-image'
          />
        </div>
        <div className='quiet-fifth-section'>
          <img src={books} className='fifth-f-image' alt='img' />
          <p>
            <strong>Bedtime Storybooks</strong> <br></br>Kids like being read
            to, even at older ages. Every Cooper Kit includes a book to increase
            that bonding time.
          </p>
        </div>
        <div className='quiet-fifth-section quiet-fifth-section-reverse'>
          <p>
            <strong>Character Building</strong> <br></br>Cooper Kits are
            parenting tools to teach character development. Each kit contains
            teachable moments around a theme-aligned virtue, value, or life
            skill.
          </p>
          <img src={character} alt='img' />
        </div>
        <div className='quiet-fifth-section'>
          <img src={cook} className='fifth-f-image' alt='img' />

          <p>
            <strong>Themed Cookbook</strong>
            <br></br>Take the theme into the kitchen to make even mealtime fun!
          </p>
        </div>
        <div className='quiet-fifth-section quiet-fifth-section-reverse'>
          <p>
            <strong>Collectible Medallions</strong>
            <br></br>Found in each Cooper Kit are military grade challenge coins
            for your kids to remember your time together for ever and ever.
          </p>
          <img src={medallions} className='fifth-f-image' alt='img' />
        </div>
        <div className='quiet-sixth-section'>
          <h2>All these Experiences for only $45.50!</h2>
          <p>
            Just for folks at your preschool, you get 30% off your first Cooper
            Kit, bringing you down to $45.50! After that, your subsequent Cooper
            Kits will renew at $65 per quarter. <br></br>
            <br></br>Every 3 months you get the latest and greatest Cooper Kit
            shipped straight to your front door! Whew, takes the work out of
            shopping and it arrives just in time for Father’s Day, back to
            school, the holiday season, and Spring Break.
          </p>
          <h1>The Father’s Day Gift Every Dad Wants</h1>
          <p>
            Dads want to spend quality time with their family on Father’s Day.
            So get him (or ask for) the only Father’s Day gift specifically
            about fathering and spending quality family time.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PreSchools;
