import { HeaddingBar } from '../components/HeaddingBar';
import { ProductDetail } from './ProductDetail';
import image from '../assets/Product_thumbnail.png';
import './product.css';

export const Product = ({ productRef }) => {
  return (
    <div ref={productRef} id='product-container'>
      <HeaddingBar
        title='Starting at $59'
        description='Medallion & Shipping included!'
      />
      <div className='as_container as_product'>
        <div className='as_product_image_wrap as_family_time_today_wrap'>
          <img
            className='as_image as_family_time_today_img'
            src={image}
            alt='Product_image'
          />
        </div>
        <ProductDetail />
      </div>
    </div>
  );
};
