import React from 'react';
import { Link } from 'react-router-dom';

import birth from '../Shared/Assets/Homepage/birth.png';
import header2 from '../Shared/Assets/Homepage/header2.png';
function FirstSectionBanner({ form, setForm, firstsection }) {
  return (
    <>
      <div className='first-section-birthday  first-section-hp-desktop first-section-hp-birthday '>
        <div className=' main-wrapper homepage-first-section'>
          <div className='first-section-hp-text first-section-hp-txt-birthday'>
            {/* <h1 id="hs-h1">Some day your kids 
will tell their kids
how great you were.</h1> */}
            <img src={firstsection.banners.box} alt='first' />
            <p>{firstsection.paragraph}</p>

            <div className='first-section-hp-buttons first-section-hp-buttons-birthday'>
              <Link
                to={
                  window.location.pathname.includes('birthday')
                    ? '/birthday/subscribe'
                    : '/subscribe'
                }
                id='claimOffer'
                className='hoverable'
                onClick={() => {
                  setForm({ ...form, houseHold: true, gifting: false });
                }}
              >
                CLAIM OFFER
              </Link>
              <Link
                to={
                  window.location.pathname.includes('birthday')
                    ? '/birthday/subscribe'
                    : '/subscribe'
                }
                className='hoverable'
                id='giveGift'
                onClick={() => {
                  setForm({ ...form, gifting: true, houseHold: false });
                }}
              >
                GIVE A GIFT
              </Link>
            </div>
          </div>
          <img
            src={firstsection.banners.desktopbanner.url}
            className='header-font'
          />
        </div>
      </div>
      <div className='first-section-hp first-section-hp-mobile  first-section-hp-birthday'>
        <div className='first-section-hp-text first-section-hp-txt-birthday'>
          {/* <img src={toys}  className="homepage-toys"/> */}
          {/* <h1>Some day your kids will tell their kids how great you were.!</h1> */}
          <img
            src={header2}
            className='first-section-birthday-img'
            alt='header'
          />
          <p>
            A family field-trip in a box filled with educational projects and
            more. Giving you those awesome family times together!.
          </p>
          <div className='first-section-hp-buttons first-section-hp-buttons-birthday'>
            <Link
              to={
                window.location.pathname.includes('birthday')
                  ? '/birthday/subscribe'
                  : '/subscribe'
              }
              id='claimOffer'
              className='hoverable'
              onClick={() => {
                setForm({ ...form, houseHold: true, gifting: false });
              }}
            >
              CLAIM OFFER
            </Link>
            <Link
              to={
                window.location.pathname.includes('homeschooling')
                  ? '/homeschooling/subscribe'
                  : '/subscribe'
              }
              className='hoverable'
              id='giveGift'
              onClick={() => {
                setForm({ ...form, gifting: true, houseHold: false });
              }}
            >
              GIVE A GIFT
            </Link>
          </div>

          <img src={birth} className='header-font' alt='birth' />
        </div>
      </div>
    </>
  );
}

export default FirstSectionBanner;
