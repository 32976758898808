import stareIcons from '../assets/icons/stars-5-1 1.png';
import { HeaddingBar } from '../components/HeaddingBar';
import { BrandLogo } from './BrandLogo';
import { testimonials } from '../components/data/testimonials';
import { Promises } from './Promises';
import './testimonial.css';

export const Testimonials = () => {
  return (
    <div className='as_testimonials'>
      <HeaddingBar title='Shipping Cooper Kits to families around the world, since 2014' />
      <BrandLogo />
      <div className='as_testimonials_wrap'>
        <div className='as_testimonials_heading'>
          <h3 className='as_heading'>Families Love Cooper Kits!</h3>
        </div>
        <div className='as_container as_testimonials_items_wrap'>
          {testimonials?.map((item) => (
            <div key={item.id} className='as_testimonials_item'>
              <div className='as_icons_wrap'>
                <img src={stareIcons} alt='' className='as_icon' />
                {/* <BiStar className='as_icon' /> */}
              </div>
              <div className='as_testimonial_message'>
                <p>{item.message}</p>
              </div>
              <h5>{item.name}</h5>
            </div>
          ))}
        </div>
      </div>
      <Promises />
    </div>
  );
};
