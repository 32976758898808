import React from "react";
import { Link } from "react-router-dom";
import {BsArrowRightShort} from "react-icons/bs";

function FirstSection({ form, setForm, firstsection }) {
  return (
    <>
      <div className=" homeschoolingBg-bannerMobile">
        <div className=" main-wrapper homepage-first-section-home">
          <div className="first-section-hp-text-home">
            <h1 className="">{firstsection.header}</h1>
            <p>{firstsection.paragraph}
</p>
            <div className="first-section-hp-buttons-home">
              <Link
                to={
                  window.location.pathname.includes("homeschooling")
                    ? "/homeschooling/subscribe"
                    : "/subscribe"
                }
                id="claimOffer-homeschooling"
                className="hoverable"
                onClick={() => {
                  setForm({ ...form, houseHold: true, gifting: false });
                }}
              >
               Begin Customization
              </Link>
              <Link
                to={
                  window.location.pathname.includes("homeschooling")
                    ? "/homeschooling/adventures"
                    : "/adventures"
                }
                className="hoverable gift-arrow-icon"
       
               
              >
               <span>See Box Adventures </span> <BsArrowRightShort/>
              </Link>
            </div>
          </div>
        <div className="adventre-width adventure-position-desktop">
        <img
            src={firstsection.banners.desktopbanner.url}
            className="adventure-position "
          />
          
        </div>
        <div className="adventre-width adventure-position-tablet">
     
            <img
            src={firstsection.banners.tabletbanner998.url}
            className="adventure-position"
          />
          
        </div>

        <div className="adventre-width adventure-position-mobile">
     
          <img
          src={firstsection.banners.mobilebanner600.url}
          className="adventure-position"
           />
   
         </div>
        </div>
      </div>
    </>
  );
}

export default FirstSection;
