import React from 'react';
import { Link } from 'react-router-dom';

function SecondSection({ secondsection }) {
  return (
    <div className='second-section-hp'>
      <div className='main-wrapper homepage-second-section'>
        <h1>{secondsection.heading}</h1>
        <h2>{secondsection.paragraph}</h2>
        {/* <div className="second-section-hp-grid">
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.firstbox.image} alt="firstbox"/>
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="wellrounded-header">{secondsection.firstbox.heading}</h1>
              <p>{secondsection.firstbox.paragraph}</p>
            </div>
          </div>
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.secondbox.image} alt="secondbox"/>
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="unique-header">{secondsection.secondbox.heading}</h1>
              <p>{secondsection.secondbox.paragraph}</p>
            </div>
          </div>
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.thirdbox.image} alt="thirdbox"/>
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="unplug-header">{secondsection.thirdbox.heading}</h1>
              <p>{secondsection.thirdbox.paragraph}</p>
            </div>
          </div>
        </div> */}

        <iframe
          src='https://www.youtube.com/embed/BuEQkiJR6xc?si=-c-Zw0D2fg7UprA4'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen
        ></iframe>
        <div className='homepage-second-section-button'>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/subscribe'
                : '/subscribe'
            }
            className='kit-button-link hoverable'
          >
            Get Your Kit
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
