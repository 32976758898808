import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import ReactPixel from "react-facebook-pixel";
// import { BiChevronLeft } from 'react-icons/bi';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Footer from '../Components/Shared/Footer';
import FormGiftit from '../Components/StepsGiftit/Form';
// import { AiOutlineInfoCircle } from 'react-icons/ai';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: "Get a Free Kid's Smart Watch with your first Cooper Kit!",
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Getgift({ form, setForm, page, setPage, sub, signupForm }) {
  const [loader, setLoader] = useState(false);
  const getSwitchBoxType = (boxType) => {
    var type = '';
    boxType.filter((e) => {
      if (e.isChecked) type = e.type;
    });
    return type;
  };
  //   const checked = (id) => {
  //     const newCards = form.giftingBoxes.map((item) => {
  //       if (item.id === id) {
  //         return {
  //           ...item,
  //           isChecked: true,
  //         };
  //       } else {
  //         return {
  //           ...item,
  //           isChecked: false,
  //         };
  //       }
  //     });
  //     setForm({
  //       ...form,
  //       giftingBoxes: newCards,
  //     });
  //   };
  // me filtru se cili box osht checked
  if (form.giftingBoxes) {
    var counter = 0;
    for (var i = 0; i < form.giftingBoxes.length; i++) {
      if (form.giftingBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }
  return form.giftingBoxes ? (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <FormGiftit
        form={form}
        setForm={setForm}
        signupForm={signupForm}
        sub={sub}
      />
      <Footer />
    </>
  ) : null;
}
export default Getgift;
