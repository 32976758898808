import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Phone from '../Components/Shared/Assets/contact/phone.svg';
import Email from '../Components/Shared/Assets/contact/email.svg';

import '../Components/Shared/Styles/contact.css';

import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function ContactSuccess({ form }) {
  return form.length > 0 ? (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='contact-form'>
        <div className='main-wrapper contact-form-main'>
          <div className='contant-info'>
            <h1>
              <span>Let’s get in touch.</span> <br></br>Contact Cooper & Kid
            </h1>
            <div className='contact-label'>
              <div className='label-icon'>
                <img src={Phone} alt='phone-icon' />
              </div>
              <p>952.388.0478</p>
            </div>
            <div className='contact-label'>
              <div className='label-icon'>
                <img src={Email} alt='email-icon' />
              </div>
              <p>info@cooperandkid.com</p>
            </div>
          </div>
          {/* <div
            dangerouslySetInnerHTML={{
              __html: form[1].acf.contact_form,
            }}
          /> */}
          <div className='thankyou-message'>
            <h1>Thank you for contacting us!</h1>{' '}
            <h3>We’ll get back to you shortly!</h3>
            <h3>Thanks.</h3>
          </div>
        </div>
      </div>
      <div className='wbg'></div>
      {/* <iframe
        name="contactForm"
        id="i_frame"
        style={{ display: "none", width: "0", height: "0" }}
      ></iframe> */}
      <Footer />
    </>
  ) : null;
}

export default ContactSuccess;
