import { NewsletterForm } from './NewsletterForm';
import './newsletter.css';

export const Newsletter = ({ handleToest }) => {
  return (
    <div className='as_container as_newslatter'>
      <div className='as_newslatter_wrap'>
        <h2 className='as_h2'>Get Your 1st Kit Free</h2>
        <p className='as_p'>
          Plus, get friendly tips, free DIYs, deals and advice made for busy
          grown-ups who love their kids.
        </p>
        <div className='as_newslatter_form'>
          <NewsletterForm />
        </div>
        <p className='as_p'>
          By clicking "Subscribe" you agree to our Privacy Policy & Terms of
          Service.
        </p>
      </div>
    </div>
  );
};
