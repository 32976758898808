import React from 'react';
import { Link } from 'react-router-dom';
import Iframe from '../Shared/youtube-video-iframe/Iframe';

const Innovative = ({ innov }) => {
  return (
    <>
      <div className='innovative main-wrapper'>
        <h1>{innov.header}</h1>
        <p>{innov.subheader}</p>
        <div className='innovative-display'>
          {innov.cards.map((item) => {
            return (
              <div className='innovative-box'>
                <div className='innovative-img'>
                  <img src={item.image} alt='image' />
                </div>
                <div className='innovative-text'>
                  <h1>{item.title}</h1>
                  <p>{item.text}</p>
                </div>
              </div>
            );
          })}
        </div>
        <Link to='/homeschooling/subscribe'>GET YOUR KIT</Link>
      </div>
      <Iframe />
    </>
  );
};

export default Innovative;
