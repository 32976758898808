import React from "react";
import Footer from "../Components/Shared/Footer";
import Navbar from "../Components/Shared/Navbar/Navbar";
import "../Components/Shared/Styles/liveEvents.css";

function LiveEvents() {
  return (
    <>
      <Navbar />
      <div className="live-events main-wrapper">
        <h1 className="events-title">Live Event Order Forms</h1>
        <div className="event">
          <div className="event-2">
            <a
              href="https://aw135.infusionsoft.app/app/orderForms/quarterly-in-person?cookieUUID=e31655d8-f11e-4fda-a33a-9e8e2859c914"
              target="_blank"
              rel="noreferrer"
            >
              Quarterly Subscription
            </a>
            <a
              href="https://aw135.infusionsoft.app/app/orderForms/annual-in-person?cookieUUID=28982375-13e2-4a17-af73-6b19538e72b0"
              target="_blank"
              rel="noreferrer"
            >
              Annual Subscription
            </a>
            <a
              href="https://aw135.infusionsoft.app/app/orderForms/yok-in-person?cookieUUID=a142b2a1-c308-4a3b-a9f0-256478812bcf"
              target="_blank"
              rel="noreferrer"
            >
              YOK
            </a>
            <a
              href="https://aw135.infusionsoft.app/app/orderForms/Cooper-Kit-No-Subscription?cookieUUID=a0b69a08-96ef-4d4a-9061-fd6c18c8e9ee"
              target="_blank"
              rel="noreferrer"
              className="no-sub"
            >
              Single Kit (No Subscription)
            </a>
          </div>
          <div className="event-2">
            <a
              href="https://aw135.infusionsoft.app/app/orderForms/quarterly-in-person?cookieUUID=e31655d8-f11e-4fda-a33a-9e8e2859c914"
              target="_blank"
              rel="noreferrer"
              className="post-order"
            >
              Quarterly Subscription
            </a>
            <h1>
              Promo Codes: <br></br>FAIRE5
            </h1>
            <h1>FAIRE10</h1>
            <h1>FAIRE15 </h1>
            <h1>etc. up to</h1>
            <h1>FAIRE50</h1>
          </div>
        </div>

        <h1 className="events-title">Live Event Cart</h1>
        <div className="event">
          <div className="event-2">
            <p>Subscriptions</p>

            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=74&subscriptionPlanId=32"
              target="_blank"
              rel="noreferrer"
            >
              Quarterly Subscription
            </a>
            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=76&subscriptionPlanId=34"
              target="_blank"
              rel="noreferrer"
            >
              Annual Subscription
            </a>
            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=78"
              target="_blank"
              rel="noreferrer"
            >
              Year of Kits
            </a>
          </div>
          <div className="event-2">
            <p>Other Products</p>
            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=116"
              target="_blank"
              rel="noreferrer"
            >
              Single Kit (No Subscription)
            </a>
            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=148"
              target="_blank"
              rel="noreferrer"
            >
              $20 Gift Card
            </a>
            <a
              href="https://aw135.infusionsoft.com/app/manageCart/addProduct?productId=150"
              target="_blank"
              rel="noreferrer"
            >
              $50 Gift Card
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LiveEvents;
