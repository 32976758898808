import React from "react";

function SecondSection({
  promise1,
  promise2,
  promise3,
  promise4,
  promise5,
  promise6,
  promise7,
  promise8,
  section2,
}) {
  const executeScroll1 = () => promise1.current.scrollIntoView();
  const executeScroll2 = () => promise2.current.scrollIntoView();
  const executeScroll3 = () => promise3.current.scrollIntoView();
  const executeScroll4 = () => promise4.current.scrollIntoView();
  const executeScroll5 = () => promise5.current.scrollIntoView();
  const executeScroll6 = () => promise6.current.scrollIntoView();
  const executeScroll7 = () => promise7.current.scrollIntoView();
  const executeScroll8 = () => promise8.current.scrollIntoView();

  return (
    <div className="second-section-pk">
      <div className="main-wrapper pk-second-section ">
        <p>
          {section2.heading}
          <span> {section2.headingbig}</span>
        </p>
        <div className="promises">
          <div
            className="promise hoverable "
            onClick={() => {
              executeScroll1();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise1.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise1.txt}</p>
          </div>
          <div
            className="promise hoverable promise-inverse"
            onClick={() => {
              executeScroll2();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise2.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise2.txt}</p>
          </div>
          <div
            className="promise hoverable "
            onClick={() => {
              executeScroll3();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise3.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise3.txt}</p>
          </div>
          <div
            className="promise hoverable promise-inverse"
            onClick={() => {
              executeScroll4();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise4.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise4.txt}</p>
          </div>
        </div>
        <div className="promises">
          <div
            className="promise hoverable"
            onClick={() => {
              executeScroll5();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise5.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise5.txt}</p>
          </div>

          <div
            className="promise hoverable promise-inverse"
            onClick={() => {
              executeScroll6();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise6.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise6.txt}</p>
          </div>

          <div
            className="promise hoverable"
            onClick={() => {
              executeScroll7();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise7.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise7.txt}</p>
          </div>
          <div
            className="promise hoverable "
            onClick={() => {
              executeScroll8();
            }}
          >
            <div className="promise-img">
              <img
                src={section2.promise8.image.url}
                alt="img"
                className="promise-img"
              />
            </div>
            <p>{section2.promise8.txt}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
