// import { BiRocket, BiCycling, BiGroup, BiBadgeCheck } from 'react-icons/bi';

import image1 from '../../assets/header/header_3.png';
import image2 from '../../assets/header/header_1.png';
import image3 from '../../assets/header/header_4.png';
import image4 from '../../assets/header/header_2.png';

// icon list
import icon1 from '../../assets/icons/icon01.png';
import icon2 from '../../assets/icons/icon02.png';
import icon3 from '../../assets/icons/icon03.png';
import icon4 from '../../assets/icons/icon04.png';

export const header = [
  {
    subtitle: 'New Box Every 3 Months!',
    title: 'Family Adventures Together That:',
    title2: [
      'deliver fun, meaningful quality time.',
      'are an imaginative educational trip.',
      'helps teach character and social emotional skills.',
      'is quality time both kids and grownups dream of.',
      'makes memories while they are still young!',
      // 'is the kind of quality time that both kids and grownups dream of.',
      // 'takes you on an imaginative educational trip.',
      // 'makes precious moments while they are still young!',
      // 'thakes you on an imaginative educational trip.',
      // 'helps teach character and social emotional skills.',
      // 'makes precious moments while they are still young!',
      // 'bring the family together for fun meaningful quality time',
      // 'is the kind of quality time that both kids and grownups dreams of.',
    ],
    desc: 'Meet the Cooper Kit, a high-quality, quarterly subscription box designed for families to do together. Fun for grownups. Fun for kids. And doubles as a parenting tool to teach valuable life lessons.',
    image1: image1,
    image2: image2,
    image3: image3,
    image4: image4,
  },
];

export const card = [
  {
    // icon: <BiRocket className='card_icon' />,
    icon: icon1,
    title: 'New themed adventure every 3 months',
  },
  {
    // icon: <BiCycling className='card_icon' />,
    icon: icon2,
    title: 'Includes multiple high-quality & unique projects + book.',
  },
  {
    // icon: <BiGroup className='card_icon' />,
    icon: icon3,
    title: 'Can be used with multiple children ages 5-12',
  },
  {
    // icon: <BiBadgeCheck className='card_icon' />,
    icon: icon4,
    title: 'Challenge coin medallion included',
  },
];
