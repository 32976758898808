import React from 'react';

function ReuseableSection({
  mainClass,
  id,
  promiseNr,
  title,
  parapgraph,
  textClass,
  image,
  promise,
  mainWrapper,
  imageClass,
}) {
  return (
    <div className={mainClass} id={id} ref={promise}>
      <div className={mainWrapper}>
        <div className={textClass}>
          <h1>{promiseNr}</h1>
          <h2>{title}</h2>
          {/* <p>{parapgraph}</p> */}
          <p
            dangerouslySetInnerHTML={{
              __html: parapgraph,
            }}
          ></p>
        </div>
        <img src={image} alt='img' className={imageClass} />
      </div>
    </div>
  );
}

export default ReuseableSection;
