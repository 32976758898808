import React from "react";
import { Link } from "react-router-dom";

function NinthSection({ ninthsection }) {
  return (
    <div className="ninth-section-hp">
      <div className="main-wrapper homepage-ninth-section">
        <div className="ninth-section-hp-text">
          <h1>{ninthsection.heading}</h1>
          <p className="bold-paragraph">{ninthsection.boldheading}</p>
          <p> {ninthsection.paragraph}</p>
          <Link
            to={
              window.location.pathname.includes("homeschooling")
                ? "/homeschooling/subscribe"
                : "/subscribe" &&   window.location.pathname.includes("birthday")
                ? "/birthday/subscribe"
                : "/subscribe"
            }
            className="hoverable"
          >
            GET DEAL
          </Link>
        </div>
        <img src={ninthsection.image} alt="img" />
      </div>
    </div>
  );
}

export default NinthSection;
