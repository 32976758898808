import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import './style.css';

export const Slider = ({ slides, slidesContent, title }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const handleNextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % slidesContent.length);
  };

  const handlePreviousSlide = () => {
    setSlideIndex((prevIndex) =>
      prevIndex === 0 ? slidesContent.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='as_slides_details'>
      <div className='as_container as_main_slider_wrap'>
        {title && <h2 className='as_slider_detail_heading'>Inside the Kit</h2>}
        <Swiper
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          freeMode={true}
          loop={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className='as_mySwiper2'
        >
          {slides?.map((item, index) => (
            <SwiperSlide className='as_family_time_today_wrap' key={index}>
              <img className='as_slider_img ' src={item.image} alt='' />
              {item.title && <h3 className='as_title'>{item.title}</h3>}
            </SwiperSlide>
          ))}
          <div
            className='swiper-button-prev'
            onClick={handlePreviousSlide}
          ></div>
          <div className='swiper-button-next' onClick={handleNextSlide}></div>
        </Swiper>
      </div>
      <div className='as_slide_detail_wrap'>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={1}
          slidesPerView={12}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className='as_mySwiper as_image_grid_wrap'
        >
          {slides?.map((item, index) => (
            <SwiperSlide
              key={index}
              className='as_item'
              onClick={() => setSlideIndex(index)}
            >
              <img className='as_slider_img' src={item.image} alt='' />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className='as_details'>
          {title ? (
            <>
              <h2 className='as_detail_heading'>Inside the Kit</h2>
              <h5 className='as_detail_heading_2'>
                {slidesContent[slideIndex].title}
              </h5>
              <p className='as_detail_desc'>{slidesContent[slideIndex].desc}</p>
            </>
          ) : (
            <>
              <h5 className='as_detail_title'>
                {slidesContent[slideIndex].title}
              </h5>
              <p className='as_detail_desc_2'>
                {slidesContent[slideIndex].desc}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
