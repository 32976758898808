import { useEffect, useState } from 'react';
import { Card } from './Card';
import './header.css';

export const Header = ({ data, productRef }) => {
  const heading = data.title2;

  const [animatedTitle, setAnimatedTitle] = useState(0);
  const [isFade, setIsFade] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setAnimatedTitle((prevIndex) => {
        const nextIndex = prevIndex + 1;
        return nextIndex < heading.length ? nextIndex : 0;
      });
    }, 4000);

    return () => {
      clearInterval(timer);
    };
  }, [heading]);

  useEffect(() => {
    const fade = setInterval(() => {
      setIsFade(false);
    }, 3000);

    return () => {
      setIsFade(true);
      clearInterval(fade);
    };
  }, [animatedTitle]);

  const handleClick = () => {
    productRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='as_container as_hero_section'>
      <div className='as_hero_item as_left'>
        <img className='as_image as_header_img_1' src={data.image1} alt='' />
        <img className='as_image as_header_img_2' src={data.image2} alt='' />
      </div>
      <div className='as_hero_item as_middle'>
        <div className='as_hero_heading'>
          <h4 className='as_h4 as_sub_title'>{data.subtitle}</h4>
          <h1 className='as_h1 as_header_title'>{data.title}</h1>
          <div className='as_animated_text'>
            <h1 className={`as_h1 as_heading as_fade ${isFade && 'fade-in'}`}>
              {heading[animatedTitle]}
            </h1>
          </div>
        </div>
        <div className='as_hero_description'>
          <p className='as_p'>{data.desc}</p>
        </div>
        <Card />
        <button
          type='button'
          onClick={handleClick}
          className='as_button as_header_btn'
        >
          Start the Family Adventure!
        </button>
      </div>
      <div className='as_hero_item as_right'>
        <img className='as_image as_header_img_3' src={data.image3} alt='' />
        <img className='as_image as_header_img_4' src={data.image4} alt='' />
      </div>
    </div>
  );
};
