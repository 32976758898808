import React from 'react';

function Tab1({ tab1 }) {
  return (
    <div className='tab1'>
      <div className='tab1-text'>
        <h2>{tab1.subheading}</h2>
        <h1>{tab1.heading}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: tab1.paragraph,
          }}
        >
          {/* {tab1.paragraph} */}
        </p>
      </div>
      <img src={tab1.image} alt='img' className='desktop-image-tab' />
      <img src={tab1.imagemobile} alt='img' className='mobile-image-tab' />
    </div>
  );
}

export default Tab1;
