import { BsArrowRightShort } from 'react-icons/bs';

const OtherBox = (props) => {
  // console.log("Aa",props.landing.acf.otherBox)

  return (
    <div className='otherbox-display'>
      {props.landing.acf.otherBox.map((item) => {
        // console.log('item', item.link);
        return (
          <a href={item.link} className='other-box' target='_blank'>
            <img src={item.boxImage.url} alt='' />

            <div className='landig-arrow'>
              <h1>{item.title} </h1>
              <BsArrowRightShort />
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
            {/* <p>{item.description} </p> */}
            <p>{item.price} </p>
          </a>
        );
      })}
    </div>
  );
};

export default OtherBox;
