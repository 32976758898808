import React from "react";

function EighthSection({ eighthsection }) {
  return (
    <div className="eighth-section-hp">
      <div className="main-wrapper homepage-eighth-section">
        <img src={eighthsection.image} alt="eight"/>
        <div className="eighth-section-text">
          <h1>{eighthsection.heading}</h1>
          <p>{eighthsection.paragraph}</p>
        </div>
      </div>
    </div>
  );
}

export default EighthSection;
