import React, { useEffect } from "react";
import { dataLayer } from "../../dataLayer";
function NumberOfChildren({ form, setForm, page, setPage, step1 }) {

  function handleSignUp() {

    dataLayer.push({'event' : 'subscription_step_1',
    'selection_type' : 'one',
    'children' : form.numberOfChildren})
    
  }

  const noOfChildren = () => {
    if (
      form.numberOfChildren === 0 ||
      form.numberOfChildren === "0" ||
      form.numberOfChildren < 1 ||
      isNaN(form.numberOfChildren)
    ) {
      return true;
      
    } else {
      return false;
    }
  };

  return (
    <div className="no-of-children">
      <h3>{step1.header}</h3>
      <h5>{step1.subheader}</h5>
      <div className="no-options">
        <div
          className="no-option hoverable"
          onClick={() => {
            setForm({ ...form, numberOfChildren: 1, multipleChildren: false });
            
          }}
          style={
            form.numberOfChildren === 1
              ? { opacity: "1", border: "2px solid #7B3D5D" }
              : form.multipleChildren === false
              ? { border: "1px solid #90DAE5" }
              : { opacity: "0.7" }
          }
        >
          <img src={step1.child.image} alt="img" />
          <h1>{step1.child.text}</h1>
        </div>
        <div
          className="no-option hoverable"
          onClick={() => {
            setForm({ ...form, numberOfChildren: 2, multipleChildren: true });
          }}
          style={
            form.numberOfChildren === 0
              ? { border: "1px solid #90DAE5" }
              : form.numberOfChildren === 1
              ? { border: "1px solid #90DAE5", opacity: "0.7" }
              : form.numberOfChildren > 1
              ? { opacity: "1", border: "2px solid #7B3D5D" }
              : { opacity: "0.7" }
          }
        >
          <img src={step1.children.image} alt="img" />
          <h1>{step1.children.text}</h1>
        </div>
      </div>
      <div
        className="multiple-child-div"
        style={
          form.multipleChildren
            ? { display: "flex", flexDirection: "row" }
            : { display: "none" }
        }
      >
        <p>{step1.children.label}</p>

        <div className="input-container2">
          <div className="square"></div>

          <input
            type="number"
            id="numberofchildren"
            // placeholder="i.e. 3"
            value={
              isNaN(form.numberOfChildren)
                ? form.numberOfChildren === 0
                : String(form.numberOfChildren)
            }
            min={2}
            onChange={(e) => {
              setForm({ ...form, numberOfChildren: parseInt(e.target.value) });
            }}
            required
          />
        </div>
      </div>
      <button
        onClick={() => {
          setPage(2);
          // gtag('event', "stepOne", {
          //   event_category: "signupFlow",
          //   event_label: "submit",
          // });
          handleSignUp()
          
          localStorage.clear();
        }}
        disabled={noOfChildren()}
        className="hoverable"
      >
        Continue
      </button>
    
    </div>
  );
}

export default NumberOfChildren;
