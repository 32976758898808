import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function TenthSection({ tenthsection }) {
  const [open, setOpen] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });
  return (
    <div className="tenth-section-hp">
      <h1>{tenthsection.heading}</h1>
      <div className="FAQs">
        <div className="faq-left">
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                1: !open[1],
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
              });
            }}
          >
            <p>{tenthsection.testimonial1.question}</p>{" "}
            {!open[1] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    1: !open[1],
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 1: !open[1] });
                }}
              />
            )}
          </div>
          <div
            className={open[1] ? "faq-content transition" : "faq-content"}
            style={open[1] ? { display: "flex" } : { display: "none" }}
          >
            <p>{tenthsection.testimonial1.answer}</p>{" "}
          </div>
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                2: !open[2],
                1: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
              });
            }}
          >
            <p>{tenthsection.testimonial2.question}</p>{" "}
            {!open[2] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    2: !open[2],
                    1: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 2: !open[2] });
                }}
              />
            )}
          </div>
          <div
            className={open[2] ? "faq-content transition" : "faq-content"}
            style={
              open[2]
                ? {
                    display: "flex",
                  }
                : { display: "none" }
            }
          >
            <p>{tenthsection.testimonial2.answer}</p>{" "}
          </div>
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                3: !open[3],
                2: false,
                1: false,
                4: false,
                5: false,
                6: false,
                7: false,
              });
            }}
          >
            <p>{tenthsection.testimonial3.question}</p>{" "}
            {!open[3] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    3: !open[3],
                    2: false,
                    1: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 3: !open[3] });
                }}
              />
            )}
          </div>

          
       
          <div
            className={open[3] ? "faq-content transition" : "faq-content"}
            style={open[3] ? { display: "flex" } : { display: "none" }}
          >
            <p>{tenthsection.testimonial3.answer}</p>{" "}
          </div>

              <div
                className="faq"
                onClick={() => {
                  setOpen({
                    ...open,
                    4: !open[4],
                    2: false,
                    1: false,
                    3: false,
                    5: false,
                    6: false,
                    7: false,
                  });
                }}
              >
                <p>Using Cooper Kits with multiple children?</p>{" "}
                {!open[4] ? (
                  <IoIosArrowDown
                    size={25}
                    onClick={() => {
                      setOpen({
                        ...open,
                        4: !open[4],
                        2: false,
                        1: false,
                        3: false,
                        5: false,
                        6: false,
                        7: false,
                      });
                    }}
                  />
                ) : (
                  <IoIosArrowUp
                    size={25}
                    onClick={() => {
                      setOpen({ ...open, 4: !open[4] });
                    }}
                  />
                )}
              </div>
              <div
                className={open[4] ? "faq-content transition" : "faq-content"}
                style={open[4] ? { display: "flex" } : { display: "none" }}
              >
                <p>Cooper Kits, as a family kit, have always been designed with a foundation of ‘togetherness’. Cooper kits will often contain group
                   / family games and always has a book to read together. Printed activity sheets within any Cooper Kit are also provided in your digital
                    resources to download and print more as needed. For many projects, we include multiple components for several members of the family. 
                    Some projects feature cooperative learning as the group / family works on them together (i.e. a giant Rube Goldberg machine). 
                    Each kit comes with a coveted collectible medallion that can be hard to share, so you have the option of adding in extra medallions in your kits 
                  at checkout. If you have more than 3 children using the kits in your group, we recommend getting an additional Cooper Kit.</p>{" "}
              </div>

        </div>
        <div className="faq-right">
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                5: !open[5],
                2: false,
                3: false,
                1: false,
                4: false,
                6: false,
                7: false,
              });
            }}
          >
            <p>{tenthsection.testimonial4.question}</p>{" "}
            {!open[5] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    5: !open[5],
                    2: false,
                    3: false,
                    1: false,
                    4: false,
                    6: false,
                    7: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 5: !open[5] });
                }}
              />
            )}
          </div>
          <div
            className={open[5] ? "faq-content transition" : "faq-content"}
            style={open[5] ? { display: "flex" } : { display: "none" }}
          >
            <p>{tenthsection.testimonial4.answer}</p>{" "}
          </div>
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                6: !open[6],
                2: false,
                3: false,
                4: false,
                1: false,
                5: false,
                7: false,
              });
            }}
          >
            <p>{tenthsection.testimonial5.question}</p>{" "}
            {!open[6] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    6: !open[6],
                    2: false,
                    3: false,
                    4: false,
                    1: false,
                    5: false,
                    7: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 6: !open[6] });
                }}
              />
            )}
          </div>
          <div
            className={open[6] ? "faq-content transition" : "faq-content"}
            style={open[6] ? { display: "flex" } : { display: "none" }}
          >
            <p>{tenthsection.testimonial5.answer}</p>{" "}
          </div>
          <div
            className="faq"
            onClick={() => {
              setOpen({
                ...open,
                7: !open[7],
                2: false,
                3: false,
                4: false,
                5: false,
                1: false,
                6: false,

              });
            }}
          >
            <p>{tenthsection.testimonial6.question}</p>{" "}
            {!open[7] ? (
              <IoIosArrowDown
                size={25}
                onClick={() => {
                  setOpen({
                    ...open,
                    6: !open[7],
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    1: false,
                  });
                }}
              />
            ) : (
              <IoIosArrowUp
                size={25}
                onClick={() => {
                  setOpen({ ...open, 7: !open[7] });
                }}
              />
            )}
          </div>
          <div
            className={open[7] ? "faq-content transition" : "faq-content"}
            style={open[7] ? { display: "flex" } : { display: "none" }}
          >
            <p>{tenthsection.testimonial6.answer}</p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TenthSection;
