import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Shared/Loader';
// import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";
import { BiChevronLeft } from 'react-icons/bi';
import ScrollToTop from '../Shared/ScrollToTop';
// import gtag from 'ga-gtag';
import { dataLayer } from '../../dataLayer';
import { v4 as uuidv4 } from 'uuid';

function Email({ form, setForm, page, setPage, signupForm, step4 }) {
  const [message, setMessage] = useState(false);
  const [homeschool, setHomeschool] = useState('');
  // console.log(signupForm);

  const validateEmail = (email) => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (emailRegex.test(email) === false) {
      setForm({ ...form, mailError: false, email: email });
    } else {
      setForm({ ...form, mailError: true, email: email });
    }
  };
  const [loader, setLoader] = useState(false);
  const disableButton = () => {
    if (
      form.mailError === false ||
      form.email === '' ||
      form.agreed === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  var simulateClick = function (elem) {
    // Create our event (with options)
    var evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
  };
  var simulateClick2 = function (elem) {
    // Create our event (with options)
    var evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    // If cancelled, don't dispatch our event
    var canceled = !elem.dispatchEvent(evt);
  };

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  // ReactGA.initialize("UA-34790251-1");
  // ReactGA.pageview(window.location.pathname + window.location.search);

  function signUpBirthdayEmail() {
    // Your sign-up logic here

    // Push data to dataLayer
    if (disableButton() === false) {
      const userId = uuidv4();

      let priceItemOne = form.standardBoxes[0].price;
      priceItemOne = priceItemOne.substring(1);
      priceItemOne = parseFloat(priceItemOne);

      let priceItemTwo = form.standardBoxes[1].price;
      priceItemTwo = priceItemTwo.substring(1);
      priceItemTwo = parseFloat(priceItemTwo);

      let priceItemThree = form.standardBoxes[2].price;
      priceItemThree = priceItemThree.substring(1);
      priceItemThree = parseFloat(priceItemThree);

      let itemString = form.standardBoxes[0].id;
      let itemStringConvert = itemString.toString();
      let itemString2 = form.standardBoxes[1].id;
      let itemStringConvert2 = itemString2.toString();
      let itemString3 = form.standardBoxes[2].id;
      let itemStringConvert3 = itemString3.toString();

      dataLayer.push({ ecommerce: null });

      dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: form.title,
          item_list_name: form.title,
          items: [
            {
              item_id: itemStringConvert,
              item_name: form.standardBoxes[0].titlehomeschooling,

              discount: form.standardBoxes[0].discount,
              index: form.standardBoxes[0].id,

              price: priceItemOne,
              quantity: 1,
            },

            {
              item_id: itemStringConvert2,
              item_name: form.standardBoxes[1].titlehomeschooling,

              discount: form.standardBoxes[1].discount,
              index: form.standardBoxes[1].id,

              price: priceItemTwo,
              quantity: 1,
            },
            {
              item_id: itemStringConvert3,
              item_name: form.standardBoxes[2].titlehomeschooling,

              discount: form.standardBoxes[2].discount,
              index: form.standardBoxes[2].id,

              price: priceItemThree,
              quantity: 1,
            },
          ],
        },
      });

      var user_data = {
        user_id: userId, // Add the unique User ID here

        email_address: form.email,
        address: {
          first_name: form.name,
          last_name: form.lastName,
        },
      };
      dataLayer.push({ user_data });
    }
  }
  return (
    <ScrollToTop>
      {/* <form
        action="https://cooperkid.com/wp-content/themes/cooperkid/submit.php"
        method="POST"
      > */}
      <div className='email-main'>
        <h3>{step4.header}</h3>
        <h5>{step4.subheader}</h5>
        <div className='input-container'>
          <input
            type='email'
            value={form.email}
            onChange={(e) => {
              validateEmail(e.target.value);
              setMessage(false);
            }}
            required
            name='email'
          />
          <label className={form.email && 'filled'}>Email</label>
        </div>
        {!form.mailError ? (
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              textAlign: 'left',
              width: '300px',
              marginTop: '5px',
            }}
          >
            Please enter your email address in format: yourname@example.com!
          </p>
        ) : null}

        <input type='hidden' name='firstName' value={form.name} />
        <input type='hidden' name='lastName' value={form.lastName} />
        <br></br>
        {message ? (
          <p className='error-message-2'>
            Please Fill Email Address and Accept to our Terms and Privacy Policy
          </p>
        ) : (
          ''
        )}

        <div className='agreement'>
          <label className='container'>
            <input
              type='checkbox'
              onChange={() => {
                setForm({ ...form, agreed: !form.agreed });
              }}
              required
              checked={form.agreed}
              className='checkbox'
            />
            <span className='checkmark'></span>
          </label>
          <p className='agreement-paragraph'>
            I agree to the{' '}
            <Link
              to={
                window.location.pathname.includes('homeschooling')
                  ? '/homeschooling/terms-of-use'
                  : '/terms-of-use'
              }
              className='hoverable'
            >
              Terms
            </Link>{' '}
            and{' '}
            <Link
              to={
                window.location.pathname.includes('homeschooling')
                  ? '/homeschooling/privacy-policy'
                  : '/privacy-policy'
              }
              className='hoverable'
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
        {loader ? <Loader /> : null}
        {/* <Loader /> */}

        <button
          type='submit'
          formTarget='dummyframe'
          onClick={() => {
            // gtag('event', "stepFourBirthday", {
            //   event_category: "signupFlow",
            //   event_label: "submit",
            // });
            signUpBirthdayEmail();
            // ReactGA.event({
            //   category: "Signup Flow Lead",
            //   action: "Lead",
            //   label: "Sign-up",
            // });
            // ReactPixel.track("Lead");
            if (localStorage.getItem('homeschool') === 'true') {
              if (disableButton() === false) {
                var someLink1 = document.getElementById('submitIFSbirthday');
                document.getElementById('inf_field_Email').value = form.email;
                document.getElementById('inf_custom_ShippingName').value =
                  form.name + ' ' + form.lastName;
                // document.getElementById("inf_field_LastName").value =
                //   form.lastName;
                if (!form.gifting) {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8429'
                  ).checked = true;
                } else {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8431'
                  ).checked = true;
                }
                document.getElementById(
                  'inf_option_IagreetothePrivacyPolicy'
                ).checked = true;

                simulateClick2(someLink1);
                timeout(2000).then((e) => {
                  setPage(page + 1);
                });
                setLoader(true);
              } else {
                setMessage(true);
              }
            } else if (localStorage.getItem('birthday') === 'true') {
              // console.log("birthday");
              if (disableButton() === false) {
                var someLink1 = document.getElementById('submitIFSbirthday');
                document.getElementById('inf_field_Email').value = form.email;
                document.getElementById('inf_custom_ShippingName').value =
                  form.name + ' ' + form.lastName;
                // document.getElementById("inf_field_LastName").value =
                //   form.lastName;
                if (!form.gifting) {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8529'
                  ).checked = true;
                } else {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8531'
                  ).checked = true;
                }
                document.getElementById(
                  'inf_option_IagreetothePrivacyPolicy'
                ).checked = true;

                simulateClick2(someLink1);
                timeout(2000).then((e) => {
                  setPage(page + 1);
                });
                setLoader(true);
              } else {
                setMessage(true);
              }
            } else {
              if (disableButton() === false) {
                var someLink = document.getElementById('submitIFS');
                document.getElementById('inf_field_Email').value = form.email;
                document.getElementById('inf_custom_ShippingName').value =
                  form.name + ' ' + form.lastName;
                // document.getElementById("inf_field_LastName").value =
                //   form.lastName;
                if (!form.gifting) {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8171'
                  ).checked = true;
                } else {
                  document.getElementById(
                    'inf_option_WheredoyouwanttosendyourCooperKits_8173'
                  ).checked = true;
                }
                document.getElementById(
                  'inf_option_IagreetothePrivacyPolicy'
                ).checked = true;

                simulateClick(someLink);
                timeout(2000).then((e) => {
                  setPage(page + 1);
                });
                setLoader(true);
              } else {
                setMessage(true);
              }
            }
          }}
          className='hoverable'
        >
          Continue
        </button>

        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className='go-back-box'
        >
          <BiChevronLeft />
          Go back
        </button>
      </div>

      <iframe
        name='dummyframe'
        style={{ display: 'none', width: '0', height: '0' }}
        title='dummyframe'
      ></iframe>
      {/* </form> */}

      {localStorage.getItem('birthday') === 'true' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: signupForm.acf.ifs_form_birthday,
          }}
          className='step-4-submission'
        ></div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: signupForm.acf.ifs_form_birthday }}
          className='step-4-submission'
        ></div>
      )}
    </ScrollToTop>
  );
}

export default Email;
