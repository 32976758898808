import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Music() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='music-sound'>
        <main
          className='wp-container-10 wp-block-group'
          id='wp--skip-link--target'
        >
          <div className='wp-container-6 wp-block-group'>
            <h1
              style={{
                marginBottom: 'var(--wp--custom--spacing--medium, 6rem)',
              }}
              className='alignwide wp-block-post-title'
            >
              Music: A Sound Odyssey Music Video Contest!
            </h1>
            <figure
              style={{
                marginBottom: 'var(--wp--custom--spacing--medium, 6rem)',
              }}
              className='alignwide wp-block-post-featured-image'
            >
              <img
                width={240}
                height={159}
                src='https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_m.jpg'
                className='attachment-post-thumbnail size-post-thumbnail wp-post-image'
                alt='Music: A Sound Odyssey Music Video Contest!'
                loading='lazy'
                srcSet='https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_m.jpg 240w, https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_m-90x59.jpg 90w'
                sizes='(max-width: 240px) 100vw, 240px'
              />
            </figure>
            <hr className='wp-block-separator alignwide is-style-wide' />
          </div>
          <div
            style={{ height: 32 }}
            aria-hidden='true'
            className='wp-block-spacer'
          />
          <div className='wp-container-7 entry-content wp-block-post-content'>
            <h2>
              Here are the entries for our&nbsp;
              <strong>
                <em>Music: A Sound Odyssey</em>
              </strong>
              &nbsp;Cooper Kit Music Video Contest!
            </h2>
            <p>
              <iframe
                src='http://www.youtube.com/embed/ls3ok0EDe1c?feature=oembed'
                title='YouTube video player'
                frameborder='0'
                width={500}
                height={350}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </p>
            <p>&nbsp;</p>
            <p>
              <iframe
                src='https://www.youtube.com/embed/gHbXPZd3hzo'
                title='YouTube video player'
                frameborder='0'
                width={500}
                height={350}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </p>
            <p>&nbsp;</p>
            <h3>
              WHICH IS YOUR FAVORITE? WATCH THEM BOTH, TELL US WHY IN THE
              COMMENTS BELOW, AND DON’T FORGET TO VOTE!
            </h3>
            {/* <p>[yop_poll id=”13″]</p> */}
            <p>&nbsp;</p>
            <p>
              Want to create your own music video? Learn more, including tips on
              creating your own no-budget music video,&nbsp;in your&nbsp;
              <a href='https://cooperandkid.customerhub.net/music-a-sound-odyssey-digital-resources'>
                Customer Portal
              </a>
              . Or find out more&nbsp;about the contest and{' '}
              <a href='https://cooperandkid.customerhub.net/music-video-contest'>
                submit your entry
              </a>{' '}
              in the Customer Portal.
            </p>
            <p>&nbsp;</p>
            <figure
              id='attachment_8074'
              aria-describedby='caption-attachment-8074'
              //   style={{ width: 640 }}
              className='wp-caption aligncenter'
            >
              <img
                loading='lazy'
                className='size-full wp-image-8074'
                alt='Family Band Music Video Contest'
                src='https://cooperkid.com/wp-content/uploads/2014/10/2323639056_6c939daa7a_z.jpg'
                srcSet='https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_z.jpg 640w, https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_z-300x199.jpg 300w, https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_z-328x218.jpg 328w, https://cooper.blackbird.marketing/wp-content/uploads/2014/10/2323639056_6c939daa7a_z-90x59.jpg 90w'
                sizes='(max-width: 640px) 100vw, 640px'
              />
              <figcaption
                id='caption-attachment-8074'
                className='wp-caption-text'
              >
                Photo by:{' '}
                <a href='https://www.flickr.com/photos/haerold/2323639056/'>
                  Harold Heindell Tejada
                </a>
              </figcaption>
            </figure>
            <p style={{ textAlign: 'center' }}></p>
          </div>
          <div
            style={{ height: 32 }}
            aria-hidden='true'
            className='wp-block-spacer'
          />
          <div className='wp-container-9 wp-block-group'>
            <div className='wp-container-8 wp-block-group'>
              <div
                style={{ fontStyle: 'italic', fontWeight: 400 }}
                className='wp-block-post-date has-small-font-size'
              >
                <time dateTime='2014-10-30T17:00:06+00:00'>
                  October 30, 2014
                </time>
              </div>
              <div className='wp-block-post-author has-small-font-size'>
                <div className='wp-block-post-author__content'>
                  <p className='wp-block-post-author__name' />
                </div>
              </div>
            </div>
            <div
              style={{ height: 32 }}
              aria-hidden='true'
              className='wp-block-spacer'
            />
            <hr className='wp-block-separator is-style-wide' />
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}

export default Music;
