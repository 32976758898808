import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function NewsletterThankyou() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='news-letter-thankyou'>
        <h1>Success!</h1>
        <Link
          to={
            window.location.pathname.includes('homeschooling')
              ? '/homeschooling'
              : '/'
          }
          className='hoverable'
        >
          Browse the Cooper Kit!
        </Link>
      </div>
      <Footer />
    </>
  );
}

export default NewsletterThankyou;
