import React from 'react';

function EighthSection({ promise, section6 }) {
  return (
    <div className='eighth-section-pk' ref={promise}>
      <div className='main-wrapper pk-eighth-section'>
        <h1>{section6.subheading}</h1>
        <h2>{section6.heading}</h2>
        <div className='eighth-section-images'>
          <div className='eighth-section-image'>
            <img src={section6.image1} alt='img' />
            <img
              src={section6.image1_arrow}
              alt='img'
              className='eighth-image-text'
            />
          </div>
          <div className='eighth-section-image'>
            <img src={section6.image2} alt='img' />
          </div>
          <div className='eighth-section-image'>
            <img src={section6.image3} alt='img' />
            <img
              src={section6.image3_arrow}
              alt='img'
              className='eighth-image-text2'
            />
          </div>
        </div>
        {/* <p>{section6.paragraph}</p> */}
        <p
          dangerouslySetInnerHTML={{
            __html: section6.paragraph,
          }}
        ></p>
      </div>
    </div>
  );
}

export default EighthSection;
