import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/order.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function International2() {
  return (
    <div className='white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='content'>
        <div id='tve_flt' className='tve_flt tcb-style-wrap'>
          <div
            id='tve_editor'
            className='tve_shortcode_editor tar-main-content'
            data-post-id={15467}
          >
            <div className='thrv_wrapper thrv_heading' data-tag='h1'>
              <h1 style={{ textAlign: 'center' }}>
                UK &amp; AUSTRALIA ORDER FORMS
              </h1>
            </div>
            <div className='thrv_wrapper thrv-columns'>
              <div className='tcb-flex-row tcb--cols--2'>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div className='tcb-clear' data-css='tve-u-162daf6f91c'>
                      <div
                        className='thrv_wrapper tve_image_caption'
                        data-css='tve-u-162daef51cf'
                      >
                        <span
                          className='tve_image_frame'
                          style={{ width: '100%' }}
                        >
                          <img
                            className='tve_image wp-image-12351 colorbox-15467'
                            alt=''
                            width={236}
                            height={150}
                            title='UKresized'
                            data-id={12351}
                            src='https://cms.cooperkid.com/wp-content/uploads/2022/06/UKresized.png'
                            style={{ width: '100%' }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daf69195'
                    >
                      <span
                        className='tve_image_frame'
                        style={{ width: '100%' }}
                      >
                        <img
                          className='tve_image wp-image-12352 colorbox-15467'
                          alt=''
                          width={300}
                          height={150}
                          title='AUSresized'
                          data-id={12352}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/AUSresized.png'
                          style={{ width: '100%' }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='thrv_wrapper thrv-columns'>
              <div className='tcb-flex-row tcb--cols--3'>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daedeb11'
                    >
                      <span
                        className='tve_image_frame'
                        style={{ width: '100%' }}
                      >
                        <img
                          className='tve_image wp-image-13837 colorbox-15467'
                          alt=''
                          width={300}
                          height={299}
                          title='1-quarterly-subscription-cooper-kid-300x299'
                          data-id={13837}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/1-quarterly-subscription-cooper-kid-300x299-5.jpg'
                          style={{ width: '100%' }}
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-1630353b188'
                            style={{ fontSize: 18 }}
                          >
                            $65 per Quarter
                          </span>
                          <br />
                          <span
                            data-css='tve-u-1630353c000'
                            style={{ fontSize: 16 }}
                          >
                            $54 Shipping.
                          </span>
                        </strong>
                        &nbsp;
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        Recurring subscription where you get 1 Cooper Kit every
                        3 months billed quarterly. Cancel anytime.
                      </p>
                      <p
                        data-css='tve-u-1630353ce4e'
                        style={{ textAlign: 'center' }}
                      >
                        <strong>Total price: $119 per quarter.</strong>
                        <br />
                        <strong>​</strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-162daf144f5'
                      data-tcb_hover_state_parent=''
                      data-button-style='default'
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/UK-Quarterly-Subscription'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-162daefffe7'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daee1640'
                    >
                      <span className='tve_image_frame'>
                        <img
                          className='tve_image wp-image-14143 colorbox-15467'
                          alt=''
                          width={300}
                          height={337}
                          title='yearly-best value'
                          data-id={14143}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/yearly-best-value-4.png'
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            style={{ fontSize: 18 }}
                            data-css='tve-u-1630353a493'
                          >
                            $59 per Kit.
                          </span>
                          <br />
                          <span
                            style={{ fontSize: 16 }}
                            data-css='tve-u-16303539105'
                          >
                            $54 Shipping per Kit.
                          </span>
                        </strong>
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        Recurring subscription where you get 1 Cooper Kit every
                        3 months billed yearly.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            style={{ fontSize: 18 }}
                            data-css='tve-u-1630353e75b'
                          >
                            Total price: $452 per year
                          </span>
                          <br />
                          <span
                            style={{ fontSize: 16 }}
                            data-css='tve-u-1630353f704'
                          >
                            (paid at once).
                          </span>
                        </strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-162daf170d6'
                      data-tcb_hover_state_parent=''
                      data-button-style='default'
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/UK-Yearly-Subscription'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-162daefffe7'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daee45f9'
                    >
                      <span className='tve_image_frame'>
                        <img
                          className='tve_image wp-image-13839 colorbox-15467'
                          alt=''
                          width={300}
                          height={299}
                          title='gift-one-year-cooper-kid-300x299'
                          data-id={13839}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/gift-one-year-cooper-kid-300x299-5.jpg'
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                      <p style={{ textAlign: 'center' }}>
                        <em>
                          <span
                            data-css='tve-u-16303537366'
                            style={{ fontSize: 18 }}
                          >
                            ​
                          </span>
                        </em>
                        <strong>
                          <span
                            data-css='tve-u-16303537368'
                            style={{ fontSize: 18 }}
                          >
                            $65 per Kit
                          </span>
                          <br />
                          <span
                            data-css='tve-u-163035382bc'
                            style={{ fontSize: 16 }}
                          >
                            $54 Shipping.
                          </span>
                        </strong>
                        <span
                          data-css='tve-u-163035382d1'
                          style={{ fontSize: 16 }}
                        >
                          &nbsp;
                        </span>
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        Recurring subscription where you get 1 Cooper Kit every
                        3 months billed quarterly. Cancel anytime.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-16303544395'
                            style={{ fontSize: 18 }}
                          >
                            Total price: $476 per year
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-16303542e77'
                            style={{ fontSize: 16 }}
                          >
                            (paid at once).
                          </span>
                        </strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-162daf02280'
                      data-tcb_hover_state_parent=''
                      data-button-style='default'
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/UK-Year-of-Kits'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-162daefffe7'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='thrv_wrapper thrv_heading' data-tag='h2'>
              <h2 style={{ textAlign: 'center' }}>WHEN WILL I GET MY KITS?</h2>
            </div>
            <div
              className='thrv_wrapper thrv_text_element'
              data-css='tve-u-16303600f40'
            >
              <p data-css='tve-u-163035ffd76'>
                You’ll get our newest surprise kit shipped to you in our
                international batch shipment in March, June, September, and
                December and they ship between the 15th and 20th of each month.
                If you need it mailed sooner, you can select expedited shipping
                at checkout.
              </p>
            </div>
          </div>
        </div>
        <div className='tcb_flag' style={{ display: 'none' }} />
        <span
          id='tve_leads_end_content'
          style={{
            display: 'block',
            visibility: 'hidden',
            border: '1px solid transparent',
          }}
        />
      </div>

      <Footer />
    </div>
  );
}

export default International2;
