import React from 'react';

function Tab3({ tab3 }) {
  return (
    <div className='tab1'>
      <div className='tab2-text'>
        <h1 id='tab3-h1'>{tab3.subheading} </h1>
        <h2 id='tab3-h2'>{tab3.heading}</h2>
        {/* <p>{tab3.paragraph}</p> */}
        <p
          dangerouslySetInnerHTML={{
            __html: tab3.paragraph,
          }}
        ></p>
      </div>

      <img src={tab3.image} alt='img' className='desktop-image-tab' />
      <img src={tab3.imagemobile} alt='img' className='mobile-image-tab' />
    </div>
  );
}

export default Tab3;
