import React from 'react';
import './styles.css';

const Iframe = () => {
  return (
    <div className='iframe-container'>
      <iframe
        src='https://www.youtube.com/embed/BuEQkiJR6xc?si=-c-Zw0D2fg7UprA4'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Iframe;
