import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/order.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Canada() {
  return (
    <div className='white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='content'>
        <div id='tve_flt' className='tve_flt tcb-style-wrap'>
          <div
            id='tve_editor'
            className='tve_shortcode_editor tar-main-content'
            data-post-id={15461}
          >
            <div className='thrv_wrapper thrv_heading' data-tag='h1'>
              <h1 style={{ textAlign: 'center' }}>CANADA ORDER FORMS</h1>
            </div>
            <div
              className='thrv_wrapper tve_image_caption'
              data-css='tve-u-162daef51cf'
            >
              <span className='tve_image_frame'>
                <img
                  className='tve_image wp-image-12350 colorbox-15461'
                  alt=''
                  width={300}
                  height={150}
                  title='CanadaNewresized'
                  data-id={12350}
                  src='https://cms.cooperkid.com/wp-content/uploads/2022/08/CanadaNewresized.png'
                  //   style={{ width: "100%" }}
                />
              </span>
            </div>
            <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
              <p data-css='tve-u-1632227d778' style={{ textAlign: 'center' }}>
                Get your Cooper Kits shipped straight to your doorstep. Duties
                and taxes are included!
              </p>
            </div>
            <div className='thrv_wrapper thrv-columns'>
              <div className='tcb-flex-row tcb--cols--3'>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daedeb11'
                    >
                      <span
                        className='tve_image_frame'
                        style={{ width: '100%' }}
                      >
                        <img
                          className='tve_image wp-image-13837 colorbox-15461'
                          alt=''
                          width={300}
                          height={299}
                          title='1-quarterly-subscription-cooper-kid-300x299'
                          data-id={13837}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/08/1-quarterly-subscription-cooper-kid-300x299-1.jpg'
                          style={{ width: '100%' }}
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-1632224f393'
                            style={{ fontSize: 18 }}
                          >
                            $65 per Quarter. $20 Shipping.
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-1632222f4e7'
                            style={{ fontSize: 18 }}
                          >
                            ​
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-16303510c77'
                            style={{ fontSize: 16 }}
                          >
                            <strong>
                              <span
                                data-css='tve-u-1632222f4e9'
                                style={{
                                  fontSize: 16,
                                  color: 'rgb(140, 56, 75)',
                                }}
                              >
                                DUTIES &amp; TAXES ARE INCLUDED!
                              </span>
                            </strong>
                          </span>
                        </strong>
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        Recurring subscription where you get 1 Cooper Kit every
                        3 months billed quarterly. Cancel anytime.
                      </p>
                      <p
                        data-css='tve-u-16303524caf'
                        style={{ textAlign: 'center' }}
                      >
                        <strong>
                          <span
                            data-css='tve-u-16303522211'
                            style={{ fontSize: 18 }}
                          >
                            Total price: $85 per quarter.
                          </span>
                        </strong>
                        <br />
                        <strong>​</strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-1632228a949'
                      data-tcb_hover_state_parent=''
                      data-button-style='ghost'
                      // style={{ display: "none" }}
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/Canada-Quarterly-Subscription?_ga=2.162252795.632528784.1525278568-1135434099.1525190151'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-16322263dd4'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daee1640'
                    >
                      <span className='tve_image_frame'>
                        <img
                          className='tve_image wp-image-14143 colorbox-15461'
                          alt=''
                          width={300}
                          height={337}
                          title='yearly-best value'
                          data-id={14143}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/yearly-best-value-4.png'
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone'>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-1632224df12'
                            style={{ fontSize: 18 }}
                          >
                            $59 +$20 Shipping per Kit.
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-16322242093'
                            style={{ fontSize: 18 }}
                          >
                            ​
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-16322242095'
                            style={{ fontSize: 16, color: 'rgb(140, 56, 75)' }}
                          >
                            DUTIES &amp; TAXES ARE INCLUDED!
                          </span>
                        </strong>
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        Recurring subscription where you get 1 Cooper Kit every
                        3 months billed yearly.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-16303521367'
                            style={{ fontSize: 18 }}
                          >
                            Total price: $316 per year
                          </span>
                          <br />
                          <span
                            data-css='tve-u-163035203ce'
                            style={{ fontSize: 16 }}
                          >
                            (paid at once).
                          </span>
                        </strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-1632228c5f8'
                      data-tcb_hover_state_parent=''
                      data-button-style='ghost'
                      // style={{ display: "none" }}
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/Canada-Yearly-Subscription'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-16322263dd4'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='tcb-flex-col'>
                  <div className='tcb-col tve_empty_dropzone'>
                    <div
                      className='thrv_wrapper tve_image_caption'
                      data-css='tve-u-162daee45f9'
                    >
                      <span className='tve_image_frame'>
                        <img
                          className='tve_image wp-image-13839 colorbox-15461'
                          alt=''
                          width={300}
                          height={299}
                          title='gift-one-year-cooper-kid-300x299'
                          data-id={13839}
                          src='https://cms.cooperkid.com/wp-content/uploads/2022/06/gift-one-year-cooper-kid-300x299-5.jpg'
                          sizes='(max-width: 300px) 100vw, 300px'
                        />
                      </span>
                    </div>
                    <div className='thrv_wrapper thrv_text_element tve_empty_dropzone on_hover'>
                      <p style={{ textAlign: 'center' }}>
                        <em>
                          <span
                            data-css='tve-u-1630351b749'
                            style={{ fontSize: 18 }}
                          >
                            ​
                          </span>
                        </em>
                        <span
                          data-css='tve-u-1632224c719'
                          style={{ fontSize: 18 }}
                        >
                          <strong>
                            $65 +&nbsp;$20 Shipping&nbsp;
                            <strong>per Kit </strong>.
                          </strong>
                          ​
                        </span>
                        <br />
                        <span
                          data-css='tve-u-163222478a7'
                          style={{ fontSize: 18 }}
                        >
                          ​
                        </span>
                        <br />
                        <span
                          data-css='tve-u-1630351cb77'
                          style={{ fontSize: 16 }}
                        >
                          <strong>
                            <span
                              data-css='tve-u-16303519a6f'
                              style={{
                                fontSize: 16,
                                color: 'rgb(140, 56, 75)',
                              }}
                            >
                              DUTIES &amp; TAXES ARE INCLUDED!
                            </span>
                          </strong>
                        </span>
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        One-time fee, no subscription. Receive or gift 1 Cooper
                        Kit every 3 months for 1 year.
                      </p>
                      <p style={{ textAlign: 'center' }}>
                        <strong>
                          <span
                            data-css='tve-u-1630351e34d'
                            style={{ fontSize: 18 }}
                          >
                            Total price: $340 per year
                          </span>
                        </strong>
                        <br />
                        <strong>
                          <span
                            data-css='tve-u-1630351f5d8'
                            style={{ fontSize: 16 }}
                          >
                            (paid at once).
                          </span>
                        </strong>
                      </p>
                    </div>
                    <div
                      className='thrv_wrapper thrv-button'
                      data-css='tve-u-162daf144f5'
                      data-tcb_hover_state_parent=''
                      data-button-style='ghost'
                      // style={{ display: "none" }}
                    >
                      <a
                        href='https://aw135.infusionsoft.com/app/orderForms/canada-year-of-kits'
                        className='tcb-button-link'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='tcb-button-texts'>
                          <span
                            className='tcb-button-text thrv-inline-text'
                            data-css='tve-u-16322263dd4'
                          >
                            <strong>BUY NOW</strong>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='thrv_wrapper thrv_heading' data-tag='h2'>
              <h2 style={{ textAlign: 'center' }}>WHEN WILL I GET MY KITS?</h2>
            </div>
            <div
              className='thrv_wrapper thrv_text_element tve_empty_dropzone'
              data-css='tve-u-1630361f3ba'
            >
              <p data-css='tve-u-1630361e1a6'>
                You’ll get our newest surprise kit shipped to you in our
                international batch shipment in March, June, September, and
                December and they ship between the 15th and 20th of each month.
                If you need it mailed sooner, you can select expedited shipping
                at checkout. Duties and taxes are included with all options{' '}
                <strong>except for expedited shipping</strong>.
              </p>
            </div>
          </div>
        </div>
        <div className='tcb_flag' style={{ display: 'none' }} />
        <span
          id='tve_leads_end_content'
          style={{
            display: 'block',
            visibility: 'hidden',
            border: '1px solid transparent',
          }}
        />
      </div>

      <Footer />
    </div>
  );
}

export default Canada;
