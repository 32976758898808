import React from 'react';

function Tab2({ tab2 }) {
  return (
    <div className='tab1'>
      <div className='tab2-text'>
        <h1>{tab2.subheading}</h1>
        <h2>{tab2.heading}</h2>
        {/* <p>{tab2.paragraph}</p> */}
        <p
          dangerouslySetInnerHTML={{
            __html: tab2.paragraph,
          }}
        ></p>
      </div>

      <img src={tab2.image} alt='img' className='desktop-image-tab' />
      <img src={tab2.imagemobile} alt='img' className='mobile-image-tab' />
    </div>
  );
}

export default Tab2;
