import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import AdventureBox from './AdventureBox';
import group from '../../src/Components/Shared/Assets/Homepage/aagroup.png';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

const Adventure = () => {
  return (
    <>
      <Helmet>
        <title>Cooper and Kid: Family Adventure Box Themes</title>
        <meta
          name='title'
          content='Cooper and Kid: Family Adventure Box Themes'
        />
        <meta
          name='description'
          content='See why kids love every kit. Spend quality time with your child with family adventure boxes that support their social emotional development.'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='adventure-banner'>
        <div className='main-wrapper'>
          <h1>Explore, Learn and Laugh with each Cooper Kit Adventure</h1>
          <p>
            With every new kit you open the door to a whole new adventure,
            packed with a rich diversity of projects, resources and treasures!
            Cooper Kits are also a great way to build character and emotional
            intelligence. Let’s not forget to highlight that they are designed
            for kids and adults together - a perfect family bonding activity!
          </p>
          <Link to='/homeschooling/subscribe'>SUBSCRIBE NOW</Link>
        </div>
      </div>
      <div className='main-wrapper adventure-wrapper'>
        <AdventureBox />
      </div>

      <div className='cub-bck '>
        <div className='main-wrapper'>
          <div className='adv-box1'>
            <p>Start your Family Adventure!</p>
            <h1>SIGN UP NOW</h1>
            <p>Get surprised every 3 months with a new Cooper Kit adventure!</p>
            <Link to='/homeschooling/peek-inside'>SHOW MORE</Link>
          </div>
          <div>
            <img src={group} alt='img' />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Adventure;
