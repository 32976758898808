import React, { useEffect } from 'react';
import '../Components/Shared/Styles/homepage.css';

import ThirdSection from '../Components/Birthday1/ThirdSection';
import FourthSection from '../Components/Birthday1/FourthSection';
import FifthSection from '../Components/Birthday1/FifthSection';
import SixthSection from '../Components/Birthday1/SixthSection';
import SeventhSection from '../Components/Birthday1/SeventhSection';
import EighthSection from '../Components/Birthday1/EighthSection';
import NinthSection from '../Components/Birthday1/NinthSection';
import TenthSection from '../Components/Birthday1/TenthSection';
import EleventhSection from '../Components/Birthday1/EleventhSection';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Footer from '../Components/Shared/Footer';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import Loader from '../Components/Shared/Loader';
import FirstSectionBanner from '../Components/Birthday1/firstsectionBanner';
import SecondSectionBirthday from '../Components/Birthday1/SecondSection';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a FREE Slip N Slide with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Birthday({ form, setForm, birthday }) {
  useEffect(() => {
    localStorage.setItem('birthday', true);
  }, []);
  return birthday ? (
    <>
      <OfferBanner data={bannerData} />
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='privacyPolicy'
        containerClasses='cookie'
        debug={false}
        style={{
          background: '#5c2945',
          fontSize: '12px',
          padding: '10px 0px',
          height: 'fit-content',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '13px',
          backgroundColor: '#90dae5',
          padding: '10px 50px',
          borderRadius: '5px',
        }}
        expires={150}
      >
        <p className='cookie-paragraph'>
          {' '}
          We use cookies to provide website functionality, to analyze traffic on
          our Cooper & Kid Site, personalize content, serve targeted
          advertisements and to enable social media functionality. Our Privacy
          Policy provides more information and explains how to update your
          privacy policy. View our{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable cookie-link'
          >
            privacy-policy
          </Link>
        </p>
      </CookieConsent>
      <Navbar />

      <FirstSectionBanner
        form={form}
        setForm={setForm}
        firstsection={birthday.acf.firstsection}
      />
      <SecondSectionBirthday secondsection={birthday.acf.experience} />

      <ThirdSection thirdsection={birthday.acf.getstarted} />
      <FourthSection fourthsection={birthday.acf.bigbox} />
      <FifthSection fifthsection={birthday.acf.character} />
      <SixthSection sixthsection={birthday.acf.medallions} />
      <SeventhSection seventhsection={birthday.acf.testimonials} />
      <EighthSection eighthsection={birthday.acf.help} />
      <NinthSection ninthsection={birthday.acf.Family} />
      <TenthSection tenthsection={birthday.acf.faqs} />
      <EleventhSection eleventhsection={birthday.acf.awards} />
      <Footer />
    </>
  ) : (
    <>
      <Loader />
    </>
  );
}

export default Birthday;
