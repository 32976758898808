import React from "react";

import { Link } from "react-router-dom";

function FourthSection({ fourthsection }) {
  return (
    <div className="fourth-section-hp">
      <div className="main-wrapper homepage-fourth-section">
        <h1>{fourthsection.heading}</h1>
        <p>{fourthsection.subheading}</p>
        <img
          src={fourthsection.bigimagedesk.url}
          alt="big-box"
          className="big-box-desktop"
        />
        <img
          src={fourthsection.bigimagemob.url}
          alt="big-box"
          className="big-box-mobile"
        />
        {/* <img
          src={fourthsection.bottomimage.url}
          alt="big-box-label"
          className="big-box-label"
        /> */}
      </div>
   
    

          <Link
                  to={
                    window.location.pathname.includes("homeschooling")
                      ? "/homeschooling/subscribe"
                      : "/subscribe"
                  }
                  className="kit-button-link hoverable"
                >
                 GET YOUR KIT
                </Link>
                 
    </div>
  );
}

export default FourthSection;
