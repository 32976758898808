import image01 from './images/what_you_01.png';
import image001 from './images/what_you_01-old.png';
import image02 from './images/what_you_02.png';
import image002 from './images/what_you_02-old.png';
import image03 from './images/what_you_03.png';
import image04 from './images/what_you_04.png';
import image05 from './images/what_you_05.png';
import image06 from './images/what_you_06.png';
import image07 from './images/what_you_07.png';
// import image08 from './images/what_you_08.png';
// import image09 from './images/what_you_09.png';
import image10 from './images/what_you_10.png';
// import image11 from './images/what_you_11.png';
// import image12 from './images/what_you_12.png';
import image007 from './images/what_you_07-old.png';
import image008 from './images/what_you_08-old.png';
import image009 from './images/what_you_09-old.png';
import image010 from './images/what_you_10-old.png';
import image011 from './images/what_you_11-old.png';
import image012 from './images/what_you_12-old.png';
// import coin from './images/what-you_coins.png';
// import storyBook from './images/what-you_story-book.jpg';
import dinnerRecipies from './images/what-you_dinner-recipies.png';
import activityGuide from './images/what-you_activity-guide.png';
import emotional from './images/what-you_emotional.png';
import digital from './images/digital_downloads.png';

export const title = 'What you get every 3 months!';

export const img = [
  { image: image001 },
  { image: image002 },
  { image: image03 },
  { image: image04 },
  { image: image05 },
  { image: image06 },
  { image: image007 },
  { image: image008 },
  { image: image009 },
  { image: image010 },
  { image: image011 },
  { image: image012 },
];

export const textData = [
  {
    subTitle: 'A New Adventure in Every Kit!',
    pg: "Each Cooper Kit has a new mission, storyline and theme. It’s an immersive family adventure that's fascinating for both kids and adults!",
  },
  {
    subTitle: '5 or More Fun and Educational Family Projects',
    pg: 'The kit’s story-based adventure unfolds through five fun projects;  building, STEM, creativity, games and shipping box engineering.  Each kit is a rich diversity of projects, resources, and treasures!',
  },
  {
    subTitle: 'Bedtime Storybook',
    pg: 'A companion children’s book highlights the kit theme or featured character or social emotional lesson.',
  },
  {
    subTitle: 'Themed Dinner Recipes',
    pg: 'Take the adventure to the kitchen with 4 themed, kid-friendly dinner recipes.',
  },
  {
    subTitle: 'Beautiful Activity Guide',
    pg: 'Infused with humor, interesting facts, and easy instructions.',
  },
  {
    subTitle: 'Character and Social Emotional Skills',
    pg: 'A social emotional or character development skill is infused throughout each Cooper Kit, complete with a parenting guide.',
  },
  {
    subTitle: 'Collectible Medallion',
    pg: 'Kids LOVE the collectible medallion! A forever-reminder of the family time together, the challenge coin in each kit commemorates the learning, growth and bonding of each Cooper Kit adventure.',
  },
  {
    subTitle: 'Digital Downloads',
    pg: 'Enhance your adventure with bonus digital downloads and resources for each kit too!',
  },
];
export const mobileWhatYouGetData = [
  {
    subTitle: 'A New Adventure in Every Kit!',
    pg: "Each Cooper Kit has a new mission, storyline and theme. It’s an immersive family adventure that's fascinating for both kids and adults!",
    image: [image01],
  },
  {
    subTitle: '5 or More Fun and Educational Family Projects',
    pg: 'The kit’s story-based adventure unfolds through five fun projects; building, STEM, creativity, games and shipping box engineering.  Each kit is a rich diversity of projects, resources, and treasures!',
    image: [image02],
  },
  {
    subTitle: 'Bedtime Storybook',
    pg: 'A companion children’s book highlights the kit theme or featured character or social emotional lesson.',
    image: [image07],
  },
  {
    subTitle: 'Themed Dinner Recipes',
    pg: 'Take the adventure to the kitchen with 4 themed, kid-friendly dinner recipes.',
    image: [dinnerRecipies],
  },
  {
    subTitle: 'Beautiful Activity Guide',
    pg: 'Infused with humor, interesting facts, and easy instructions.',
    image: [activityGuide],
  },
  {
    subTitle: 'Character and Social Emotional Skills',
    pg: 'A social emotional or character development skill is infused throughout each Cooper Kit, complete with a parenting guide.',
    image: [emotional],
  },
  {
    subTitle: 'Collectible Medallion',
    pg: 'Kids LOVE the collectible medallion! A forever-reminder of the family time together, the challenge coin in each kit commemorates the learning, growth and bonding of each Cooper Kit adventure.',
    image: [image10],
  },
  {
    subTitle: 'Digital Downloads',
    pg: 'Enhance your adventure with bonus digital downloads and resources for each kit too!',
    image: [digital],
  },
];
