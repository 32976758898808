import { useRef } from 'react';
import { OfferBanner } from './components/As_OfferBanner';
import { Header } from './header/Header';
import { header } from './components/data/header';
import { Testimonials } from './testimonials/Testimonials';
import { Product } from './product/Product';
import { Slider } from './components/slider/Slider';
import { Compair } from './compair/Compair';
import { Abouts } from './abouts/Abouts';
import { slides, slidesContent, title } from './components/data/slides';
import './moreFunFamilyTime.css';

const data = header[0];

export const MoreFunFamilyTime = () => {
  const productRef = useRef();

  return (
    <div className='as_layout'>
      <OfferBanner productRef={productRef} />
      <Header data={data} productRef={productRef} />
      <Testimonials />
      <Product productRef={productRef} />
      <Slider slides={slides} slidesContent={slidesContent} title={title} />
      <Compair />
      <Abouts productRef={productRef} />
    </div>
  );
};
