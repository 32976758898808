import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper";

function ThirdSection({ about }) {
  return (
    <div className="about-thirdSection">
      <h1>{about.subheadunderimg}</h1>
      <p>{about.paragraphunder}</p>
      <div className="about-cards about-cards-desktop">
        {about.steps.map((e) => (
          <div className="aboutCards">
            <div
              className="about-card"
              style={{ backgroundImage: `url(${e.imagebg})` }}
            >
              <div className="card-number">{e.stepnr}</div>
            </div>
            <div className="about-card-text">
              <p>{e.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="about-cards about-cards-mobile">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          centeredSlides={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          navigation={{
            clickable: true,
          }}
          breakpoints={{
            998: {
              width: 998,
              slidesPerView: 1,
              centeredSlides: true,
            },
            600: {
              // width: 600,
              slidesPerView: 2,
              centeredSlides: true,
            },
          }}
          // autoplay={{
          //   delay: 3500,
          //   disableOnInteraction: false,
          // }}
          modules={[Pagination, Navigation, Autoplay]}
          className="about-mySwiper"
        >
          {about.steps.map((e) => (
            <SwiperSlide>
              <div className="aboutCards">
                <div
                  className="about-card"
                  style={{ backgroundImage: `url(${e.imagebg})` }}
                >
                  <div className="card-number">{e.stepnr}</div>
                </div>
                <div className="about-card-text">
                  <p>{e.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ThirdSection;
