import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import image from '../Components/Shared/Assets/Keep Pages/Dad-Kid-Catapult-Current.png';
import '../Components/Shared/Styles/selected_country.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function SelectedCountry() {
  // const country = [
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:" Albania"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:" Algeria"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:" Afghanistan"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:" Andorra"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:" Angola"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:" Anguilla"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:"Antigua &amp; Barbuda"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:" Argentina"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:" Armenia"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:" Aruba"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:" Australia"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-1/",
  //     name:" Austria"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:" Azerbaijan"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:" Bahamas"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:" Bahrain"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:" Bangladesh"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:"    Barbados"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:"Belarus"
  //   },
  //   {
  //     link:"https://cooperkid.com/western-europe/",
  //     name:" Belgium"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:"    Belize"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"    Benin"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:"Bermuda"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:"Bhutan"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:"Bolivia"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-2/",
  //     name:"Bosnia and Herzegovnia"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"Botswana"
  //   },
  //   {
  //     link:"https://cooperkid.com/major-eu-asia-latin/",
  //     name:" Brazil"
  //   },
  //   {
  //     link:"https://cooperkid.com/latin-america/",
  //     name:" British Virgin Islands"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:"Brunei Darussalam"
  //   },
  //   {
  //     link:"https://cooperkid.com/europe-1/",
  //     name:" Bulgaria"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:" Burkina Faso"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"Burundi"
  //   },
  //   {
  //     link:"https://cooperkid.com/asia-australia/",
  //     name:"Cambodia"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"Cameroon"
  //   },
  //   {
  //     link:"https://cooperkid.com/canada/",
  //     name:"Canada"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"Cape Verde"
  //   },
  //   {
  //     link:"https://cooperkid.com/middle-east-and-africa/",
  //     name:"Cape Verde"
  //   },
  // ]
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='main-wrapper selected-country'>
        <div className='country-dropdown'>
          <h1>SHIPPING OUTSIDE THE UNITED STATES?</h1>
          <p>
            Select your country from the list below or{' '}
            <a href='https://cooperkid.com/get-it/' target='_blank'>
              return to the US order forms.
            </a>
          </p>
          <form name='orderbasedonlocation' className='dropdown-form'>
            <select
              name='country'
              className='country-select-dropdown'
              onChange={(e) => {
                window.document.location.href = e.target.value;
              }}
            >
              <option selected='selected'>Where are you shipping to?</option>
              <option value='https://cooperkid.com/europe-2/'>Albania</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Algeria
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Afghanistan
              </option>
              <option value='https://cooperkid.com/europe-2/'>Andorra</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Angola
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Anguilla
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Antigua &amp; Barbuda
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Argentina
              </option>
              <option value='https://cooperkid.com/europe-2/'>Armenia</option>
              <option value='https://cooperkid.com/latin-america/'>
                Aruba
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Australia
              </option>
              <option value='https://cooperkid.com/europe-1/'>Austria</option>
              <option value='https://cooperkid.com/europe-2/'>
                Azerbaijan
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Bahamas
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Bahrain
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Bangladesh
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Barbados
              </option>
              <option value='https://cooperkid.com/europe-2/'>Belarus</option>
              <option value='https://cooperkid.com/western-europe/'>
                Belgium
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Belize
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Benin
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Bermuda
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Bhutan
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Bolivia
              </option>
              <option value='https://cooperkid.com/europe-2/'>
                Bosnia and Herzegovnia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Botswana
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Brazil
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                British Virgin Islands
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Brunei Darussalam
              </option>
              <option value='https://cooperkid.com/europe-1/'>Bulgaria</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Burkina Faso
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Burundi
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Cambodia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Cameroon
              </option>
              <option value='https://cooperkid.com/canada/'>Canada</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Cape Verde
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Cayman Islands
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Central African Republic
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Chad
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Chile
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                China
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Colombia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Comoros
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Congo
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Costa Rica
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Cote D’Ivoire
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Croatia
              </option>
              <option value='https://cooperkid.com/latin-america/'>Cuba</option>
              <option value='https://cooperkid.com/europe-1/'>Cyprus</option>
              <option value='https://cooperkid.com/europe-1/'>
                Czech Republic
              </option>
              <option value='https://cooperkid.com/western-europe/'>
                Denmark
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Djibouti
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Dominica
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Dominican Republic
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Egypt
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Equador
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Equatorial Guinea
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Eritrea
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Ethiopia
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Estonia
              </option>
              <option value='https://cooperkid.com/europe-2/'>
                Faroe Islands
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Fiji
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Finland
              </option>
              <option value='https://cooperkid.com/western-europe/'>
                France
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                French Guiana
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                French Polynesia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Gabon
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Gambia
              </option>
              <option value='https://cooperkid.com/europe-2/'>Georgia</option>
              <option value='https://cooperkid.com/western-europe/'>
                Germany
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Ghana
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Gibraltar
              </option>
              <option value='https://cooperkid.com/europe-1/'>Greece</option>
              <option value='https://cooperkid.com/europe-2/'>Greenland</option>
              <option value='https://cooperkid.com/latin-america/'>
                Guadeloupe
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Guatamala
              </option>
              <option value='https://cooperkid.com/europe-2/'>Guernsey</option>
              <option value='https://cooperkid.com/latin-america/'>
                Grenada
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Guinea
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Guinea-Bissau
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Guyana
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Haiti
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Honduras
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Hong Kong
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Hungary
              </option>
              <option value='https://cooperkid.com/europe-2/'>Iceland</option>
              <option value='https://cooperkid.com/asia-australia/'>
                India
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Indonesia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Iraq
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Ireland
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Israel
              </option>
              <option value='https://cooperkid.com/western-europe/'>
                Italy
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Jamaica
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Japan
              </option>
              <option value='https://cooperkid.com/europe-2/'>Jersey</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Jordan
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Kenya
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Kiribati
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Kuwait
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Kyrgyzstan
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Laos
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Latvia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Lebanon
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Lesotho
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Liberia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Libya
              </option>
              <option value='https://cooperkid.com/europe-2/'>
                Liechtenstein
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Lithuania
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Luxembourg
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Macao
              </option>
              <option value='https://cooperkid.com/europe-2/'>Macedonia</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Madagascar
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Malawi
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Malaysia
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Maldives
              </option>
              <option value='https://cooperkid.com/europe-2/'>Maldova</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Mali
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Malta
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Martinique
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Mauritania
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Mauritius
              </option>
              <option value='https://cooperkid.com/mexico/'>Mexico</option>
              <option value='https://cooperkid.com/western-europe/'>
                Monaco
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Mongolia
              </option>
              <option value='https://cooperkid.com/europe-2/'>
                Montenegro
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Montserrat
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Morocco
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Mozambique
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Myanmar
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Nambia
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Nepal
              </option>
              <option value='https://cooperkid.com/western-europe/'>
                Netherlands
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Netherlands Antilles
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                New Caledonia
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                New Zealand
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Nicaragua
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Niger
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Nigeria
              </option>
              <option value='https://cooperkid.com/europe-2/'>Norway</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Oman
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Pakistan
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Panama
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Papua New Guinea
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Paraguay
              </option>
              <option value='https://cooperkid.com/latin-america/'>Peru</option>
              <option value='https://cooperkid.com/asia-australia/'>
                Philippines
              </option>
              <option value='https://cooperkid.com/europe-1/'>Poland</option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Portugal
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Qatar
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Reunion
              </option>
              <option value='https://cooperkid.com/europe-1/'>Romania</option>
              <option value='https://cooperkid.com/europe-2/'>Russia</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Rwanda
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Barthelemy
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Kitts and Nevis
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Luca
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Maarten
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Martin
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Saint Vincent and the Grenadines
              </option>
              <option value='https://cooperkid.com/europe-2/'>
                San Marino
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Sao Tome and Principe
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Saudi Arabia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Senegal
              </option>
              <option value='https://cooperkid.com/europe-2/'>Serbia</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Seychelles
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Singapore
              </option>
              <option value='https://cooperkid.com/europe-1/'>Slovakia</option>
              <option value='https://cooperkid.com/europe-1/'>Slovenia</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                South Africa
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                South Korea
              </option>
              <option value='https://cooperkid.com/western-europe/'>
                Spain
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Sri Lanka
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Suriname
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Swaziland
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Sweden
              </option>
              <option value='https://cooperkid.com/major-eu-asia-latin/'>
                Switzerland
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Tanzania
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Togo
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Trinidad and Tobago
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Tunisia
              </option>
              <option value='https://cooperkid.com/europe-2/'>Turkey</option>
              <option value='https://cooperkid.com/asia-australia/'>
                Turkmenistan
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Turks and Caicos
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Uganda
              </option>
              <option value='https://cooperkid.com/europe-2/'>Ukraine</option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                United Arab Emirates
              </option>
              <option value='https://cooperkid.com/uk/'>United Kingdom</option>
              <option value='https://cooperkid.com/get-it/'>
                United States
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Uruguay
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Uzbekistan
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Vanuatu
              </option>
              <option value='https://cooperkid.com/latin-america/'>
                Venezuela
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Vietnam
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Wallis and Futuna
              </option>
              <option value='https://cooperkid.com/asia-australia/'>
                Western Samoa
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Yemen
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Zambia
              </option>
              <option value='https://cooperkid.com/middle-east-and-africa/'>
                Zimbabwe
              </option>
              <option value='https://cooperkid.com/contact-us/'>
                Not in the List
              </option>
            </select>
          </form>
        </div>
        <div className='country-image'>
          <img src={image} alt='Dad Kid CatapultCurrent' />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SelectedCountry;
