import { useEffect, useState } from 'react';
import { BiPlus } from 'react-icons/bi';

export const ProductDetail = () => {
  const [selectedPlan, setSelectedPlan] = useState({
    plan: 'yearly',
    medallion: false,
  });
  const [navigate, setNavigate] = useState();

  const handlePlanClick = (plan) => {
    setSelectedPlan({
      ...selectedPlan,
      plan,
      medallion: false,
    });
  };

  const handleAddMedallionClick = () => {
    setSelectedPlan({
      ...selectedPlan,
      medallion: !selectedPlan.medallion,
    });
  };

  useEffect(() => {
    const formBaseUrl = 'https://aw135.infusionsoft.com/app/orderForms/';

    const formName = `${new Date().getFullYear()}-US-${
      selectedPlan.plan === 'yearly' ? 'Payg' : 'Super-Saver'
    }-${
      selectedPlan.plan === 'yearly' ? 'Yearly' : 'Prepay-Yearly'
    }-Subscription${selectedPlan.medallion ? '-wMedallion' : ''}`;
    const form = formBaseUrl + formName;
    setNavigate(form);
  }, [selectedPlan]);

  return (
    <div className='as_product_details_wrap'>
      <h2 className='as_h2'>Start Creating Family Time today!</h2>
      <div className='as_product_plan'>
        <div className='as_left_border'></div>
        <h3 className='as_pick_a_plan'>Pick a plan</h3>
        <p className='as_p'>
          New kit every 3 months. Free US Shipping. First kit ships within 1-3 
          business days
        </p>
        <div className='as_product_plan_item_wrap'>
          <div
            className={`${
              selectedPlan.plan === 'yearly' ? 'as_selected' : null
            } as_product_plan_item as_item1`}
            onClick={() => handlePlanClick('yearly')}
          >
            <h4 className='as_h4'>
              Pay-as-you-go
              <br />
              Yearly Subscription
            </h4>
            <small>$64.99/kit</small>
          </div>
          <div className='as_extra' />
          <div
            className={`${
              selectedPlan.plan === 'prepay' ? 'as_selected' : ''
            } as_product_plan_item as_item2`}
            onClick={() => handlePlanClick('prepay')}
          >
            <h4 className='as_h4'>
              Prepay Yearly <br />
              Subscription
            </h4>
            <small>$59.99/kit</small>
          </div>
        </div>
        <h3 className='as_pick_a_plan'>Optional Extra Medallions - $4/ea</h3>
        <p className='as_p'>
          Kids love ‘em! Each kit comes with one, get extras so every sibling
          gets theirs.
        </p>
      </div>
      <button
        className={`as_button ${selectedPlan.medallion ? 'active' : ''}`}
        // className='as_button'
        type='button'
        onClick={handleAddMedallionClick}
      >
        <BiPlus /> Add Extra Medallions
      </button>
      <a href={navigate} className='as_button as_add_to_cart'>
        Add to cart
      </a>
    </div>
  );
};
