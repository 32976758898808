import React from 'react';
import thankyou from './images/thankyou.png';
import './thankyou.css';
import Navbar from '../../Components/Shared/Navbar/Navbar';
import Footer from '../../Components/Shared/Footer';

const ThankYou = () => {
  return (
    <>
      <Navbar />
      <diV className='thankyou'>
        <img src={thankyou} alt='logo' />
        <h2>Your Order is Complete.</h2>
        <p>
          Please see the sales rep to choose your Cooper Kits and collect your
          items.
        </p>
        <span>Thank you!</span>
      </diV>
      <Footer />
    </>
  );
};
export default ThankYou;
