import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import '../Shared/Styles/form.css';
import BoxType from './BoxType';
import Email from './Email';
import GiftBoxes from './GiftBoxes';
import Medallion from './Medallion';
import NumberOfChildren from './NumberOfChildren';
import StandardBoxes from './StandardBoxes';
// import Upsell from "./Upsell";
import Logo from '../Shared/Assets/Logo.svg';
import Step1 from '../Shared/Assets/Form/1.svg';
import Step2 from '../Shared/Assets/Form/2.svg';
import Step3 from '../Shared/Assets/Form/3.svg';
import Step4 from '../Shared/Assets/Form/4.svg';
import Step5 from '../Shared/Assets/Form/5.svg';
import Step6 from '../Shared/Assets/Form/6.svg';
import Step7 from '../Shared/Assets/Form/7.svg';
import St1 from '../Shared/Assets/Form/st1.svg';
import Stt1 from '../Shared/Assets/Form/stt1.svg';
import St2 from '../Shared/Assets/Form/st2.svg';
import St3 from '../Shared/Assets/Form/st3.svg';
import St4 from '../Shared/Assets/Form/st4.svg';
import { Link } from 'react-router-dom';
import Fullname from './Fullname';

import CookieConsent from 'react-cookie-consent';
// import Date from "./BirthdayForm";

import { OfferBanner } from '../../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Form({ form, setForm, signupForm, sub }) {
  useEffect(() => {
    if (window.location.pathname === '/get-it') {
      setPage(5);
      setForm({
        numberOfChildren: 2,
        gifting: false,
        multipleChildren: true,
        agreed: true,
        houseHold: true,
      });
    } else if (window.location.pathname === '/gift-it') {
      setPage(5);
      setForm({
        numberOfChildren: 2,
        gifting: true,
        multipleChildren: true,
        agreed: true,
        houseHold: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  var src = '';
  // console.info(signupForm);

  const slideBar = (page) => {
    switch (page) {
      case 1:
        return (src = Step1);
      case 2:
        return (src = Step2);
      case 3:
        return (src = Step3);
      case 4:
        return (src = Step4);
      case 5:
        return (src = Step5);
      case 6:
        return (src = Step6);
      case 7:
        return (src = Step7);
      default:
    }
  };
  const slideBar2 = (page) => {
    switch (page) {
      case 1:
        return (src = Stt1);
      case 2:
        return (src = St1);
      case 3:
        return (src = St2);
      case 4:
        return (src = St3);
      case 5:
        return (src = St4);

      // case 7:
      //   return (src = Step7);
      default:
    }
  };
  const pageDisplay = (page) => {
    switch (page) {
      case 1:
        return (
          <NumberOfChildren
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            step1={sub.firststep}
          />
        );
      case 2:
        return (
          <BoxType
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            step2={sub.secondstep}
          />
        );
      case 3:
        return (
          <Fullname
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            step3={sub.thirdstep}
          />
        );
      case 4:
        return (
          <Email
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            signupForm={signupForm}
            step4={sub.fourthstep}
          />
        );
      case 5:
        if (form.houseHold === true && form.gifting === false) {
          return (
            <StandardBoxes
              form={form}
              setForm={setForm}
              page={page}
              setPage={setPage}
              sub={sub}
            />
          );
        } else if (form.houseHold === false && form.gifting === true) {
          return (
            <GiftBoxes
              form={form}
              setForm={setForm}
              page={page}
              setPage={setPage}
              sub={sub}
            />
          );
        } else {
          return (
            <BoxType
              form={form}
              setForm={setForm}
              page={page}
              setPage={setPage}
            />
          );
        }

      case 6:
        // if (form.numberOfChildren === 1) {
        //   return (
        //     <Upsell
        //       form={form}
        //       setForm={setForm}
        //       page={page}
        //       setPage={setPage}
        //       upsell={sub}
        //     />
        //   );
        // } else
        if (form.numberOfChildren > 1) {
          return (
            <Medallion
              form={form}
              setForm={setForm}
              page={page}
              setPage={setPage}
              medallion={sub}
            />
          );
        } else {
          return (
            <NumberOfChildren
              form={form}
              setForm={setForm}
              page={page}
              setPage={setPage}
            />
          );
        }
      case 7:
        return <p>blank</p>;

      default:
    }
  };
  // console.log(form);
  return (
    <>
      {/* <div style={{ backgroundColor: "yellow" }}>
        <h2 style={{ textAlign: "center", padding: "20px", fontSize: "20px" }}>
          Get 25% off your first box using the code 'DAD25OFF' at checkout!
        </h2>
      </div> */}
      <Helmet>
        <title>Cooper and Kid: Get Your Cooper Kit</title>
        <meta name='title' content='Cooper and Kid: Get Your Cooper Kit' />
        <meta
          name='description'
          content='Send as a gift or get it for your family. Customize your Cooper Kit here! '
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='privacyPolicy'
        containerClasses='cookie'
        debug={false}
        style={{
          background: '#5c2945',
          fontSize: '12px',
          padding: '10px 0px',
          height: 'fit-content',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '13px',
          backgroundColor: '#90dae5',
          padding: '10px 50px',
          borderRadius: '5px',
        }}
        expires={false}
      >
        <p className='cookie-paragraph'>
          {' '}
          We use cookies to provide website functionality, to analyze traffic on
          our Cooper & Kid Site, personalize content, serve targeted
          advertisements and to enable social media functionality. Our Privacy
          Policy provides more information and explains how to update your
          privacy policy. View our{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable cookie-link'
          >
            privacy-policy
          </Link>
        </p>
      </CookieConsent>
      {/* <p className="offere-banner">Get a Free Extra Cooper Kit! Valued at $150 with select subscriptions.</p>   */}
      <div className='form-header'>
        <Link
          to={
            window.location.pathname.includes('homeschooling')
              ? '/homeschooling'
              : '/'
          }
          className='form-navbar-logo hoverable'
        >
          <img src={Logo} alt='logo' className='form-logo' />
        </Link>
        {form.numberOfChildren === 1 ? (
          <img src={slideBar2(page)} alt='img' className='slidebar' />
        ) : (
          <img src={slideBar(page)} alt='img' className='slidebar' />
        )}
        <div className='internationall-p'>
          <span>Shipping Internationally? </span>
          <Link to='/select-your-country'>Click Here.</Link>
        </div>
      </div>
      <div className='form-body '>
        {pageDisplay(page)}{' '}
        {window.location.pathname.includes('homeschooling') ? (
          <Link to='/' className='homeschoolerLink'>
            Not a Homeschooler?
          </Link>
        ) : null}
      </div>
      <div className='form-footer'>
        <p>
          Questions?{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/contact-us'
                : '/contact-us'
            }
          >
            Let us know how we can help
          </Link>
        </p>
        <p>© {new Date().getFullYear()} Cooper & Kid, LLC</p>
      </div>
    </>
  );
}

export default Form;
