import { promisesData } from '../components/data/testimonials';

export const Promises = () => {
  return (
    <div className='as_promises_wrap'>
      <div className='as_promises_heading'>
        <p className='as_p'>Every Cooper Kit delivers on</p>
        <h3 className='as_sub_heading'>8 Promises</h3>
      </div>
      <div className='as_container as_promise_item_wrap'>
        {promisesData?.map((item, index) => (
          <div key={index} className='as_promise_item'>
            <div className='as_icon_wrap'>
              <img className='as_image' src={item.image} alt='Promise' />
            </div>
            <p className='as_p'>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
