// Logos

import logo_image1 from '../../assets/logo/logo_1.png';
import logo_image2 from '../../assets/logo/logo_2.png';
import logo_image3 from '../../assets/logo/logo_3.png';
import logo_image4 from '../../assets/logo/logo_4.png';
import logo_image5 from '../../assets/logo/logo_5.png';
import logo_image6 from '../../assets/logo/logo_6.png';

//Promisses

import promise_image1 from '../../assets/promises/promise_1.png';
import promise_image2 from '../../assets/promises/promise_7.png';
import promise_image3 from '../../assets/promises/promise_8.png';
import promise_image4 from '../../assets/promises/promise_2.png';
import promise_image5 from '../../assets/promises/promise_3.png';
import promise_image6 from '../../assets/promises/promise_4.png';
import promise_image7 from '../../assets/promises/promise_5.png';
import promise_image8 from '../../assets/promises/promise_6.png';

// Brand Logos
export const logos = [
  { image: logo_image1 },
  { image: logo_image2 },
  { image: logo_image3 },
  { image: logo_image4 },
  { image: logo_image5 },
  { image: logo_image6 },
];

// Testimonial

export const testimonials = [
  {
    id: 1,
    name: 'Joby Weber',
    message: 'Absolutely amazing best box ever!!!',
  },
  {
    id: 2,
    name: 'Meghan Anderson',
    message:
      'Good materials and variety of activities. Enjoyed spending the time together as a family.',
  },
  {
    id: 3,
    name: 'Michelle Lubonski',
    message: 'I thought the quality of the kits was excellent.',
  },
];

//Promises

export const promisesData = [
  { title: 'A New Adventure', image: promise_image1 },
  { title: 'Themed Family Projects', image: promise_image2 },
  { title: 'A Companion Bedtime Storybook', image: promise_image3 },
  { title: 'Beautiful Activity Guide', image: promise_image4 },
  { title: 'Integrated Social Emotional Skills', image: promise_image5 },
  { title: 'Themed Dinner Recipes', image: promise_image6 },
  { title: 'Digital Downloads', image: promise_image7 },
  { title: 'Wonderful Collectible Medallion', image: promise_image8 },
];
