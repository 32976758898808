import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Image from '../Components/Shared/Assets/Keep Pages/tryToProcess.jpg';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function TryToProcess() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='annual-order'>
        <div>
          <h1>Thanks! We will try to process your card again.</h1>
          <img src={Image} alt='tryimage' />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TryToProcess;
