import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper';
import { mobileWhatYouGetData } from './what-you-get-data';
import './whatYouGet.css';

export const WhatYouGetSlider = () => {
  return (
    <Swiper
      navigation={true}
      freeMode={true}
      // autoHeight={true}
      loop={true}
      modules={[FreeMode, Navigation]}
      className='as_what_you_get_slider'
    >
      {mobileWhatYouGetData?.map((item, index) => (
        <SwiperSlide key={index}>
          <div className='as_what_title_text_wrap' key={index}>
            <h2 className='as_h2 as_what_subtitle'>{item.subTitle}</h2>
            <p className=' as_what_text'>{item.pg}</p>
            <div className='as_what_you_get_mobile_img'>
              {item.image?.map((itemImage, index) => (
                <div className='as_what_you_get_img_wrap'>
                  <img
                    key={index}
                    className='as_what_you_get_mobile_img_item'
                    src={itemImage}
                    alt={item.subTitle}
                  />
                </div>
              ))}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
