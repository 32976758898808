import React from 'react';
import { dataLayer } from '../../dataLayer';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
function StandardBoxes({ form, setForm, page, setPage, sub }) {
  const checked = (id) => {
    const newCards = form.standardBoxes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setForm({
      ...form,
      standardBoxes: newCards,
    });
  };
  // me filtru se cili box osht checked

  var counter = 0;

  if (form.standardBoxes) {
    // eslint-disable-next-line no-redeclare
    var counter = 0;
    for (var i = 0; i < form.standardBoxes.length; i++) {
      if (form.standardBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }

  let priceItemOne = form.standardBoxes[0].price;
  priceItemOne = priceItemOne.substring(1);
  priceItemOne = parseFloat(priceItemOne);

  let priceItemTwo = form.standardBoxes[1].price;
  priceItemTwo = priceItemTwo.substring(1);
  priceItemTwo = parseFloat(priceItemTwo);

  let priceItemThree = form.standardBoxes[2].price;
  priceItemThree = priceItemThree.substring(1);
  priceItemThree = parseFloat(priceItemThree);
  let itemString = form.standardBoxes[0].id;
  let itemStringConvert = itemString.toString();
  let itemString2 = form.standardBoxes[1].id;
  let itemStringConvert2 = itemString2.toString();
  let itemString3 = form.standardBoxes[2].id;
  let itemStringConvert3 = itemString3.toString();
  const userIdd = uuidv4();
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: form.title,
      item_list_name: form.title,
      items: [
        {
          item_id: itemStringConvert,
          item_name: form.standardBoxes[0].title,

          discount: form.standardBoxes[0].discount,
          index: form.standardBoxes[0].id,

          price: priceItemOne,
          quantity: 1,
        },

        {
          item_id: itemStringConvert2,
          item_name: form.standardBoxes[1].title,

          discount: form.standardBoxes[1].discount,
          index: form.standardBoxes[1].id,

          price: priceItemTwo,
          quantity: 1,
        },
        {
          item_id: itemStringConvert3,
          item_name: form.standardBoxes[2].title,

          discount: form.standardBoxes[2].discount,
          index: form.standardBoxes[2].id,

          price: priceItemThree,
          quantity: 1,
        },
      ],
    },
  });

  var user_data = {
    user_id: userIdd, // Add the unique User ID here

    email_address: form.email,
    address: {
      first_name: form.name,
      last_name: form.lastName,
    },
  };
  window.dataLayer.push({ user_data });

  function handleClickPlansGetit(props) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    var price = props.price.substring(1);
    price = parseFloat(price);
    const userId = uuidv4();
    let itemStrinGet = props.id;
    let itemStringConvertGet = itemStrinGet.toString();
    dataLayer.push({ ecommerce: null });

    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        item_list_id: props.title,
        item_list_name: props.title,
        items: [
          {
            item_id: itemStringConvertGet,
            item_name: props.title,

            discount: props.discount,
            index: props.id,

            price: price,
            quantity: 1,
          },
        ],
      },
    });

    var user_data = {
      user_id: userId, // Add the unique User ID here

      email_address: form.email,
      address: {
        first_name: form.name,
        last_name: form.lastName,
      },
    };
    dataLayer.push({ user_data });
  }

  return form.standardBoxes ? (
    <>
      <div className='internationall-getit'>
        <span>Shipping Internationally? </span>
        <Link to='/select-your-country'>Click Here.</Link>
      </div>
      <div className='standard'>
        <h1 className='box-title'>Select Your Family’s Subscription</h1>
        <h4 className='box-title_subheading-plans'>
          New Cooper Kits Every 3 Months
        </h4>
        <h2 className='subheading-plans'>FREE U.S. SHIPPING FOR ALL!</h2>
        <div className='standard-boxes'>
          {form.standardBoxes.map((e, index) => (
            <div key={e.id}>
              {console.log('badge', e)}
              <div
                className='standard-box hoverable'
                onClick={() => {
                  handleClickPlansGetit(e);
                  checked(e.id);
                  setPage(page + 1);
                }}
                style={
                  e.isChecked
                    ? {
                        backgroundColor: '#E3F6F8',
                        border: '1px solid #90DAE5',
                        boxShadow: '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        WebkitBoxShadow:
                          '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        MozBoxShadow: '0px 0px 5px 65px rgba(244,222,225,0.18)',
                      }
                    : counter < 3
                    ? { backgroundColor: '#f6f7f8' }
                    : { backgroundColor: '#f6f7f8' }
                }
              >
                {e.kit ? (
                  <p
                    className='product_card_top'
                    dangerouslySetInnerHTML={{
                      __html: e.kit,
                    }}
                  ></p>
                ) : (
                  <p style={{ marginBottom: '44px' }}></p>
                )}
                <div className='product_card_images'>
                  <img src={e.image} alt='cardImage' />
                </div>
                <div className=''>
                  <div className='product_card_header'>
                    <p>{e.title}</p>
                    <p
                      className='product_price'
                      dangerouslySetInnerHTML={{
                        __html: e.price,
                      }}
                    ></p>
                    <p
                      className='product_discount_price'
                      dangerouslySetInnerHTML={{
                        __html: e.discount,
                      }}
                    ></p>
                    {e.price === '$41.99' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: e.kit,
                        }}
                      ></div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    className='product_card_points'
                    dangerouslySetInnerHTML={{
                      __html: e.bottomlabel,
                    }}
                  ></div>
                  <div className='offer-discount'>
                    {e.offer ? (
                      <>
                        <p className=''>{e.offer}</p>
                        <i>*Not valid with any other offers or discounts.</i>
                      </>
                    ) : (
                      <p style={{ marginTop: '50px' }}></p>
                    )}
                  </div>
                  <div className='product_card_select_button'>Select Plan</div>
                  <div className='product_card_bottom_text'>
                    {e.cancel_text}
                  </div>
                  <div className='plans-save'>{e.save}</div>
                </div>
              </div>
              <div id='shipping-info-mobile'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: e.bottomlabel,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <h2 className='boxes-h2'>{sub.standardproducts.notice2}</h2>
      </div>
    </>
  ) : null;
}

export default StandardBoxes;
