import React from "react";

function ThirdSection({ section3 }) {
  return (
    <div className="third-section-pk">
      <div className="main-wrapper pk-third-section">
        <div className="third-section-pk-text">
          <h1>{section3.header}</h1>
          <p>{section3.paragraph}</p>
        </div>
        <img src={section3.image.url} alt="img" />
      </div>
    </div>
  );
}

export default ThirdSection;
