import React, { useEffect } from "react";
import Loader from "../Components/Shared/Loader";

const MyAcc = () => {
  useEffect(() => {
    window.location.href = "https://cooperandkid.customerhub.net/";
  });
  return (
    <div>
      <Loader />
    </div>
  );
};

export default MyAcc;
