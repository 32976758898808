import React, { useEffect, useRef, useState } from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import { dataLayer } from '../dataLayer';
import { v4 as uuidv4 } from 'uuid';
// import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Bonus Kit with your first Cooper Kit!',
  link: 'subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function StandardBoxes({ form, setForm, page, setPage, sub }) {
  const [active, setActive] = useState(0);
  // const [loader, setLoader] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const getSwitchBoxType = (boxType) => {
    // console.log(boxType, "++++++++++++");
    var type = '';
    boxType.filter((e) => {
      if (e.isChecked) {
        type = e.type;
      }
    });
    // console.log(type, "-009-09");
    return type;
  };
  const checked = (id) => {
    const newCards = form.standardBoxes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setForm({
      ...form,
      standardBoxes: newCards,
    });
  };
  // me filtru se cili box osht checked
  var counter = 0;
  if (form.standardBoxes) {
    var counter = 0;
    for (var i = 0; i < form.standardBoxes.length; i++) {
      if (form.standardBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }
  var params =
    '?name=' +
    form.name +
    '&isGift=' +
    form.gifting +
    '&numberOfChildren=' +
    form.numberOfChildren +
    '&email=' +
    form.email +
    '&medallions=' +
    form.numberOfChildren +
    '&surprise=' +
    form.surprise +
    '&receive=' +
    form.receive;
  const links = {
    Quarterly: [
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-month' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-2-months' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-3-months' +
        params,
    ],
    'Standard Yearly': [
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-1-month' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-2-months' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-3-months' +
        params,
    ],
    'Prepay Yearly': [
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-Month' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-2-Months' +
        params,
      'https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-3-Months' +
        params,
    ],
  };
  const orderLinks = (type, receive) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'Quarterly':
        localStorage.setItem('SKU', 'quarterly-subscription-family');
        break;
      case 'Standard Yearly':
        localStorage.setItem('SKU', 'annual-billed-quarterly');
        break;
      default:
        break;
    }
    return links[type][receive - 1];
  };
  const updatePage = (e) => {
    checked(e.id);
    // ReactPixel.trackSingle("1835004036766063", "AddToCart", {
    //   value: parseFloat(e.price.substring(1, 7)),
    //   currency: "USD",
    // });
  };
  useEffect(() => {
    if (form.receive === active) {
      // orderLinks(getSwitchBoxType(form.standardBoxes), form.receive);
      window.location.href = orderLinks(
        getSwitchBoxType(form.standardBoxes),
        form.receive
      );
      localStorage.setItem('name', form.name);
      localStorage.setItem('lastName', form.lastName);
      if (form.gifting === true) {
        localStorage.setItem('isGift', true);
      } else {
        localStorage.setItem('isGift', false);
      }
      localStorage.setItem('numberOfChildren', form.numberOfChildren);
      localStorage.setItem('email', form.email);
      if (form.gifting === true) {
        localStorage.setItem(
          'giftBoxType',
          form.giftingBoxes.filter((e) => e.isChecked)[0]['type']
        );
      } else {
        localStorage.setItem(
          'houseHoldType',
          form.standardBoxes.filter((e) => e.isChecked)[0]['type']
        );
      }
      localStorage.setItem('surprise', form.surprise);
    } else if (form.numberOfChildren > 1 && form.receive === active) {
      setPage(page + 1);
    }
    const handleResize = () => changeDeviceSize(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [form.standardBoxes]);
  const ref = useRef(null);
  const handleClick = () => {
    if (deviceSize < 600) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  let priceItemOne = form.standardBoxes[0].price;
  priceItemOne = priceItemOne.substring(1);
  priceItemOne = parseFloat(priceItemOne);

  let priceItemTwo = form.standardBoxes[1].price;
  priceItemTwo = priceItemTwo.substring(1);
  priceItemTwo = parseFloat(priceItemTwo);

  let priceItemThree = form.standardBoxes[2].price;
  priceItemThree = priceItemThree.substring(1);
  priceItemThree = parseFloat(priceItemThree);

  const userIdd = uuidv4();
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: form.title,
      item_list_name: form.title,
      items: [
        {
          item_id: form.standardBoxes[0].id,
          item_name: ' Homeschool Flexi Plan',

          discount: form.standardBoxes[0].discount,
          index: form.standardBoxes[0].id,

          price: 69.99,
          quantity: 1,
        },

        {
          item_id: form.standardBoxes[1].id,
          item_name: 'Homeschool Standard 4-Kit Plan',

          discount: form.standardBoxes[1].discount,
          index: form.standardBoxes[1].id,

          price: 64.99,
          quantity: 1,
        },
        {
          item_id: form.standardBoxes[2].id,
          item_name: 'Homeschool Super Saver Prepay Plan',

          discount: form.standardBoxes[2].discount,
          index: form.standardBoxes[2].id,

          price: 59.99,
          quantity: 1,
        },
      ],
    },
  });

  var user_data = {
    user_id: userIdd, // Add the unique User ID here

    email_address: form.email,
    address: {
      first_name: form.name,
      last_name: form.lastName,
    },
  };
  window.dataLayer.push({ user_data });

  function handleClickPlansHomeschoolingGetit(props) {
    if (form.receive === active) {
      var price = props.price.substring(1);
      price = parseFloat(price);
      const userId = uuidv4();
      let itemString = props.id;
      let itemStringConvert = itemString.toString();

      dataLayer.push({ ecommerce: null });

      dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          item_list_id: props.title,
          item_list_name: props.title,
          items: [
            {
              item_id: itemStringConvert,
              item_name: props.title,

              discount: props.discount,
              index: props.id,

              price: price,
              quantity: 1,
            },
          ],
        },
      });

      var user_data = {
        user_id: userId, // Add the unique User ID here

        email_address: form.email,
        address: {
          first_name: form.name,
          last_name: form.lastName,
        },
      };
      dataLayer.push({ user_data });
    }
  }

  return form.standardBoxes ? (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='standard '>
        <h1 className='box-title'>
          Customizable Options Designed For Homeschooling Families
        </h1>
        <div className='internationall-getit top-minus'>
          <span>Shipping Internationally? </span>
          <Link to='/select-your-country'>Click Here.</Link>
        </div>
        <h2 className='subheading-plans'>FREE U.S. SHIPPING FOR ALL!</h2>

        <div className='delivery-program'>
          <p>Select Delivery Frequency:</p>
          <div>
            <label
              htmlFor='everyMonth'
              onClick={() => {
                setForm({ ...form, receive: 1 });
                setActive(1);
                handleClick();
                // gtag('event', "HomeschoolingGetitEveryMonth", {
                //   event_category: "signupFlow",
                //   event_label: "submit",
                // });
              }}
              className={`${active === 1 ? 'plans-active' : ''}`}
            >
              <input type='radio' name='everyMonth' id='everyMonth'></input>
              Every Month
            </label>

            <label
              htmlFor='every2Month'
              onClick={() => {
                setForm({ ...form, receive: 2 });
                setActive(2);
                handleClick();
                // gtag('event', "HomeschoolingGetit2Month", {
                //   event_category: "signupFlow",
                //   event_label: "submit",
                // });
              }}
              className={`${active === 2 ? 'plans-active' : ''}`}
            >
              <input type='radio' name='everyMonth' id='every2Month'></input>
              Every 2 Month
            </label>

            <label
              htmlFor='every3Month'
              onClick={() => {
                setForm({ ...form, receive: 3 });
                setActive(3);
                handleClick();
                // gtag('event', "HomeschoolingGetit3Month", {
                //   event_category: "signupFlow",
                //   event_label: "submit",
                // });
              }}
              className={`${active === 3 ? 'plans-active' : ''}`}
            >
              <input type='radio' name='everyMonth' id='every3Month'></input>
              Every 3 Month
            </label>
          </div>
        </div>
        <p className='check-id-transition' ref={ref}>
          Next, Pick a Plan:
        </p>
        <div className='standard-boxes  '>
          {form.standardBoxes.map((e, index) => (
            <div key={e.id}>
              <div
                className='standard-box hoverable'
                onClick={() => {
                  updatePage(e);
                  handleClickPlansHomeschoolingGetit(e);
                }}
                style={
                  e.isChecked
                    ? {
                        backgroundColor: '#E3F6F8',
                        border: '1px solid #90DAE5',
                        boxShadow: '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        WebkitBoxShadow:
                          '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        MozBoxShadow: '0px 0px 5px 65px rgba(244,222,225,0.18)',
                      }
                    : counter < 3
                    ? { backgroundColor: '#F6F7F8' }
                    : { backgroundColor: '#F6F7F8' }
                }
              >
                <img className='landing-value' src={e.badgeHomeschooling} />
                <div className='product-card-image'>
                  <img
                    src={e.image}
                    alt='img'
                    className={index === 0 ? 'image-1' : ''}
                  />
                </div>
                <div className='product-title'>
                  <div className='product-arrow'>
                    <h1>{e.titlehomeschooling}</h1>
                    <BsArrowRightShort />
                  </div>
                  <div className='plansoffer-display plansoffer-homeschool'>
                    <div className='getit-position'>
                      <h2>{e.pricehomeshcool}</h2>
                      {/* <div className="getit-line-home"></div> */}
                    </div>
                    {/* <h3>{e.discount}</h3> */}

                    {e.pricehomeshcool === '59.99 per kit / $239.96 total' ? (
                      ''
                    ) : (
                      <p className='getit-holiday'>per Kit</p>
                    )}
                  </div>
                  <div className='offer-discount'>
                    {/* <p>{e.offer}</p>    */}
                    <p className='free-shipping'>Free Shipping!</p>
                    {e.bonus ? (
                      <>
                        <p>{e.bonus}</p>
                        <i>*Not valid with any other offers or discounts.</i>
                      </>
                    ) : (
                      <p className='margin-bottom-freeShipping'></p>
                    )}
                  </div>
                  <div className='choose-plan-box'>Select Plan</div>

                  <div className='plans-save'>{e.save}</div>
                  <div
                    className='plans-hover-display'
                    dangerouslySetInnerHTML={{
                      __html: e.bottomlabelhomeschool,
                    }}
                  ></div>
                </div>
              </div>
              {/* <p className="plans-hover-details">Subscription Details <AiOutlineInfoCircle></AiOutlineInfoCircle></p> */}
              {/* <div className="plans-hover-display"
              dangerouslySetInnerHTML={{
                __html: e.bottomlabelhomeschool,
              }}
            ></div> */}
              <div id='shipping-info-mobile'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: e.bottomlabelhomeschool,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        {/* <p className="boxes-p">{sub.standardproducts.notice}</p>
      <p className="boxes-discount">Discounts are as compared to a regular full priced Cooper Kit.</p>
  <div class="international-link homeschooling-link"><span>Shipping Internationally? </span><a href="/select-your-country">Click Here.</a></div> */}
        <h2 className='boxes-h2-homeschooling'>
          {sub.standardproducts.notice2}
        </h2>
      </div>
      <div className='homescholling-getit-p'>
        <Footer />
      </div>
    </>
  ) : null;
}
export default StandardBoxes;
