import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/friendsAndFamily.css';
import '../Components/Shared/Styles/order.css';
import { Link } from 'react-router-dom';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function FriendsAndFamily(props) {
  // console.log("aaaa",props.friendsAndFamily.acf.firsttitle)
  return (
    <div className=' white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='main-wrapper friends_main'>
        <img src={props.friendsAndFamily.acf.familyBanner.url} alt='friends' />
        <div className='friends_texts'>
          <div className='friends_text'>
            <h1>{props.friendsAndFamily.acf.firsttitle}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: props.friendsAndFamily.acf.firstDescription,
              }}
            ></div>
          </div>
          <div className='friends_text'>
            <h1>{props.friendsAndFamily.acf.secondTitle}</h1>
            {/* <p>
            {props.friendsAndFamily.acf.secondDescription}
            </p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: props.friendsAndFamily.acf.secondDescription,
              }}
            ></div>
          </div>
          <div className='friends_text'>
            <h1> {props.friendsAndFamily.acf.thirdTitle}</h1>

            {/* <p>
            {props.friendsAndFamily.acf.thirdDescription}
            </p> */}
            <div
              dangerouslySetInnerHTML={{
                __html: props.friendsAndFamily.acf.thirdDescription,
              }}
            ></div>
            <Link
              to={
                window.location.pathname.includes('homeschooling')
                  ? '/homeschooling/subscribe'
                  : '/subscribe'
              }
            >
              GET THE KIT
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FriendsAndFamily;
