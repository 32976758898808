import React, { useEffect } from 'react';

function FooterForm({ email, page, setPage }) {
  useEffect(() => {
    // document.getElementById("email-field").style.display = "none";
  }, [page]);
  // document.getElementById("inf_field_Email").value = email;
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  return (
    <>
      <form
        acceptCharset='UTF-8'
        action='https://aw135.infusionsoft.com/app/form/process/1bab757cbe3a69011e9b1b40e0d53085'
        className='infusion-form '
        id='inf_form_1bab757cbe3a69011e9b1b40e0d53085'
        method='POST'
      >
        <input
          name='inf_form_xid'
          type='hidden'
          defaultValue='1bab757cbe3a69011e9b1b40e0d53085'
        />
        <input
          name='inf_form_name'
          type='hidden'
          defaultValue='Newsletter Sign Up Form Bottom'
        />
        <input
          name='infusionsoft_version'
          type='hidden'
          defaultValue='1.70.0.458466'
        />
        <div>
          <div
            className='authoring-image image-snippet logo-snippet'
            contentid='logo'
          >
            <div
              className='authoring-image image-snippet logo-snippet'
              contentid='logo'
            >
              <div className='imageSnippet-alignDiv'>
                <img
                  alt='Company Logo'
                  border={0}
                  className='simage float_center'
                  src='https://d1yoaun8syyxxt.cloudfront.net/aw135-6d4caabf-08e1-448c-8815-4184bd1a0254-v2'
                  title='Company Logo'
                  width={248}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <p>
            Now, tailor the information you get with your first name and bit
            about you.
          </p>
        </div>
        <div className='infusion-field first-name-footer'>
          <label htmlFor='inf_field_FirstName'>First Name *</label>
          <input
            id='inf_field_FirstName'
            name='inf_field_FirstName'
            placeholder='First Name *'
            type='text'
          />
        </div>

        <input
          id='inf_field_Email'
          name='inf_field_Email'
          placeholder='Email *'
          type='email'
          value={email}
        />

        <div className='infusion-field'>
          <label htmlFor='inf_option_Iam'>I am *</label>
          <div className='infusion-radio'>
            <div className='options-container'>
              <span className='infusion-option'>
                <input
                  id='inf_option_Iam_6895'
                  name='inf_option_Iam'
                  type='radio'
                  defaultValue={6895}
                />
                <label htmlFor='inf_option_Iam_6895'>A Dad</label>
              </span>
              <span className='infusion-option infusion-option-footer'>
                <input
                  id='inf_option_Iam_6897'
                  name='inf_option_Iam'
                  type='radio'
                  defaultValue={6897}
                />
                <label htmlFor='inf_option_Iam_6897'>A Mom</label>
              </span>
              <span className='infusion-option'>
                <input
                  id='inf_option_Iam_6899'
                  name='inf_option_Iam'
                  type='radio'
                  defaultValue={6899}
                />
                <label htmlFor='inf_option_Iam_6899'>A Grandparent</label>
              </span>
              <span className='infusion-option'>
                <input
                  id='inf_option_Iam_6901'
                  name='inf_option_Iam'
                  type='radio'
                  defaultValue={6901}
                />
                <label htmlFor='inf_option_Iam_6901'>Other</label>
              </span>
            </div>
          </div>
        </div>

        <div className='infusion-field'>
          <span className='infusion-checkbox'>
            <input
              id='inf_option_IagreetothePrivacyPolicy'
              is-required='true'
              name='inf_option_IagreetothePrivacyPolicy'
              type='checkbox'
              defaultValue={6903}
            />
            <label
              className='infusion-field-label-container infusion-label-checkbox'
              htmlFor='inf_option_IagreetothePrivacyPolicy'
            >
              I agree to the Privacy Policy *
            </label>
          </span>
        </div>
        <div>
          <div>&nbsp;</div>
        </div>
        <div className='infusion-submit'>
          <button
            className='infusion-recaptcha'
            id='recaptcha_1bab757cbe3a69011e9b1b40e0d53085'
            type='submit'
            formTarget='footerEmail'
            onClick={() => {
              timeout(2000).then((e) => {
                setPage(1);
              });
            }}
          >
            SUBMIT
          </button>
        </div>
      </form>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" src="https://aw135.infusionsoft.app/app/webTracking/getTrackingCode"></script>
                        <script type="text/javascript" src="https://aw135.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.458466-hf-202204141721"></script>
                        <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async" defer="defer"></script>
                        <script type="text/javascript" src="https://aw135.infusionsoft.com/app/timezone/timezoneInputJs?xid=1bab757cbe3a69011e9b1b40e0d53085"></script>
                        <script type="text/javascript" src="https://aw135.infusionsoft.com/js/jquery/jquery-3.3.1.js"></script>
                        <script type="text/javascript" src="https://aw135.infusionsoft.app/app/webform/overwriteRefererJs"></script>`,
        }}
      />
    </>
  );
}

export default FooterForm;
