import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Phone from '../Components/Shared/Assets/contact/phone.svg';
import Email from '../Components/Shared/Assets/contact/email.svg';

import '../Components/Shared/Styles/contact.css';
import Loader from '../Components/Shared/Loader';

import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Contact({ form }) {
  return form ? (
    <>
      <Helmet>
        <title> Cooper and Kid: Contact Us</title>
        <meta name='title' content=' Cooper and Kid: Contact Us' />
        <meta
          name='description'
          content='Cooper and Kid: Have a question for our team? Drop us a note.'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='contact-form'>
        <div className='main-wrapper contact-form-main'>
          <div className='contant-info'>
            <h1>
              <span>Let’s get in touch.</span> <br></br>Contact Cooper & Kid
            </h1>
            <div className='contact-label'>
              <div className='label-icon'>
                <img src={Phone} alt='phone-icon' />
              </div>
              <p>952.388.0478</p>
            </div>
            <div className='contact-label'>
              <div className='label-icon'>
                <img src={Email} alt='email-icon' />
              </div>
              <p>customerservice@cooperandkid.com</p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: form.acf.contact_form,
            }}
          />
        </div>
      </div>
      <div className='wbg'></div>
      {/* <iframe
        name="contactForm"
        id="i_frame"
        style={{ display: "none", width: "0", height: "0" }}
      ></iframe> */}
      <Footer />
    </>
  ) : (
    <Loader />
  );
}

export default Contact;
