export const table = [
  { title: 'Number of projects per kit', cooper: 5, others: '1-2' },
  { title: 'Number of children that can use 1 kit', cooper: '3-4', others: 1 },
  { title: 'Made for the whole family', cooper: 'Yes', others: 'No' },
  {
    title:
      'Storylined adventure theme with new cartoon character with each kit',
    cooper: 'Yes',
    others: 'No',
  },
  {
    title:
      'Multi-discplinary in each box (i.e, science, technology, reading, engineering, art, cooking)',
    cooper: 'Yes',
    others: 'No',
  },
  {
    title: 'High quality materials and products',
    cooper: 'Yes',
    others: 'Varies',
  },
  {
    title: 'Character and social emotional skills',
    cooper: 'Yes',
    others: 'No',
  },
  { title: 'Box Engineering', cooper: 'Yes', others: 'No' },
  {
    title: 'Companion digital resources and downloads',
    cooper: 'Yes',
    others: 'No',
  },
  {
    title: 'Theme-aligned and kid-friendly recipes',
    cooper: 'Yes',
    others: 'No',
  },
  { title: 'Collectible Medallion', cooper: 'Yes', others: 'No' },
];
