import React from 'react';
import '../Components/Shared/Styles/quietOnTheSet.css';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import image1 from '../Components/Shared/Assets/Keep Pages/image1.png';
import image2 from '../Components/Shared/Assets/Keep Pages/image 2.png';
import glass from '../Components/Shared/Assets/Keep Pages/glass.png';
import monkeys from '../Components/Shared/Assets/Keep Pages/monkeys.png';
import flowerMan from '../Components/Shared/Assets/Keep Pages/flowerMan.png';
import cards from '../Components/Shared/Assets/Keep Pages/cards.png';
import house from '../Components/Shared/Assets/Keep Pages/house.png';
import monkey1 from '../Components/Shared/Assets/Keep Pages/monkey1.png';
import monkey2 from '../Components/Shared/Assets/Keep Pages/monkey2.png';
import monkey3 from '../Components/Shared/Assets/Keep Pages/monkey3.png';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function QuietOnTheSet() {
  return (
    <div className='white-background'>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='quite-main-wrapper'>
        <div className='quite-first-section'>
          <div>
            <h1>
              QUIET ON THE <br></br> <span>SET Sample Kit</span>
            </h1>
          </div>
          <img src={image2} className='quite-firstsection-image2' alt='img' />
          <img src={image1} className='quite-firstsection-image1' alt='img' />
        </div>
        <div className='quiet-second-section'>
          <div className='quiet-second-section-card'>
            <img src={glass} alt='img' />
            <p>
              Activity 4:​Learn how to do your own stunts, including your own
              pie-in-the-face gag!
            </p>
          </div>{' '}
          <div className='quiet-second-section-card1'>
            <img src={monkeys} alt='img' />
            <p>
              Themed Cookbook:Every Cooper Kit includes a themed cookbook. In
              this kit you’ll make all black and white food! What this food
              lacks in color, in makes up in flavor! Yum!
            </p>
          </div>{' '}
        </div>
        <div className='quiet-third-section'>
          <p>
            Bedtime Storybook:Each Cooper Kit ​contains a theme-aligned book.
            Quiet on the Set includes the beautiful wordless picture book, The
            Flower Man, which contains over 40 concurrent stories!
            <br></br>
            <br></br>
            Activity 2:Every Cooper Kit box transforms into something cool. This
            one turns into a scene clapper to start and end your puppet shows
            with style!
          </p>
          <img src={flowerMan} alt='img' />
        </div>
        <div className='quite-first-section'>
          <div>
            <h1>
              so, What’s in a <br></br> <span>Cooper Kit?</span>
            </h1>
            <br></br>
            <br></br>
            <p>
              Quiet on the Set is one of the latest Cooper Kits, designed to
              teach kids about confidence, the silent film era, and encourage
              fatherhood engagement along the way. So what’s in the kit? Quite a
              lot!:
              <br></br>
              <br></br> Activity 1:Each Cooper Kit features at least one
              building project. In this one you build your own precursor to the
              film projector, the magic lantern! This time, remote controlled.
            </p>
          </div>
          <img src={cards} className='quite-fourth-image' alt='img' />
        </div>
        <div className='quiet-fifth-section'>
          <img src={house} alt='img' />
          <p>
            Activity 3:E​xtreme charades! Take the most common acting game to
            the next level with the Shushies Card Game and a blackboard!
            Discover your character, action, setting, and act it all out.
          </p>
        </div>
        <div className='quiet-fifth-section quiet-fifth-section-reverse'>
          <p>
            Activity 5:Every Cooper Kit ​has an outlet for creative expression.
            Using the color-me MacGuffin monkey, you can make your own movie!
            You decide the characters, the plot, and the costumes. Those young
            budding directors are just itching to unleash their latent
            film-making genius!
          </p>
          <img src={monkey1} alt='img' />
        </div>
        <div className='quiet-fifth-section'>
          <img src={monkey2} alt='img' />

          <p>
            ​Character Building Theme:Every Cooper Kit​ is a fun parenting tool
            for character building and life skills. In this kit, you’ll explore
            confidence and learning from mistakes.
          </p>
        </div>
        <div className='quiet-fifth-section quiet-fifth-section-reverse'>
          <p>
            ​Character Building Theme:Every Cooper Kit​ is a fun parenting tool
            for character building and life skills. In this kit, you’ll explore
            confidence and learning from mistakes.
          </p>
          <img src={monkey3} alt='img' />
        </div>
        <div className='quiet-sixth-section'>
          <h2>
            But that’s <span>just quiet</span> on the set
          </h2>
          <p>
            Every single kit has a collection of FIVE core awesome activities
            and projects​. And they’re always new themes and projects, you’ll
            never get repeats! Among those unique activities, you’ll always find
            building projects, creative expression projects, and more! Plus the
            box always turns into a toy. That’s right. Even the BOX turns into a
            toy, every time. ​​Order below and you’ll get Quiet on the Set for
            your first kit ad then the newest surprise kit every 3 months! Kits
            ship out quarterly in spring, summer, fall, and winter. Meaning
            you’ll be covered for spring break, Father’s Day, back to school,
            and the holiday season!
          </p>
          <h1>ACTIVITY KITS FOR KIDS AND DADS TO DO TOGETHER</h1>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default QuietOnTheSet;
