import React from "react";
import SlideBar from "../Shared/Assets/Homepage/slidebar.svg";

import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper";
function ThirdSection({ thirdsection }) {
  const settings = {
    className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 798,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          infinite: true,
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="third-section-hp third-section-hp-desktop">
        <div className="main-wrapper homepage-third-section">
          <h1>{thirdsection.heading}</h1>
          <img src={SlideBar} className="slideBar" alt="slidebar" />
          <div className="progress-hp">
            <div className="progress-hp-desc">
              <Link
                to={
                  window.location.pathname.includes("birthday")
                    ? "/birthday/subscribe"
                    : "/subscribe"
                }
              >
                <h1>{thirdsection.steps.steponetext} </h1>
                <img src={thirdsection.singlestep1.image} alt="subscribe" />
                <p>{thirdsection.singlestep1.paragraph}</p>
              </Link>
            </div>
            <div className="progress-hp-desc">
              <Link
                to={
                  window.location.pathname.includes("birthday")
                    ? "/birthday/subscribe"
                    : "/subscribe"
                }
              >
                <h1>{thirdsection.steps.steptwotext} </h1>
                <img src={thirdsection.singlestep2.image} alt="truck" />
                <p>{thirdsection.singlestep2.paragraph}</p>
              </Link>
            </div>
            <div className="progress-hp-desc">
              <Link
                to={
                  window.location.pathname.includes("birthday")
                    ? "/birthday/subscribe"
                    : "/subscribe"
                }
              >
                <h1>{thirdsection.steps.stepthreetext} </h1>
                <img src={thirdsection.singlestep3.image} alt="fam" />
                <p>{thirdsection.singlestep3.paragraph}</p>
              </Link>
            </div>
          </div>
          <Link
            to={
              window.location.pathname.includes("birthday")
                ? "/birthday/subscribe"
                : "/subscribe"
            }
            className="hoverable"
          >
            SUBSCRIBE NOW
          </Link>
        </div>
      </div>

      <div className="third-section-hp third-section-hp-mobile">
        <h1>How to get started</h1>
        <img src={SlideBar} alt="slidebar" />
        <div className="progress-hp">
          <Swiper
            slidesPerView={1.4}
            spaceBetween={5}
            centeredSlides={true}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // navigation={{
            //   clickable: true,
            // }}
            modules={[Pagination, Autoplay]}
            breakpoints={{
              998: {
                width: 998,
                slidesPerView: 1,
                centeredSlides: true,
              },
              600: {
                // width: 600,
                slidesPerView: 1.4,
                centeredSlides: true,
              },
            }}
            className="whatyouget-slider"
          >
            <SwiperSlide>
              <div className="progress-hp-desc">
                <Link
                  to={
                    window.location.pathname.includes("birthday")
                      ? "/birthday/subscribe"
                      : "/subscribe"
                  }
                >
                  <h1>{thirdsection.steps.steponetext} </h1>
                  <img src={thirdsection.singlestep1.image} alt="subscribe" />
                  <p>{thirdsection.singlestep1.paragraph}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="progress-hp-desc">
                <Link
                  to={
                    window.location.pathname.includes("birthday")
                      ? "/birthday/subscribe"
                      : "/subscribe"
                  }
                >
                  <h1>{thirdsection.steps.steptwotext} </h1>
                  <img src={thirdsection.singlestep2.image} alt="truck" />
                  <p>{thirdsection.singlestep2.paragraph}</p>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="progress-hp-desc">
                <Link
                  to={
                    window.location.pathname.includes("birthday")
                      ? "/birthday/subscribe"
                      : "/subscribe"
                  }
                >
                  <h1>{thirdsection.steps.stepthreetext} </h1>
                  <img src={thirdsection.singlestep3.image} alt="fam" />
                  <p>{thirdsection.singlestep3.paragraph}</p>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ThirdSection;
