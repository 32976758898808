import React from "react";
import Gift from "../Shared/Assets/Form/gift.png";
import Household from "../Shared/Assets/Form/household.png";
import { BiChevronLeft } from "react-icons/bi";
import ScrollToTop from "../Shared/ScrollToTop";
// import gtag from 'ga-gtag';
import { dataLayer } from "../../dataLayer";
function BoxType({ form, setForm, page, setPage, step2 }) {
  var disabled = true;

  if (form.houseHold === true && form.gifting === true) {
    disabled = true;
  } else {
    disabled = false;
  }

  const borderColor = () => {
    if (form.gifting && form.houseHold) {
      return { opacity: "1", border: "1px solid #90DAE5" };
    } else if (!form.gifting && form.houseHold) {
      return { opacity: "1", border: "2px solid #7B3D5D" };
    } else {
      return { opacity: "0.7", border: "1px solid #90DAE5" };
    }
  };
  const borderColor2 = () => {
    if (form.gifting && form.houseHold) {
      return { opacity: "1", border: "1px solid #90DAE5" };
    } else if (form.gifting && !form.houseHold) {
      return { opacity: "1", border: "2px solid #7B3D5D" };
    } else {
      return { opacity: "0.7", border: "1px solid #90DAE5" };
    }
  };

  function SignUpBirthdayStandard() {
    // Your sign-up logic here
    
    // Push data to dataLayer
    dataLayer.push({'event' : 'birthday_step_2',
    'selection_type' : 'my household'})
  }
 
  function SignUpBirthdayGift() {
    // Your sign-up logic here
    
    // Push data to dataLayer
    dataLayer.push({'event' : 'birthday_step_2',
    'selection_type' : 'sending as a gift'})
  }
 

  return (
    <ScrollToTop>
      <div className="box-types-main">
        <h3>{step2.header}</h3>
        <h5>{step2.subheader}</h5>
        <div className="box-types">
          <div
            className="box-type hoverable"
            style={borderColor()}
            onClick={() => {
              // gtag('event', "stepTwoBirthday", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              SignUpBirthdayStandard()
              setForm({ ...form, gifting: false, houseHold: true });
              setPage(page + 1);
            }}
          >
            <img src={step2.household.image} alt="img" />
            <h1>{step2.household.text}</h1>
          </div>
          <div
            className="box-type hoverable"
            style={borderColor2()}
            onClick={() => {
              // gtag('event', "stepTwoBirthday", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              SignUpBirthdayGift()
              setForm({ ...form, houseHold: false, gifting: true });
              setPage(page + 1);
            }}
          >
            <img src={step2.gift.image} alt="img" />
            <h1>{step2.gift.text}</h1>
          </div>
        </div>
        {/* <div className="form-buttons"> */}
        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className="go-back-box"
        >
          <BiChevronLeft />
          Go back
        </button>
        {/* </div> */}
      </div>
    </ScrollToTop>
  );
}

export default BoxType;
