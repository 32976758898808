import us from '../assets/compir/Us.png';
import logo from '../assets/compir/Isolation_Mode.png';
import them from '../assets/compir/Them.png';
import { Newsletter } from '../newsletter/Newsletter';
import { table } from '../components/data/table';
import './compair.css';

export const Compair = () => {
  return (
    <div className='as_container as_compair_section'>
      <div className='as_container as_compair'>
        <div className='as_compair_wrap'>
          <div className='as_logo'>
            <img className='as_image as_table_logo_img' src={us} alt='Logo' />
            <img className='as_image as_table_logo_img' src={logo} alt='Logo' />
            <img className='as_image as_table_logo_img' src={them} alt='Logo' />
          </div>
          <h4 className='as_table_heading'>
            Cooper Kit = more fun, more learning and more value!
          </h4>
          <div className='as_compair_table_wrap'>
            <table>
              <thead className='as_table_head'>
                <tr>
                  <th className='as_title'></th>
                  <th className='as_cooper_kit'>Cooper Kit</th>
                  <th className='as_others'>Others</th>
                </tr>
              </thead>
              <tbody className='as_table_body'>
                {table?.map((item) => (
                  <tr key={item.title}>
                    <td className='as_title'>{item.title}</td>
                    <td className='as_cooper_kit'>{item.cooper}</td>
                    <td className='as_others'>{item.others}</td>
                  </tr>
                ))}
                <tr>
                  <td className='as_title as_bottom'>
                    <h4 className='as_h4 as_bottom_heading'>Value per kit</h4>
                  </td>
                  <td className='as_cooper_kit as_bottom'>$120</td>
                  <td className='as_others as_bottom'>$25-$50</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Newsletter />
    </div>
  );
};
