import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../Components/Shared/Styles/homepage.css';
import '../Components/Shared/Styles/innovative.css';

import FirstSection from '../Components/Homeschooling/FirstSection';
// import SecondSection from '../Components/Homepage/SecondSection';
// import ThirdSection from '../Components/Homepage/ThirdSection';
import { WhatYouGet } from './moreFunFamilyTime/whatYouGet/WhatYouGet';
// import FourthSection from "../Components/Homepage/FourthSection";
// import FifthSection from '../Components/Homepage/FifthSection';
import SixthSection from '../Components/Homepage/SixthSection';
import SeventhSection from '../Components/Homepage/SeventhSection';
import EighthSection from '../Components/Homepage/EighthSection';
import NinthSection from '../Components/Homepage/NinthSection';
import TenthSection from '../Components/Homepage/TenthSection';
import EleventhSection from '../Components/Homepage/EleventhSection';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Footer from '../Components/Shared/Footer';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import Loader from '../Components/Shared/Loader';
import Innovative from '../Components/Homeschooling/Innovative';
import ThirdsectionHome from '../Components/Homeschooling/ThirdsectionHome';
import Emotional from '../Components/Homeschooling/Emotional';
// import Adventure from '../Components/Homeschooling/adventure';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';
// import {
//   slidesDataContent,
//   slidesDesktopData,
//   slidesMobileData,
//   slidesTabData,
// } from './home/components/main-slider/homeshooling-data';
// import { MainSlider } from './home/components/main-slider/MainSlider';

const bannerData = {
  text: 'Get a Free Bonus Kit with your first Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Homeschooling({ form, setForm, homepage }) {
  useEffect(() => {
    localStorage.setItem('homeschool', true);
  }, []);
  // console.log(homepage.acf);
  return homepage ? (
    <>
      <Helmet>
        <title>Cooper and Kid: Your Homeschool Subscription Box</title>
        <meta
          name='title'
          content='Cooper and Kid: Your Homeschool Subscription Box'
        />
        <meta
          name='description'
          content='For ages 5-12: Fun, story-based learning kits filled with STEM, Crafts, Books, Character & EQ Building, Recipes and a Collectible Challenge Coin! Customized to your learning pace.'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='privacyPolicy'
        containerClasses='cookie'
        debug={false}
        style={{
          background: '#5c2945',
          fontSize: '12px',
          padding: '10px 0px',
          height: 'fit-content',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '14px',
          backgroundColor: '#90dae5',
          padding: '10px 50px',
          borderRadius: '5px',
        }}
        expires={150}
      >
        <p className='cookie-paragraph'>
          {' '}
          We use cookies to provide website functionality, to analyze traffic on
          our Cooper & Kid Site, personalize content, serve targeted
          advertisements and to enable social media functionality. Our Privacy
          Policy provides more information and explains how to update your
          privacy policy. View our{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable cookie-link'
          >
            privacy-policy
          </Link>
        </p>
      </CookieConsent>
      <Navbar />
      {/* <div className='as_homepage_header_slider_desktop'>
        <MainSlider
          slidesData={slidesDesktopData}
          slidesContent={slidesDataContent}
        />
      </div>
      <div className='as_homepage_header_slider_tab'>
        <MainSlider
          slidesData={slidesTabData}
          slidesContent={slidesDataContent}
        />
      </div>
      <div className='as_homepage_header_slider_mobile'>
        <MainSlider
          slidesData={slidesMobileData}
          slidesContent={slidesDataContent}
        />
      </div> */}

      <FirstSection
        form={form}
        setForm={setForm}
        firstsection={homepage.acf.firstsection}
      />
      <Innovative innov={homepage.acf.innovative} />
      {/* <SecondSection secondsection={homepage.acf.experience} /> */}
      <ThirdsectionHome thirdsection={homepage.acf.getstarted} />
      <WhatYouGet />
      {/* <FourthSection fourthsection={homepage.acf.bigbox} /> */}
      {/* <FifthSection fifthsection={homepage.acf.character} /> */}
      {/* <Adventure/> */}
      <Emotional />
      <SixthSection sixthsection={homepage.acf.medallions} />
      <SeventhSection seventhsection={homepage.acf.testimonials} />
      <EighthSection eighthsection={homepage.acf.help} />
      <NinthSection ninthsection={homepage.acf.Family} />
      <TenthSection tenthsection={homepage.acf.faqs} />
      <EleventhSection eleventhsection={homepage.acf.awards} />
      <Footer />
    </>
  ) : (
    <>
      <Loader />
    </>
  );
}

export default Homeschooling;
