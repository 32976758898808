import { logos } from '../components/data/testimonials';

export const BrandLogo = () => {
  return (
    <div className='as_container as_brand'>
      <div className='as_container as_brand_wrap'>
        {logos?.map((logo, index) => (
          <div key={index} className='as_brand_item'>
            <img className='as_image' src={logo.image} alt='' />
          </div>
        ))}
      </div>
    </div>
  );
};
