import react from "react";

import SpaceBook from "../Shared/Assets/Homepage/spacebook.gif";
const Emotional = () =>{
    return (
        <div className="emotional main-wrapper">
     <div className="emotional-display">
     <div>    <h1 className="emotional-h1">Develop Character and Emotional Intelligence!</h1>
            <p className="emotional-p">Explore These 24 Cooper Kit Emotional and Character Development Themes!</p></div>
               <div>
               <img src={SpaceBook} alt="space"/>
               </div>
     </div>
             {/* <FifthSection heading="Develop Character and Emotional Intelligence!" paragraph="Explore These 24 Cooper Kit Emotional and Character Development Themes!" ></FifthSection> */}
          <div className="emotional-flex">
          <div className="emotional-first">
             <h1>Inner Compass</h1>
                 <p>Patience</p>
                 <p>Fears</p>
                 <p>Moderation</p>
              <p>Idealism</p>
                <p>Self-Regulation</p>
                <p>Simplicity</p>
            </div>

            <div className="emotional-second">
             <h1>Interpersonal</h1>
                 <p>Conflict Resolution</p>
                 <p>Respect</p>
                 <p>Generosity</p>
              <p>Apologizing</p>
                <p>Secrets</p>
                <p>Trust</p>
            </div>

            <div className="emotional-third">
             <h1>Mindset</h1>
                 <p>Mindfulness</p>
                 <p>Creativity</p>
                 <p>Optimism</p>
              <p>Open-Mindedness</p>
                <p>Failure</p>
                <p>Confidence</p>
            </div>

            
            <div className="emotional-fourth">
             <h1>Leadership</h1>
                 <p>Adaptability</p>
                 <p>Heroism</p>
                 <p>Authenticity</p>
              <p>Goal Setting</p>
                <p>Responsibility</p>
                <p>Grit & Survival Skills</p>
            </div>
            
          </div>
        </div>
    )
}

export default Emotional;