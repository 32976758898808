import React from "react";


function SecondSection({ about }) {
  return (
    <div className="about-secondSection">
      <h1>{about.visionheading}</h1>
      <p>{about.visionsubheading}</p>
      <img src={about.globeimg} alt="Our vision" className="our-vision-img" />
    </div>
  );
}

export default SecondSection;
