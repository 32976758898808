import React from 'react';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/press.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Press() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <h1 className='press-title'>FEATURED PRESS</h1>{' '}
      <div className='main-press'>
        <div className='press-column1 press-column'>
          <a
            href='http://torispelling.com/blog/post/giveaway-father-s-day-in-a-box'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/EditorialToriSpelling-255x218-1.png'
              alt='img'
            />
          </a>
          <a
            href='http://www.dailycandy.com/everywhere/article/145291/Cooper-Kid'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/DailyCandy.jpg'
              alt='img'
            />
          </a>
          <a
            href='http://www.smallfryblog.com/2013/05/07/cooper-kid/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/smallfryblog-1348681231_600-239x218-1.jpg'
              alt='img'
            />
          </a>
          <a
            href='http://www.bloggerfather.com/2013/05/the-cooper-kit-review.html'
            target='_blank'
            rel='noreferrer'
          >
            <img src='https://cms.cooperkid.com/wp-content/uploads/2022/05/BloggerFather.jpg' />
          </a>
          <a
            href='https://archive.triblive.com/lifestyles/more-lifestyles/learning-activity-kits-keep-kids-busy-stimulated/#axzz2dT7YIgUd'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/triblive-262x218-1.jpg'
              alt='img'
            />
          </a>
          <a
            href='https://www.tellmebest.com/best-toys-creative-play/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/tell-me-best-logo.png'
              alt='img'
            />
          </a>
        </div>
        <div className='press-column2 press-column'>
          <a
            href='http://www.babble.com/home/cooper-kid-the-coolest-subscription-for-the-coolest-dad'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/Babble-logo.jpg'
              alt='img'
            />
          </a>
          <a
            href='http://projectnursery.com/2013/06/last-minute-fathers-day-gifts/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/project-nursery-300x54-1.png'
              alt='img'
            />
          </a>
          <a
            href='https://www.menshealth.com/technology-gear/g19542454/tech-grabs-dads-grads/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/Mens-health-logo-300x67-1.jpg'
              alt='img'
            />
          </a>
          <a
            href='https://geekdad.com/2013/06/cooper-and-kid/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/cropped-logo-geekdad-300x163-1.png'
              alt='img'
            />
          </a>
          <a
            href='https://www.hugedomains.com/domain_profile.cfm?d=thenoobdad.com'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/noob-dad-300x110-1.jpg'
              alt='img'
            />
          </a>
          <a
            href='https://coolmompicks.com/blog/06/fathers-day-gifts-from-kids/.php/#more'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/cool-mom-picks-logo-300x141-1.png'
              alt='img'
            />
          </a>
          <a
            href='https://seramount.com/working-mother/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/working-mother.png'
              alt='img'
            />
          </a>
        </div>
        <div className='press-column3 press-column'>
          <h1>AWARDS</h1>
          <a
            href='https://www.modernman.com/2013-evolve-awards-gadgets-toys/'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/MM-evolve-logos-2013-186x218-1.jpg'
              alt='img'
            />
          </a>{' '}
          <a
            href='https://www.modernman.com/2013-evolve-awards-gadgets-toys/'
            target='_blank'
            rel='noreferrer'
          >
            2013 Modern Man Evolve Award
          </a>
          <h1>PRESS CONTACT</h1>
          <a
            href='mailto:press@cooperandkid.com'
            className='press-contact-links'
          >
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/email.png'
              alt='img'
            />
            press@cooperandkid.com
          </a>
          <a href='tel:9523880478' className='press-contact-links'>
            <img
              src='https://cms.cooperkid.com/wp-content/uploads/2022/05/phone.png'
              alt='img'
            />
            952.388.0478
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Press;
