import React from "react";

function FifthSection({ about }) {
  return (
    <div className="about-fifthSection">
      <h1 className="a5-title">{about.whoweareheading}</h1>

      <div dangerouslySetInnerHTML={{ __html: about.whoweareparagraph }} />
    </div>
  );
}

export default FifthSection;
