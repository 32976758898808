import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/affiliate.css';
import image1 from '../Components/Shared/Assets/Keep Pages/Bedtime-story-homepage-small.jpg';
import image2 from '../Components/Shared/Assets/Keep Pages/Machines-in-Motion-300-dpi-cleaned.jpg';
import quotesDown from '../Components/Shared/Assets/Keep Pages/quotes.svg';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function AffiliateProgram() {
  return (
    <>
      <Helmet>
        <title>Cooper and Kid: Affiliate Program</title>
        <meta name='title' content='Cooper and Kid: Affiliate Program' />
        <meta
          name='description'
          content='Cooper and Kid: Do you talk to homeschoolers and parents? Become an affiliate!'
        />
      </Helmet>
      <div className='white-background'>
        <OfferBanner data={bannerData} />
        <Navbar />
        <div className='main-wrapper affiliate-program'>
          <h1 className='affiliate-main-title'>
            Cooper & Kid Affiliate Program:
          </h1>
          <div className='affiliate-images'>
            <img src={image1} className='affiliate-images-1' alt='img' />
            <img src={image2} className='affiliate-images-2' alt='img' />
          </div>
          <h1 className='affiliate-program-center-title'>
            Thank You for Your Interest in Our Affiliate Program
          </h1>
          <p>
            Through our affiliate program, you'll be able to earn commissions by
            promoting the Cooper Kit subscription. Subscription options include
            the Quarterly, Yearly, and non-renewing Year of Kits option. The
            Cooper Kit works as a great gift for Mother's Day, Father's Day,
            birthdays, homeschoolers and the holiday season. Though, there is
            never really a bad time of year to encourage family engagement!
          </p>
          <h1 className='affiliate-program-center-title'>
            Affiliate Program Benefits
          </h1>
          <ul className='affiliate-list'>
            <li>15% commission on Quarterly Subscription initial signups</li>
            <li>
              5% on Annual Subscription and Year of Kit package initial signups
            </li>
            <li>Special coupon codes and seasonal promotions</li>
            <li>Dedicated creative</li>
            <li>Responsive representative from Cooper & Kid</li>
          </ul>
          <a
            href='https://www.shareasale.com/shareasale.cfm?merchantID=54977'
            target='_blank'
            rel='noreferrer'
            className='hoverable'
          >
            APPLY TO BE AN AFFILIATE
          </a>
          <div className='affiliate-overview'>
            <h1>The Cooper Kit Overview:</h1>
          </div>
          <div className='affiliate-overview'>
            <div className='overview'>
              <h1>The Cooper Kit is:</h1>
              <ul>
                <li>A Quarterly Activity Kit Subscription Box</li>
                <li>For Grownups & Kids (5-12) to do Together</li>
                <li>A Parenting Tool to Teach Character Building</li>
                <li>A Unique Theme Each Quarter</li>
              </ul>
            </div>
            <div className='overview'>
              <h1>The Cooper Kit Contains:</h1>
              <ul>
                <li>5 Core Activities</li>
                <li>Bedtime Storybook</li>
                <li>Themed Cookbook</li>
                <li>The Shipping Box Turns into a Toy</li>
                <li>Building & Creative Expression Projects</li>
                <li>A Collectible Medallion</li>
              </ul>
            </div>
            <div className='overview'>
              <h1>Sample Kit Examples:</h1>
              <ul>
                <li>Machines in Motion</li>
                <li>Quiet on the Set</li>
                <li>Deep</li>
                <li>Music: A Sound Odyssey</li>
                <li>Sneak & Destroy</li>
              </ul>
            </div>
          </div>
          <a
            href='https://www.shareasale.com/shareasale.cfm?merchantID=54977'
            target='_blank'
            rel='noreferrer'
            className='hoverable'
          >
            APPLY TO BE AN AFFILIATE
          </a>

          <h1 className='affiliate-program-center-title'>
            What People Are Saying About Our Kits
          </h1>
          <div className='affiliate-testimonials'>
            <div className='affiliate-testimonials-small'>
              <h1 className='affiliate-testimonials-name'>Vanessa W.</h1>
              <p className='affiliate-testimonials-text'>
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-up-quotes'
                />
                Amazing compilation of items. Variety of entertaining options to
                fully engage both parent and child. Will recommend to others!
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-down-quotes'
                />
              </p>
            </div>
            <div className='affiliate-testimonials-medium'>
              <h1 className='affiliate-testimonials-name'>Levi W.</h1>
              <p className='affiliate-testimonials-text'>
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-up-quotes'
                />
                My daughter and I have both been absolutely loving the kit. We
                opened the box together on Saturday morning and proceeded to
                spend the next three hours building and playing. It not only
                gave us some specific activities to do but also sparked good
                discussions about physics and memories from my childhood.
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-down-quotes'
                />
              </p>
            </div>
            <div className='affiliate-testimonials-small'>
              <h1 className='affiliate-testimonials-name'>Lisa M.</h1>
              <p className='affiliate-testimonials-text'>
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-up-quotes'
                />
                Our daughter is so excited to find the kit on the porch and it's
                so fun for daddy and daughter to enjoy something just for the
                two of them.
                <img
                  src={quotesDown}
                  className='affiliate-testimonials-down-quotes'
                />
              </p>
            </div>
          </div>
          <a
            href='https://www.shareasale.com/shareasale.cfm?merchantID=54977'
            target='_blank'
            rel='noreferrer'
            className='hoverable'
          >
            APPLY TO BE AN AFFILIATE
          </a>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default AffiliateProgram;
