import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../../Components/Shared/Styles/homepage.css';
import '../../Components/Shared/Styles/as_homepage.css';
import SecondSection from '../../Components/Homepage/SecondSection';
import ThirdSection from '../../Components/Homepage/ThirdSection';
import FifthSection from '../../Components/Homepage/FifthSection';
import SixthSection from '../../Components/Homepage/SixthSection';
import SeventhSection from '../../Components/Homepage/SeventhSection';
import EighthSection from '../../Components/Homepage/EighthSection';
import NinthSection from '../../Components/Homepage/NinthSection';
import TenthSection from '../../Components/Homepage/TenthSection';
import EleventhSection from '../../Components/Homepage/EleventhSection';
import Navbar from '../../Components/Shared/Navbar/Navbar';
import Footer from '../../Components/Shared/Footer';
import Loader from '../../Components/Shared/Loader';
import { MainSlider } from './components/main-slider/MainSlider';
import { WhatYouGet } from '../moreFunFamilyTime/whatYouGet/WhatYouGet';
import { CookieComponent } from '../../layouts/CookieComponent';
import { OfferBanner } from '../../layouts/offer-banner/OfferBanner';
import {
  slidesDataContent,
  slidesDesktopData,
  slidesMobileData,
  slidesTabData,
} from './components/main-slider/data';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

export const HomePage = ({ form, setForm, homepage }) => {
  return homepage ? (
    <>
      <Helmet>
        <title>Cooper and Kid: Family Subscription Box</title>
        <meta name='title' content='Cooper and Kid: Family Subscription Box' />
        <meta
          name='description'
          content='Kids grow up fast! Get memorable activity boxes for busy parents and kids to enjoy together. Explore social emotional development and collect challenge coins too!'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='as_homepage_header_slider_desktop'>
        <MainSlider
          slidesData={slidesDesktopData}
          slidesContent={slidesDataContent}
        />
      </div>
      <div className='as_homepage_header_slider_tab'>
        <MainSlider
          slidesData={slidesTabData}
          slidesContent={slidesDataContent}
        />
      </div>
      <div className='as_homepage_header_slider_mobile'>
        <MainSlider
          slidesData={slidesMobileData}
          slidesContent={slidesDataContent}
        />
      </div>

      <SecondSection secondsection={homepage.acf.experience} />
      <ThirdSection thirdsection={homepage.acf.getstarted} />
      <WhatYouGet />
      <FifthSection fifthsection={homepage.acf.character} />
      <SixthSection sixthsection={homepage.acf.medallions} />
      <SeventhSection seventhsection={homepage.acf.testimonials} />
      <EighthSection eighthsection={homepage.acf.help} />
      <NinthSection ninthsection={homepage.acf.Family} />
      <TenthSection tenthsection={homepage.acf.faqs} />
      <EleventhSection eleventhsection={homepage.acf.awards} />
      <Footer />
      <CookieComponent />
    </>
  ) : (
    <>
      <Loader />
    </>
  );
};
