import React from "react";

function FourthSection({ about }) {
  return (
    <div className="about-fourthSection">
      <h1>
        <span>{about.changedheading}</span>
        <br></br> {about.changedsubHead}
      </h1>
      {/* <p>{about.changedparagraph}</p> */}
      <div dangerouslySetInnerHTML={{ __html: about.changedparagraph }} />
    </div>
  );
}

export default FourthSection;
