import React from "react";
import { Link } from "react-router-dom";

function FirstSection({ section1 }) {
  return (
    <div
      className="first-section-pk"
      style={{ backgroundImage: `url(${section1.background.url})` }}
    >
      <div className="main-wrapper pk-first-section">
        <div className="first-section-pk-text">
          <h1>{section1.mainheading}</h1>
          <h2>{section1.heading2}</h2>
          <p>{section1.paragraph}</p>
          <Link
            to={
              window.location.pathname.includes("homeschooling")
                ? "/homeschooling/subscribe"
                : "/subscribe"
            }
            className="hoverable"
          >
            SUBSCRIBE NOW
          </Link>
        </div>
        <img src={section1.image.url} alt="img" />
      </div>
    </div>
  );
}

export default FirstSection;
