import React from "react";
import { Link } from "react-router-dom";
function NinthSection({ lastsection }) {
  return (
    <div className="main-wrapper pk-ninth-section">
      <div className="ninth-section-pk">
        <div className="ninth-section-pk-text">
          <h3>{lastsection.subheading}</h3>
          <h2>{lastsection.heading}</h2>
          <p>{lastsection.paragraph}</p>
          <Link
            to={
              window.location.pathname.includes("homeschooling")
                ? "/homeschooling/subscribe"
                : "/subscribe"
            }
            className="hoverable"
          >
            {lastsection.button}
          </Link>
        </div>
        <img src={lastsection.image} alt="img" />
      </div>
    </div>
  );
}

export default NinthSection;
