import React from 'react';
import { Helmet } from 'react-helmet-async';
import FifthSection from '../Components/About/FifthSection';
import FirstSection from '../Components/About/FirstSection';
import FourthSection from '../Components/About/FourthSection';
import SecondSection from '../Components/About/SecondSection';
import SeventhSection from '../Components/About/SeventhSection';
import SixthSection from '../Components/About/SixthSection';
import ThirdSection from '../Components/About/ThirdSection';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/about.css';
import '../Components/Shared/Styles/footer.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function About({ aboutUs }) {
  return (
    <>
      <Helmet>
        <title>Cooper and Kid: Helping You Raise Thriving Kids</title>
        <meta
          name='title'
          content='Cooper and Kid: Helping You Raise Thriving Kids'
        />
        <meta
          name='description'
          content="Cooper & Kid's inter-generational tools help parents with fun, educational resources that make family time fun, meaningful and valuable for everyone."
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='about-page'>
        <div className='main-wrapper'>
          <FirstSection about={aboutUs.acf} />
          <SecondSection about={aboutUs.acf} />
          <ThirdSection about={aboutUs.acf} />
          <FourthSection about={aboutUs.acf} />
          <FifthSection about={aboutUs.acf} />
          <SixthSection about={aboutUs.acf} />
          <SeventhSection about={aboutUs.acf} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
