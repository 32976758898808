import React from "react";
import { Link } from "react-router-dom";

import Box from "../Shared/Assets/About/box.png";

function SeventhSection({ about }) {
  return (
    <div className="about-seventhSection">
      <div className="about-seventhSection-text">
        <h1>{about.cooperkitheading}</h1>
        <p>{about.cooperkitparagraph}</p>
        <Link
          to={
            window.location.pathname.includes("homeschooling")
              ? "/homeschooling/subscribe"
              : "/subscribe"
          }
        >
          Get A Cooper Kit
        </Link>
      </div>
      <img src={Box} alt="Box Image!" />
    </div>
  );
}

export default SeventhSection;
