import React, { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";

import Loader from "../Shared/Loader";
import Tab1 from "./Tabs/Tab1";
import Tab2 from "./Tabs/Tab2";
import ScrollToTop from "../Shared/ScrollToTop";

import { dataLayer } from "../../dataLayer";

function Upsell({ form, setForm, page, setPage, upsell }) {
  const [loader, setLoader] = useState(false);

  const [tshirt, setTshirt] = useState({
    adult: true,
    child: false,
  });
  const switchTabs = (upsell) => {
    if (tshirt.adult) {
      return <Tab1 upsell={upsell} />;
    } else {
      return <Tab2 upsell={upsell} />;
    }
  };
  const getSwitchBoxType = (boxType) => {
    var type = "";
    boxType.filter((e) => {
      if (e.isChecked) type = e.type;
    });
    return type;
  };
  // console.log(form.standardBoxes.filter((e) => e.isChecked)[0].price);
  // console.log(form);

  var params =
    "?name=" +
    form.name +
    "&isGift=" +
    form.gifting +
    "&numberOfChildren=" +
    form.numberOfChildren +
    "&email=" +
    form.email +
    "&medallions=" +
    form.numberOfChildren;



    function handleSignUpBirthdayYes() {
      // Your sign-up logic here
      
      // Push data to dataLayer
      dataLayer.push({'event' : 'birthday_step_6_upsell',
      'selection_type' : 'yes',
      'upsell_type' : 'Medallion'})
      
    }
  
    function handleSignUpBirthdayNo() {
      // Your sign-up logic here
      
      // Push data to dataLayer
      dataLayer.push({'event' : 'birthday_step_6_upsell',
      'selection_type' : 'no',
      'upsell_type' : 'Medallion'})
     
      
    }


  return !loader ? (
    <ScrollToTop>
      <h1 className="t-shirt-header">{upsell.upsell.topheader}</h1>
      <div className="medallion-container">
        <div className="medallion-image">
          <div className="tshirt-tabs">
            <div
              className="tshirt-tab tshirt-tab1 hoverable"
              style={
                tshirt.adult
                  ? {
                      backgroundColor: "#7B3D5D",
                      height: "105%",
                      borderColor: "#7B3D5D",
                    }
                  : { backgroundColor: "#F6F7F8" }
              }
              onClick={() => {
                setTshirt({ ...tshirt, adult: true, child: false });
              }}
            >
              <p style={tshirt.adult ? { color: "white" } : { color: "black" }}>
                Adult
              </p>
            </div>
            <div
              className="tshirt-tab tshirt-tab2 hoverable"
              style={
                tshirt.child
                  ? {
                      backgroundColor: "#7B3D5D",
                      height: "105%",
                      borderColor: "#7B3D5D",
                    }
                  : { backgroundColor: "#F6F7F8" }
              }
              onClick={() => {
                setTshirt({ ...tshirt, adult: false, child: true });
              }}
            >
              <p style={tshirt.child ? { color: "white" } : { color: "black" }}>
                Youth
              </p>
            </div>
          </div>

          {switchTabs(upsell)}
        </div>
        <div className="medallion-text">
          <h1>{upsell.upsell.header}</h1>
          {tshirt.adult ? <p className="adult-p">Add adult and youth t-shirts to your first box to remember that character development is a life-long process! Fun for the whole family! $20 per t-shirt.</p> :
                    <div dangerouslySetInnerHTML={{ __html: upsell.upsell.paragraph }} />
        }
          {/* <p>{upsell.upsell.paragraph}</p> */}

          <button
            className="confirm-button hoverable"
            onClick={() => {
              // gtag('event', "stepSixBirthdayUpsellYes", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              handleSignUpBirthdayYes()
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren === 1) {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case "Quarterly Gift":
                      localStorage.setItem(
                        "SKU",
                        "quarterly-subscription-family"
                      );

                      window.location.href =
                        "https://aw135.infusionsoft.app/app/orderForms/2022-US-Quarterly-Tshirts-Subscription" +
                        params;

                      break;
                    case "Standard Yearly":
                      localStorage.setItem("SKU", "annual-billed-quarterly");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Tshirts-Subscription" +
                        params;
                      break;
                    case "One Year Gift":
                      localStorage.setItem("SKU", "yearly-subscription");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/yok-tshirt" +
                        params;

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case "Quarterly":
                      localStorage.setItem(
                        "SKU",
                        "quarterly-subscription-family"
                      );

                      window.location.href =
                        "https://aw135.infusionsoft.app/app/orderForms/2022-US-Quarterly-Tshirts-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      localStorage.setItem("SKU", "annual-billed-quarterly");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Tshirts-Subscription" +
                        params;
                      break;
                    case "Prepay Yearly":
                      localStorage.setItem("SKU", "yearly-subscription");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-TShirt-Subscription" +
                        params;

                      break;
                    default:
                      break;
                  }
                }
              } else {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case "Quarterly Gift":
                      localStorage.setItem(
                        "SKU",
                        "quarterly-subscription-family"
                      );

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Medallion-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      localStorage.setItem("SKU", "annual-billed-quarterly");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Medallion-Subscription" +
                        params;
                      break;
                    case "One Year Gift":
                      localStorage.setItem("SKU", "yearly-subscription");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/yok-medallion" +
                        params;

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case "Quarterly":
                      localStorage.setItem(
                        "SKU",
                        "quarterly-subscription-family"
                      );

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Medallion-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      localStorage.setItem("SKU", "annual-billed-quarterly");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Medallion-Subscription" +
                        params;
                      break;
                    case "Prepay Yearly":
                      localStorage.setItem("SKU", "yearly-subscription");

                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Medallion-Subscription" +
                        params;

                      break;
                    default:
                      break;
                  }
                }
              }

              // window.location.href =
              //   "https://aw135.infusionsoft.com/app/form/quarterly-point-of-sale2" +
              //   params;

              localStorage.setItem("name", form.name);
              localStorage.setItem("lastName", form.lastName);
              if (form.gifting === true) {
                localStorage.setItem("isGift", true);
              } else {
                localStorage.setItem("isGift", false);
              }
              localStorage.setItem("numberOfChildren", form.numberOfChildren);
              localStorage.setItem("email", form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  "giftBoxType",
                  form.giftingBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              } else {
                localStorage.setItem(
                  "houseHoldType",
                  form.standardBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              }
              localStorage.setItem("medallion", form.medallionUpsell);
              setLoader(true);
            }}
          >
            Yes, please!
          </button>
          {loader ? <Loader /> : ""}
          <button
            className="hoverable"
            onClick={() => {
              // gtag('event', "stepSixBirthdayUpsellNo", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              handleSignUpBirthdayNo()
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren === 1) {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case "Quarterly Gift":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
                        params;
                      break;
                    case "One Year Gift":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/yok" +
                        params;

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case "Quarterly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
                        params;
                      break;
                    case "Prepay Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription" +
                        params;

                      break;
                    default:
                      break;
                  }
                }
              } else {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case "Quarterly Gift":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
                        params;
                      break;
                    case "One Year Gift":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/yok" +
                        params;

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case "Quarterly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
                        params;
                      break;
                    case "Standard Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
                        params;
                      break;
                    case "Prepay Yearly":
                      window.location.href =
                        "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription" +
                        params;

                      break;
                    default:
                      break;
                  }
                }
              }

              // window.location.href =
              //   "https://aw135.infusionsoft.com/app/form/quarterly-point-of-sale2" +
              //   params;

              localStorage.setItem("name", form.name);
              localStorage.setItem("lastName", form.lastName);
              if (form.gifting === true) {
                localStorage.setItem("isGift", true);
              } else {
                localStorage.setItem("isGift", false);
              }
              localStorage.setItem("numberOfChildren", form.numberOfChildren);
              localStorage.setItem("email", form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  "giftBoxType",
                  form.giftingBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              } else {
                localStorage.setItem(
                  "houseHoldType",
                  form.standardBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              }
              localStorage.setItem("medallion", form.medallionUpsell);
              setLoader(true);
            }}
          >
            No, thanks
          </button>
        </div>
      </div>
      <div className="upsell-container">
        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className="go-back-box"
        >
          <BiChevronLeft />
          Go back
        </button>
      </div>
    </ScrollToTop>
  ) : (
    <Loader />
  );
}

export default Upsell;
