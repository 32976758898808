import { Link } from 'react-router-dom';

export const OfferBanner = ({ data }) => {
  return (
    <div style={data.style}>
      <div>{data?.text}</div>
      <Link style={data.linkStyle} to={data?.link}>
        Get Offer
      </Link>
    </div>
  );
};
