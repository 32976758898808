import React from 'react';

function Tab4({ tab4 }) {
  return (
    <div className='tab1'>
      <div className='tab2-text'>
        <h1 className='tab4-h1'>{tab4.subheading}</h1>
        <h2 id='tab4-h2'>{tab4.heading}</h2>
        {/* <p>{tab4.paragraph}</p> */}
        <p
          dangerouslySetInnerHTML={{
            __html: tab4.paragraph,
          }}
        ></p>
      </div>
      <img src={tab4.image} alt='img' />
    </div>
  );
}

export default Tab4;
