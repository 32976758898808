import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import '../Components/Shared/Styles/terms.css';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Privacy({ privacy }) {
  return (
    <>
      <Helmet>
        <title>The Cooper and Kid Box Privacy Policy</title>
        <meta name='title' content='The Cooper and Kid Box Privacy Policy' />
        <meta
          name='description'
          content='The Cooper and Kid Box Privacy Policy.'
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='terms-of-services'>
        <div
          className='terms-div'
          dangerouslySetInnerHTML={{ __html: privacy.content.rendered }}
        ></div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
