import React, { useEffect, useState } from 'react';
import '../Shared/Styles/form.css';

import Medallion from './Medallion';

// import Upsell from "./Upsell";
import Logo from '../Shared/Assets/Logo.svg';
import Step1 from '../Shared/Assets/Form/just1.svg';
import Step2 from '../Shared/Assets/Form/just2.svg';

import { Link } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import GiftBoxes from './GiftBoxes';

function FormGiftit({ form, setForm, signupForm, sub }) {
  // useEffect(() => {
  //   if (window.location.pathname === "/get-it") {
  //     setPage(5);
  //     setForm({
  //       numberOfChildren: 2,
  //       gifting: false,
  //       multipleChildren: true,
  //       agreed: true,
  //       houseHold: true,
  //     });
  //   } else if (window.location.pathname === "/gift-it") {
  //     setPage(5);
  //     setForm({
  //       numberOfChildren: 2,
  //       gifting: true,
  //       multipleChildren: true,
  //       agreed: true,
  //       houseHold: false,
  //     });
  //   }
  // }, []);

  const [page, setPage] = useState(1);
  var src = '';
  // console.info(signupForm);

  const slideBar = (page) => {
    switch (page) {
      case 1:
        return (src = Step1);
      case 2:
        return (src = Step2);

      default:
    }
  };

  const pageDisplay = (page) => {
    switch (page) {
      case 1:
        return (
          <GiftBoxes
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            sub={sub}
          />
        );

      case 2:
        return (
          <Medallion
            form={form}
            setForm={setForm}
            page={page}
            setPage={setPage}
            medallion={sub}
          />
        );

      case 3:
        return <p>blank</p>;

      default:
    }
  };
  // console.log(form);
  return (
    <>
      {/* <div style={{ backgroundColor: "yellow" }}>
        <h2 style={{ textAlign: "center", padding: "20px", fontSize: "20px" }}>
          Get 25% off your first box using the code 'DAD25OFF' at checkout!
        </h2>
      </div> */}
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='privacyPolicy'
        containerClasses='cookie'
        debug={false}
        style={{
          background: '#5c2945',
          fontSize: '12px',
          padding: '10px 0px',
          height: 'fit-content',
        }}
        buttonStyle={{
          color: 'white',
          fontSize: '13px',
          backgroundColor: '#90dae5',
          padding: '10px 50px',
          borderRadius: '5px',
        }}
        expires={false}
      >
        <p className='cookie-paragraph'>
          {' '}
          We use cookies to provide website functionality, to analyze traffic on
          our Cooper & Kid Site, personalize content, serve targeted
          advertisements and to enable social media functionality. Our Privacy
          Policy provides more information and explains how to update your
          privacy policy. View our{' '}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable cookie-link'
          >
            privacy-policy
          </Link>
        </p>
      </CookieConsent>

      <div className='form-header getit-form'>
        <img src={slideBar(page)} alt='img' className='slidebar' />
        <div className='internationall-p'></div>
      </div>
      <div className='form-body form-getit'>
        {pageDisplay(page)}{' '}
        {window.location.pathname.includes('homeschooling') ? (
          <Link to='/' className='homeschoolerLink'>
            Not a Homeschooler?
          </Link>
        ) : null}
      </div>
    </>
  );
}

export default FormGiftit;
