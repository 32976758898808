/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Form from './Components/Form/Form';
import ScrollToTop from './Components/Shared/ScrollToTop';
import PeekInside from './Pages/PeekInside';
import Giftbox1 from './Components/Shared/Assets/Form/box1gift.png';
import Giftbox2 from './Components/Shared/Assets/Form/box2gift.png';
import Form2 from './Components/homeschoolingForm/Form';
import Form3 from './Components/FormBirthday/Form';
import Giftbox11 from './Components/Shared/Assets/Form/1box.png';
import Giftbox22 from './Components/Shared/Assets/Form/2box.png';
import Giftbox33 from './Components/Shared/Assets/Form/3box.png';
import StandardBoxe3 from './Components/Shared/Assets/Form/standardbox3.png';
import Terms from './Pages/Terms';
import CostumerService from './Pages/CostumerService';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Thankyou from './Pages/Thankyou';
import Faqs from './Pages/Faqs';
import Privacy from './Pages/Privacy';
import AffiliateProgram from './Pages/AffiliateProgram';
import Canada from './Pages/Canada';
import Footer from './Components/Shared/Footer';
import ContactSuccess from './Pages/ContactSuccess';
import NewsletterThankyou from './Pages/NewsletterThankyou';
import AnnualOrderReceived from './Pages/AnnualOrderReceived';
import OrderReceived from './Pages/OrderReceived';
import TryToProcess from './Pages/TryToProcess';
import QuarterlyOrderReceived from './Pages/QuarterlyOrderReceived';
import SelectedCountry from './Pages/SelectedCountry';
import Loader from './Components/Shared/Loader';
import AsiaAustralia from './Pages/AsiaAustralia';
import Europe1 from './Pages/Europe1';
import Europe2 from './Pages/Europe2';
import International3 from './Pages/International3';
import LatinAmerica from './Pages/LatinAmerica';
import MajorEuAsiaLatin from './Pages/MajorEuAsiaLatin';
import Mexico from './Pages/Mexico';
import MiddleEastAndAfrica from './Pages/MiddleEastAndAfrica';
import International2 from './Pages/International2';
import Uk from './Pages/Uk';
import WesternEurope from './Pages/WesternEurope';
import FriendsAndFamily from './Pages/FriendsAndFamily';
import LiveEvents from './Pages/LiveEvents';
import QuietOnTheSet from './Pages/QuietOnTheSet';
import Test from './Pages/QuarterlyThankyou';
import QuarterlyThankyou from './Pages/QuarterlyThankyou';
import YokOrder from './Pages/YokOrder';
import AnnualOrder from './Pages/AnnualOrder';
import StandardYearly from './Pages/StandardYearly';
import CookieConsent from 'react-cookie-consent';
import Page404 from './Pages/404';
import FathersDay2018 from './Pages/FathersDay2018';
import Bonus from './Pages/Bonus';
import Popbox from './Pages/Popbox';
import Press from './Pages/Press';
import Music from './Pages/Music';
// import ReactPixel from "react-facebook-pixel";
import PreSchools from './Pages/PreSchools';
import MyAcc from './Pages/MyAcc';
import Homeschooling from './Pages/Homeschooling';
import GetIt from './Pages/GetIt';
import Getgift from './Pages/Giftit';
// import Birthday from "./Pages/Birthday";
import Birthday from './Pages/Birthday1';
import Adventure from './Pages/Adventure';
import StandardBoxes from './Pages/HomeschoolGetit';
import GiftBoxes from './Pages/HomeschoolGiftit';
// import TopNav from "./Components/Shared/Navbar/topNav";
import LandingPage from './Pages/landingPage';
import FullLandingPage from './Pages/fullLandingPage';
import Conference from './Pages/conference';
// pages
import { HomePage } from './Pages/home/HomePage';
import { MoreFunFamilyTime } from './Pages/moreFunFamilyTime/MoreFunFamilyTime';
import ThankYou from './Pages/thankyouPage/ThankYou';

console.warn = console.error = () => {};

function App() {
  const [loaded, setLoaded] = useState(true);
  const [pages, setpages] = useState({
    pages: [],
    isLoaded: false,
  });
  const [subscribeForm, setSubscribeForm] = useState({
    subscribe: [],
    isLoaded: false,
  });

  useEffect(() => {
    if (!window.location.pathname.includes('homeschooling')) {
      localStorage.removeItem('homeschool');
    } else {
      // Do Nothing!!!
    }
  }, []);
  var singup,
    contactForm,
    samplePage,
    completeYourOrder,
    thankYou,
    quiteOnTheSet,
    affiliateProgram,
    fathersDay2008,
    yearlyGift,
    yearlyStandard,
    yearlyPrepaid,
    quarterly,
    homepage,
    peekinside,
    subscribe,
    aboutUs,
    termsOfUse,
    costumerServices,
    privacyPolicy,
    homeschooling,
    birthday,
    offer,
    friendsAndFamily,
    landingPage,
    LiveEvents;
  // eslint-disable-next-line array-callback-return
  pages.pages.map((e) => {
    // eslint-disable-next-line default-case
    switch (e.id) {
      case 55:
        singup = e;
        break;
      case 22:
        contactForm = e;
        break;

      case 2:
        samplePage = e;
        break;
      case 17423:
        completeYourOrder = e;
        break;
      case 17418:
        thankYou = e;
        break;
      case 17176:
        quiteOnTheSet = e;
        break;
      case 15549:
        affiliateProgram = e;
        break;
      case 17146:
        fathersDay2008 = e;
        break;
      case 16393:
        fathersDay2008 = e;
        break;
      case 6:
        quarterly = e;
        break;
      case 19583:
        yearlyPrepaid = e;
        break;
      case 19584:
        yearlyStandard = e;
        break;
      case 19585:
        yearlyGift = e;
        break;
      case 19638:
        homepage = e;
        break;
      case 19724:
        peekinside = e;
        break;
      case 20075:
        subscribe = e;
        break;
      case 19913:
        aboutUs = e;
        break;
      case 20258:
        termsOfUse = e;
        break;
      case 20261:
        costumerServices = e;
        break;
      case 20266:
        privacyPolicy = e;
        break;
      case 21235:
        homeschooling = e;
        break;
      case 22039:
        birthday = e;
        break;
      case 22089:
        offer = e;
        break;
      case 22101:
        friendsAndFamily = e;
        break;
      case 22148:
        landingPage = e;
        break;
      case 22272:
        LiveEvents = e;
    }
  });

  const [form, setForm] = useState({
    numberOfChildren: 0,
    houseHold: true,
    gifting: true,
    email: '',
    name: '',
    lastName: '',
    date: [],
    otherUpsell: false,
    medallionUpsell: false,
    mailError: true,
    multipleChildren: false,
    agreed: false,
    chooseKit: false,
    surprise: 0,
    receive: 1,
    kitType: 1,
    standardBoxes: [
      {
        id: 1,
        isChecked: false,
        img: <img src={Giftbox1} alt='img' className='image-1' />,
        title: 'Quarterly Subscription',
        price: '$69.99 per Kit',
        info: 'Free Shipping. Recurring subscription. Get 1 Cooper Kit every 3 months. Billed quarterly. Cancel anytime.*',
        bonus: '',
        type: 'Quarterly',
      },
      {
        id: 2,
        isChecked: false,
        img: <img src={Giftbox2} alt='img' />,
        title: 'Standard Yearly Subscription',
        price: '$59.99 per Kit',
        info: 'Free Shipping. Recurring subscription. Get 1 Cooper Kit every 3 months for a year. Billed quarterly.*',
        bonus: 'Free Bonus Family Game!',

        type: 'Standard Yearly',
      },
      {
        id: 3,
        isChecked: false,
        img: <img src={StandardBoxe3} alt='img' />,
        title: 'Prepay Yearly Subscription',
        price: '$59.99 per Kit',
        info: 'Free Shipping. Recurring subscription. Get 1 Cooper Kit every 3 months for a year. Billed yearly.  $239.96*',
        bonus: 'Free Bonus Family Game!',

        type: 'Prepay Yearly',
      },
    ],
    giftingBoxes: [
      {
        id: 1,
        isChecked: false,
        img: <img src={Giftbox11} alt='img' className='image-1' />,
        title: 'Quarterly Gift Subscription',
        price: '$69.99 per Kit',
        info: 'Free Shipping. Recurring subscription. Gift 1 Cooper Kit every 3 months. Billed quarterly. Cancel anytime.*',
        bonus: '',

        type: 'Quarterly Gift',
      },
      {
        id: 2,
        isChecked: false,
        img: <img src={Giftbox22} alt='img' />,
        title: 'Standard Yearly Gift Subscription',
        price: '$59.99 per Kit',
        info: 'Free Shipping. Recurring subscription. Gift 1 Cooper Kit every 3 months for a year.Billed quarterly.*',
        bonus: 'Free Bonus Family Game!',

        type: 'Standard Yearly',
      },
      {
        id: 3,
        isChecked: false,
        img: <img src={Giftbox33} alt='img' />,
        title: 'One Year Gift Package',
        price: '$59.99 per Kit',
        info: 'Free Shipping. Gift 1 Cooper Kit every 3 months for a year. Prepay total of $259.96. Non-Renewing. ',
        bonus: 'Free Bonus Family Game!',

        type: 'One Year Gift',
      },
    ],
    iframeSrc: '',
    nameValid: true,

    lastNameValid: true,
  });

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }
  useEffect(() => {
    axios
      .get('https://cms.cooperkid.com/wp-json/wp/v2/pages?per_page=100')
      .then((res) => {
        setpages({
          pages: res.data,
          isLoaded: true,
        });
      })
      .catch((err) => console.log(err));
    timeout(500).then((e) => {
      setLoaded(false);
    });
  }, []);

  useEffect(() => {
    setSubscribeForm({
      subscribe: pages.pages.filter((e) => e.id === 20075),
      isLoaded: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages.isLoaded]);

  useEffect(() => {
    if (subscribeForm.isLoaded) {
      setForm({
        ...form,
        giftingBoxes: [
          subscribeForm.subscribe[0].acf.giftingproducts.product1,
          subscribeForm.subscribe[0].acf.giftingproducts.product2,
          subscribeForm.subscribe[0].acf.giftingproducts.product3,
        ],
        standardBoxes: [
          subscribeForm.subscribe[0].acf.standardproducts.product1,
          subscribeForm.subscribe[0].acf.standardproducts.product2,
          subscribeForm.subscribe[0].acf.standardproducts.product3,
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeForm.subscribe.length]);
  return pages.isLoaded ? (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route
              path='/'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/more-fun-family-time'
              element={<MoreFunFamilyTime />}
            />
            <Route
              path='/birthday'
              exact
              element={
                <Birthday form={form} setForm={setForm} birthday={birthday} />
              }
            />
            <Route
              path='/homeschooling'
              exact
              element={
                <Homeschooling
                  form={form}
                  setForm={setForm}
                  homepage={homeschooling}
                />
              }
            />
            <Route
              path='/covid19'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />{' '}
            <Route
              path='/howthingsfly/justfordad'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route path='/my-account' exact element={<MyAcc />} />
            <Route
              path='/covid19-offer'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/fathers-day-body-hair-contest'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/fathers-day-cooper-kits-pinterest'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/gift-a-year-of-cooper-kits'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/gift-card'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/how-it-works'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/howthingsfly'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/cooperkit'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/top-fathers-day-gifts'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/yearly-subscription'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/justfordad'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/medallion-collection'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/medallion-display'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/justfordad'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/quarterly-subscription'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/peek-inside'
              exact
              element={<PeekInside peekinside={peekinside} />}
            />
            <Route
              path='/homeschooling/peek-inside'
              exact
              element={<PeekInside peekinside={peekinside} />}
            />
            <Route
              path='/samplekit'
              exact
              element={<PeekInside peekinside={peekinside} />}
            />
            <Route
              path='/subscribe'
              exact
              element={
                <Form
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/homeschooling/subscribe'
              exact
              element={
                <Form2
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/birthday/subscribe'
              exact
              element={
                <Form3
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/getit'
              exact
              element={
                <Form form={form} setForm={setForm} signupForm={singup} />
              }
            />
            <Route
              path='/terms-of-use'
              exact
              element={<Terms terms={termsOfUse} />}
            />
            <Route
              path='/homeschooling/terms-of-use'
              exact
              element={<Terms terms={termsOfUse} />}
            />
            <Route path='/terms' exact element={<Terms terms={termsOfUse} />} />
            <Route
              path='/homeschooling/terms'
              exact
              element={<Terms terms={termsOfUse} />}
            />
            <Route
              path='/customer-service'
              exact
              element={<CostumerService costumer={costumerServices} />}
            />
            <Route
              path='/homeschooling/customer-service'
              exact
              element={<CostumerService costumer={costumerServices} />}
            />
            <Route
              path='/origin-story'
              exact
              element={<About aboutUs={aboutUs} />}
            />
            <Route
              path='/about-us'
              exact
              element={<About aboutUs={aboutUs} />}
            />
            <Route
              path='/homeschooling/about-us'
              exact
              element={<About aboutUs={aboutUs} />}
            />
            <Route path='/about' exact element={<About aboutUs={aboutUs} />} />
            <Route
              path='/homeschooling/about'
              exact
              element={<About aboutUs={aboutUs} />}
            />
            <Route
              path='/contact-us'
              exact
              element={<Contact form={contactForm} />}
            />
            <Route
              path='/homeschooling/contact-us'
              exact
              element={<Contact form={contactForm} />}
            />
            <Route
              path='/checkout/thank-you'
              exact
              element={<Thankyou body={quarterly} />}
            />
            <Route path='/faq' exact element={<Faqs faqs={homepage} />} />
            <Route
              path='/homeschooling/faq'
              exact
              element={<Faqs faqs={homepage} />}
            />
            <Route
              path='/privacy-policy'
              exact
              element={<Privacy privacy={privacyPolicy} />}
            />
            <Route
              path='/homeschooling/privacy-policy'
              exact
              element={<Privacy privacy={privacyPolicy} />}
            />
            <Route
              path='/affiliate-program'
              exact
              element={<AffiliateProgram affiliateProgram={affiliateProgram} />}
            />
            <Route path='/canada' exact element={<Canada />} />
            <Route
              path='/contact-us-thank-you'
              exact
              element={<ContactSuccess form={pages.pages} />}
            />
            <Route
              path='/newsletter-thank-you'
              exact
              element={<NewsletterThankyou />}
            />
            <Route
              path='/checkout/annual-order-received'
              exact
              element={<AnnualOrderReceived />}
            />
            <Route
              path='/checkout/order-received'
              exact
              element={<OrderReceived />}
            />
            <Route
              path='/checkout/we-will-try-to-process-your-card-again'
              exact
              element={<TryToProcess />}
            />
            <Route
              path='/checkout/quarterly-order-received'
              exact
              element={<QuarterlyOrderReceived />}
            />
            <Route
              path='/thank-you'
              exact
              element={<QuarterlyOrderReceived />}
            />
            <Route
              path='/self-checkout-thank-you'
              exact
              element={<ThankYou />}
            />
            <Route
              path='/select-your-country'
              exact
              element={<SelectedCountry />}
            />
            <Route
              path='/checkout/complete-annual-order'
              exact
              element={<AnnualOrder body={yearlyPrepaid} />}
            />
            <Route
              path='/checkout/complete-quarterly-order'
              exactf
              element={<QuarterlyThankyou body={quarterly} />}
            />
            <Route
              path='/checkout/complete-yok-order'
              exact
              element={<YokOrder body={yearlyGift} />}
            />
            <Route
              path='/checkout/complete-standard-yearly-order'
              exact
              element={<StandardYearly />}
            />
            <Route
              path='/complete-your-order'
              exact
              element={<Thankyou body={quarterly} />}
            />
            <Route
              path='/Gift-it'
              exact
              element={
                <Getgift
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/birthday/Gift-it'
              exact
              element={
                <Getgift
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/homeschooling/Gift-it'
              exact
              element={
                <GiftBoxes
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/Get-it'
              exact
              element={
                <GetIt
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/birthday/Get-it'
              exact
              element={
                <GetIt
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/homeschooling/Get-it'
              exact
              element={
                <StandardBoxes
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route path='/asia-australia' exact element={<AsiaAustralia />} />
            <Route path='/europe-1' exact element={<Europe1 />} />
            <Route path='/europe-2' exact element={<Europe2 />} />
            <Route path='/international-3' exact element={<International3 />} />
            <Route path='/latin-america' exact element={<LatinAmerica />} />
            <Route
              path='/catch'
              exact
              element={
                <HomePage form={form} setForm={setForm} homepage={homepage} />
              }
            />
            <Route
              path='/major-eu-asia-latin'
              exact
              element={<MajorEuAsiaLatin />}
            />
            <Route path='/mexico' exact element={<Mexico />} />
            <Route
              path='/middle-east-and-africa'
              exact
              element={<MiddleEastAndAfrica />}
            />
            <Route path='/international-2' exact element={<International2 />} />
            <Route path='/uk' exact element={<Uk />} />
            <Route path='/western-europe' exact element={<WesternEurope />} />
            <Route
              path='/friends-and-family'
              exact
              element={<FriendsAndFamily friendsAndFamily={friendsAndFamily} />}
            />
            {/* <Route
              path='/live-events-cart-orderforms-products'
              exact
              element={<LiveEvents />}
            /> */}
            <Route path='/quiet-on-the-set' exact element={<QuietOnTheSet />} />
            <Route path='/test' exact element={<Test />} />
            <Route
              path='/fathers-day-cooper-kits-2018'
              exact
              element={<FathersDay2018 />}
            />
            <Route
              path='/checkout/complete-order-with-bonus-card'
              exact
              element={<Bonus />}
            />
            <Route
              path='/Gift-it'
              exact
              element={
                <Getgift
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/homeschooling/Gift-it'
              exact
              element={
                <Getgift
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/Get-it'
              exact
              element={
                <GetIt
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route
              path='/homeschooling/adventures'
              exact
              element={<Adventure />}
            />
            <Route path='/adventures' exact element={<Adventure />} />
            <Route
              path='/homeschooling/Get-it'
              exact
              element={
                <GetIt
                  form={form}
                  setForm={setForm}
                  signupForm={singup}
                  sub={subscribe.acf}
                />
              }
            />
            <Route path='/popbox' exact element={<Popbox />} />
            <Route path='/press' exact element={<Press />} />
            <Route path='*' exact element={<Page404 />} />
            <Route path='/music-sound-odyssey' exact element={<Music />} />
            <Route
              path='/live-events-homeschool-cart'
              exact
              element={<FullLandingPage landingPage={landingPage} />}
            />
            <Route
              path='/live-events-cart'
              exact
              element={<Conference LiveEvents={LiveEvents} />}
            />
            <Route
              path='/preschool-fathers-day'
              exact
              element={<PreSchools />}
            />
          </Routes>
        </ScrollToTop>
        {/* <Footer /> */}
      </Router>
    </>
  ) : (
    <Loader />
  );
}

export default App;
