import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Components/Shared/Footer';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Logo404 from '../Components/Shared/Assets/404.png';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Page404() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <Navbar />
      <div className='main-404-page'>
        <img src={Logo404} alt='404' />
        <h1>This page was not found.</h1>
        <Link
          to={
            window.location.pathname.includes('homeschooling')
              ? '/homeschooling'
              : '/'
          }
        >
          Go home
        </Link>
      </div>

      {/* <Footer /> */}
    </>
  );
}

export default Page404;
