import React, { useState } from 'react';
import Loader from '../Shared/Loader';
import { BiChevronLeft } from 'react-icons/bi';
import { dataLayer } from '../../dataLayer';

function Medallion({ form, setForm, page, setPage, medallion }) {
  const [loader, setLoader] = useState(false);
  const getSwitchBoxType = (boxType) => {
    var type = '';
    boxType.filter((e) => {
      if (e.isChecked) type = e.type;
    });
    return type;
  };

  function giftitYes() {
    // Your sign-up logic here

    // Push data to dataLayer
    dataLayer.push({
      event: 'get_it_step_2_upsell',
      selection_type: 'yes',
      upsell_type: 'Medallion',
    });
  }

  function giftitNo() {
    // Your sign-up logic here

    // Push data to dataLayer
    dataLayer.push({
      event: 'get_it_step_2_upsell',
      selection_type: 'no',
      upsell_type: 'Medallion',
    });
  }
  return !loader ? (
    <>
      <div className='medallion-container'>
        <div
          className='medallion-image'
          style={{ backgroundImage: `url(${medallion.medallion.image})` }}
        ></div>
        <div className='medallion-text'>
          <div
            dangerouslySetInnerHTML={{ __html: medallion.medallion.header }}
          />
          {/* <p>
            Each Cooper Kit includes 1 collectible medallion, which are powerful
            objects of awe for kids. Parents of multiple children often upgrade
            to get additional medallions. $4 for each upgrade.
          </p> */}
          <div
            dangerouslySetInnerHTML={{ __html: medallion.medallion.paragraph }}
          />
          <button
            className='confirm-button hoverable'
            onClick={() => {
              // gtag('event', "GiftitYes", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              giftitYes();
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren === 1) {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case 'Quarterly Gift':
                      localStorage.setItem(
                        'SKU',
                        'quarterly-subscription-family'
                      );
                      window.location.href =
                        'https://aw135.infusionsoft.app/app/orderForms/2022-US-Quarterly-Tshirts-Subscription';
                      break;
                    case 'Standard Yearly':
                      localStorage.setItem('SKU', 'annual-billed-quarterly');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Tshirts-Subscription';
                      break;
                    case 'One Year Gift':
                      localStorage.setItem('SKU', 'yearly-subscription');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/yok-tshirt';

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case 'Quarterly':
                      localStorage.setItem(
                        'SKU',
                        'quarterly-subscription-family'
                      );
                      window.location.href =
                        'https://aw135.infusionsoft.app/app/orderForms/2022-US-Quarterly-Tshirts-Subscription';
                      break;
                    case 'Standard Yearly':
                      localStorage.setItem('SKU', 'annual-billed-quarterly');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Tshirts-Subscription';
                      break;
                    case 'Prepay Yearly':
                      localStorage.setItem('SKU', 'yearly-subscription');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-TShirt-Subscription';

                      break;
                    default:
                      break;
                  }
                }
              } else {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case 'Quarterly Gift':
                      localStorage.setItem(
                        'SKU',
                        'quarterly-subscription-family'
                      );
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Medallion-Subscription';
                      break;
                    case 'Standard Yearly':
                      localStorage.setItem('SKU', 'annual-billed-quarterly');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Medallion-Subscription';
                      break;
                    case 'One Year Gift':
                      localStorage.setItem('SKU', 'yearly-subscription');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/yok-medallion';

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case 'Quarterly':
                      localStorage.setItem(
                        'SKU',
                        'quarterly-subscription-family'
                      );
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Medallion-Subscription';
                      break;
                    case 'Standard Yearly':
                      localStorage.setItem('SKU', 'annual-billed-quarterly');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Medallion-Subscription';
                      break;
                    case 'Prepay Yearly':
                      localStorage.setItem('SKU', 'yearly-subscription');

                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Medallion-Subscription';

                      break;
                    default:
                      break;
                  }
                }
              }

              // window.location.href =
              //   "https://aw135.infusionsoft.com/app/form/quarterly-point-of-sale2" +
              //   params;

              localStorage.setItem('name', form.name);
              localStorage.setItem('lastName', form.lastName);
              if (form.gifting === true) {
                localStorage.setItem('isGift', true);
              } else {
                localStorage.setItem('isGift', false);
              }
              localStorage.setItem('numberOfChildren', form.numberOfChildren);
              localStorage.setItem('email', form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  'giftBoxType',
                  form.giftingBoxes.filter((e) => e.isChecked)[0]['type']
                );
              } else {
                localStorage.setItem(
                  'houseHoldType',
                  form.standardBoxes.filter((e) => e.isChecked)[0]['type']
                );
              }
              localStorage.setItem('medallion', form.medallionUpsell);
              setLoader(true);
            }}
          >
            Yes, please!
          </button>
          {loader ? <Loader /> : ''}
          <button
            className='hoverable'
            onClick={() => {
              // gtag('event', "GiftitNo", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              giftitNo();
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren === 1) {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case 'Quarterly Gift':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription';
                      break;
                    case 'Standard Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription';
                      break;
                    case 'One Year Gift':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/yok';

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case 'Quarterly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription';
                      break;
                    case 'Standard Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription';
                      break;
                    case 'Prepay Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription';

                      break;
                    default:
                      break;
                  }
                }
              } else {
                if (form.gifting === true) {
                  switch (getSwitchBoxType(form.giftingBoxes)) {
                    case 'Quarterly Gift':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription';
                      break;
                    case 'Standard Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription';
                      break;
                    case 'One Year Gift':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/yok';

                      break;
                    default:
                      break;
                  }
                } else {
                  switch (getSwitchBoxType(form.standardBoxes)) {
                    case 'Quarterly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription';
                      break;
                    case 'Standard Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription';
                      break;
                    case 'Prepay Yearly':
                      window.location.href =
                        'https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription';

                      break;
                    default:
                      break;
                  }
                }
              }
              localStorage.setItem('name', form.name);
              localStorage.setItem('lastName', form.lastName);
              if (form.gifting === true) {
                localStorage.setItem('isGift', true);
              } else {
                localStorage.setItem('isGift', false);
              }
              localStorage.setItem('numberOfChildren', form.numberOfChildren);
              localStorage.setItem('email', form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  'giftBoxType',
                  form.giftingBoxes.filter((e) => e.isChecked)[0]['type']
                );
              } else {
                localStorage.setItem(
                  'houseHoldType',
                  form.standardBoxes.filter((e) => e.isChecked)[0]['type']
                );
              }
              localStorage.setItem('medallion', form.medallionUpsell);
              setLoader(true);
            }}
          >
            No, thanks
          </button>
        </div>
      </div>
      <div className='upsell-container'>
        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className='go-back-box'
        >
          <BiChevronLeft />
          Go back
        </button>
      </div>
    </>
  ) : (
    <Loader />
  );
}

export default Medallion;
