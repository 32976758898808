import React, { useState } from 'react';
import './Styles/footer.css';
import Logo from '../Shared/Assets/Homepage/logo_footer.png';
import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import Fb from '../Shared/Assets/Homepage/fb.png';
import Ig from '../Shared/Assets/Homepage/ig.png';
import Tw from '../Shared/Assets/Homepage/tw.png';
import Yt from '../Shared/Assets/Homepage/yt.png';
import Pi from '../Shared/Assets/Homepage/pint.png';
import disableScroll from 'disable-scroll';

import { CgCloseO } from 'react-icons/cg';
import FooterForm from './FooterForm';
import FooterThankyou from './FooterThankyou';

function Footer() {
  const [mailError, setMailError] = useState(true);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [popup, setPopup] = useState(false);
  const [page, setPage] = useState(0);

  const [agreed, setAgreed] = useState(false);
  const [iAm, setIAm] = useState({
    iAm: [
      {
        id: 1,
        title: 'dad',
        isChecked: true,
      },
      {
        id: 2,
        title: 'mom',
        isChecked: false,
      },
      {
        id: 3,
        title: 'grandparent',
        isChecked: false,
      },
      {
        id: 4,
        title: 'other',
        isChecked: false,
      },
    ],
  });

  !popup ? disableScroll.off() : disableScroll.on();
  const validateEmail = (email) => {
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);

    if (emailRegex.test(email) === false) {
      setMailError(false);
    } else {
      setMailError(true);
    }
  };
  const disableBtn = () => {
    if (!mailError) {
      return true;
    } else if (email.length < 1) {
      return true;
    } else {
      return false;
    }
  };
  var date = new Date();
  var year = date.getFullYear();

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      //do something
      setPopup(false);
    }
  });
  const footerForm = (page) => {
    switch (page) {
      case 0:
        return <FooterForm email={email} page={page} setPage={setPage} />;
      case 1:
        return <FooterThankyou />;
    }
  };

  return (
    <div
      className='footer-main'
      style={
        window.location.pathname === '/subscribe'
          ? { display: 'none' }
          : { display: 'flex' }
      }
    >
      <div className='footer1'>
        <div className='logo-subscribe'>
          <img src={Logo} alt='logo' />
          <p>
            Get friendly tips, free DIYs, deals and advice made for busy
            grown-ups who love their kids.{' '}
          </p>
          <h6>Subscribe to our newsletter</h6>

          <div className='newsletter-footer'>
            <input
              type='email'
              placeholder='Email address'
              className='email-footer'
              name='email'
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
            />
            <button
              className='submit-footer'
              onClick={() => {
                // if (mailError === true && email.length > 1) {
                //   setMessage(true);
                // } else {
                //   setMessage(false);
                // }

                setPopup(true);
              }}
              disabled={
                disableBtn()
                // !mailError
              }
            >
              <BiChevronRight size={25} />
            </button>

            <div
              className='footer-popup-overlay'
              style={popup ? { display: 'flex' } : { display: 'none' }}
            >
              <div className='footer-popup'>
                <CgCloseO
                  className='footer-popup-close-icon hoverable'
                  size={27}
                  onClick={() => {
                    setPopup(false);
                  }}
                />
                <div className='popup-form'>{footerForm(page)}</div>
              </div>
            </div>
          </div>
          {!mailError ? (
            <p
              style={{
                color: 'red',
                fontSize: '12px',
                textAlign: 'left',
                width: '300px',
                position: 'absolute',
                bottom: '-25px',
              }}
            >
              Enter a valid email!
            </p>
          ) : null}
          {message === true && mailError === true ? (
            <p
              style={{
                color: '#5c2945',
                fontSize: '13px',
                textAlign: 'left',
                // width: "300px",
                position: 'absolute',
              }}
              className='thankyou-message'
            >
              Thank you for subscribing to our newsletter! 😊
            </p>
          ) : (
            ''
          )}
        </div>
        <div className='footer-links'>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/subscribe'
                : '/subscribe'
            }
            className='hoverable'
          >
            <h1>Get Started</h1>
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/subscribe'
                : '/subscribe'
            }
            className='hoverable'
          >
            Get the Cooper Kit
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling'
                : '/homeschooling'
            }
            className='hoverable'
          >
            Homeschool Program
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/peek-inside'
                : '/peek-inside'
            }
            className='hoverable'
          >
            See Inside a Kit
          </Link>

          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/about-us'
                : '/about-us'
            }
            className='hoverable'
          >
            About Us
          </Link>
        </div>
        <div className='footer-links'>
          <h1>Reach Out</h1>
          {/* <Link to="/" className="hoverable">
            Help
          </Link> */}
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/faq'
                : '/faq'
            }
            className='hoverable'
          >
            FAQs
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/contact-us'
                : '/contact-us'
            }
            className='hoverable'
          >
            Contact Us
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/adventures'
                : '/adventures'
            }
            className='hoverable'
          >
            See Box Adventures
          </Link>
        </div>
        <div className='footer-links'>
          <h1>Follow Us</h1>
          <div className='social'>
            <div>
              <img src={Fb} alt='fb' />
            </div>
            <a
              href='https://www.facebook.com/CooperAndKid'
              target='_blank'
              rel='noreferrer'
              className='hoverable'
            >
              Facebook
            </a>
          </div>
          {/* <div className='social'>
            <div>
              <img src={Tw} alt='tw' />
            </div>
            <a
              href='https://twitter.com/CooperandKid'
              target='_blank'
              rel='noreferrer'
              className='hoverable'
            >
              Twitter
            </a>
          </div> */}
          <div className='social'>
            <div>
              <img src={Ig} alt='ig' />
            </div>
            <a
              href='https://www.instagram.com/cooperandkid/'
              target='_blank'
              rel='noreferrer'
              className='hoverable'
            >
              Instagram
            </a>
          </div>
          <div className='social'>
            <div>
              <img src={Yt} alt='ig' />
            </div>
            <a
              href='https://www.youtube.com/user/cooperandkid'
              target='_blank'
              rel='noreferrer'
              className='hoverable'
            >
              Youtube
            </a>
          </div>
          <div className='social'>
            <div>
              <img src={Pi} alt='ig' />
            </div>
            <a
              href='https://www.pinterest.com/cooperandkid/_created/'
              target='_blank'
              rel='noreferrer'
              className='hoverable'
            >
              Pinterest
            </a>
          </div>
        </div>
        <div className='footer-links'>
          <h1>More</h1>
          <a
            href='https://cooperandkid.customerhub.net/'
            target='_blank'
            rel='noreferrer'
            className='hoverable'
          >
            Login
          </a>
        </div>
      </div>
      <div className='footer2'>
        <p>Copyright © {year}. Cooper&Kid. All rights reserved.</p>
        <div className='terms-privacy'>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/terms-of-use'
                : '/terms-of-use'
            }
            className='hoverable'
          >
            Terms of Use
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/customer-service'
                : '/customer-service'
            }
            className='hoverable'
          >
            Customer Service
          </Link>
          <Link
            to={
              window.location.pathname.includes('homeschooling')
                ? '/homeschooling/privacy-policy'
                : '/privacy-policy'
            }
            className='hoverable'
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <iframe
        name='footerEmail'
        style={{ display: 'none', width: '0', height: '0' }}
      ></iframe>
    </div>
  );
}

export default Footer;
