import React from "react";
function SixthSection({ about }) {
  return (
    <div className="about-sixthSection">
      <h1>{about.meetthefounderheading}</h1>
      <img src={about.founderimg} alt="Nichole profile picture!" />
      <div dangerouslySetInnerHTML={{ __html: about.founderparagraph }} />
    </div>
  );
}

export default SixthSection;
