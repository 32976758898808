import React from 'react';
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
// import ReactPixel from "react-facebook-pixel";
// import { BiChevronLeft } from 'react-icons/bi';
import Navbar from '../Components/Shared/Navbar/Navbar';
import Footer from '../Components/Shared/Footer';
import FormGetit from '../Components/StepsGetit/Form';
// import { AiOutlineInfoCircle } from 'react-icons/ai';

import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: "Get a Free Kid's Smart Watch with your first Cooper Kit!",
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function GetIt({ form, setForm, page, setPage, sub, signupForm }) {
  const getSwitchBoxType = (boxType) => {
    var type = '';
    boxType.filter((e) => {
      if (e.isChecked) type = e.type;
    });
    return type;
  };
  //   const checked = (id) => {
  //     const newCards = form.standardBoxes.map((item) => {
  //       if (item.id === id) {
  //         return {
  //           ...item,
  //           isChecked: true,
  //         };
  //       } else {
  //         return {
  //           ...item,
  //           isChecked: false,
  //         };
  //       }
  //     });
  //     setForm({
  //       ...form,
  //       standardBoxes: newCards,
  //     });
  //   };
  // me filtru se cili box osht checked
  var counter = 0;
  if (form.standardBoxes) {
    var counter = 0;
    for (var i = 0; i < form.standardBoxes.length; i++) {
      if (form.standardBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }
  return form.standardBoxes ? (
    <>
      <Helmet>
        <title>Cooper and Kid: Get Your Cooper Kit</title>
        <meta name='title' content='Cooper and Kid: Get Your Cooper Kit' />
        <meta
          name='description'
          content='Send as a gift or get it for your family. Customize your Cooper Kit here! '
        />
      </Helmet>
      <OfferBanner data={bannerData} />
      <Navbar />
      <FormGetit
        form={form}
        setForm={setForm}
        signupForm={signupForm}
        sub={sub}
      />
      <Footer />
    </>
  ) : null;
}
export default GetIt;
