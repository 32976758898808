export const NewsletterForm = () => {
  return (
    <>
      <form
        accept-charset='UTF-8'
        action='https://aw135.infusionsoft.com/app/form/process/6533a5664976244ce785bf420fff6633'
        className='infusion-form'
        id='inf_form_6533a5664976244ce785bf420fff6633'
        method='POST'
      >
        <input
          name='inf_form_xid'
          type='hidden'
          value='6533a5664976244ce785bf420fff6633'
        />
        <input
          name='inf_form_name'
          type='hidden'
          value='Get 1st Kit Free Form'
        />
        <input
          name='infusionsoft_version'
          type='hidden'
          value='1.70.0.591171'
        />
        <div className='infusion-field'>
          <input
            className='as_input'
            id='inf_field_Email'
            name='inf_field_Email'
            placeholder='Email Address *'
            type='email'
          />
        </div>
        <div>
          <div>&nbsp;</div>
        </div>
        <div className='infusion-submit'>
          <button
            className='infusion-recaptcha as_button'
            id='recaptcha_6533a5664976244ce785bf420fff6633'
            type='submit'
          >
            Subscribe
          </button>
        </div>
      </form>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" src="https://aw135.infusionsoft.app/app/webTracking/getTrackingCode"></script>
            <script type="text/javascript" src="https://aw135.infusionsoft.com/resources/external/recaptcha/production/recaptcha.js?b=1.70.0.591171-hf-202309181141"></script>
            <script src="https://www.google.com/recaptcha/api.js?onload=onloadInfusionRecaptchaCallback&render=explicit" async="async" defer="defer"></script>
            <script type="text/javascript" src="https://aw135.infusionsoft.com/app/timezone/timezoneInputJs?xid=6533a5664976244ce785bf420fff6633"></script>
            <script type="text/javascript" src="https://aw135.infusionsoft.com/js/jquery/jquery-3.3.1.js"></script>
            <script type="text/javascript" src="https://aw135.infusionsoft.app/app/webform/overwriteRefererJs"></script>
            `,
        }}
      />
    </>
  );
};
