import React, { useState } from "react";
import Loader from "../Shared/Loader";
import { BiChevronLeft } from "react-icons/bi";
import ScrollToTop from "../Shared/ScrollToTop";
import { dataLayer } from "../../dataLayer";

function MedallionCopy({ form, setForm, page, setPage, medallion }) {
  const [loader, setLoader] = useState(false);
  const getSwitchBoxType = (boxType) => {
    var type = "";
    boxType.filter((e) => {
      if (e.isChecked) type = e.type;
    });
    return type;
  };

  var params =
    "?name=" +
    form.name +
    "&isGift=" +
    form.gifting +
    "&numberOfChildren=" +
    form.numberOfChildren +
    "&email=" +
    form.email +
    "&medallions=" +
    ( form.numberOfChildren - 1)+
    "&surprise=" +
    form.surprise +
    "&receive=" +
    form.receive;

  const noOrderLinksGift = (type) => {
    // console.log("bbbbbbbbb");

    // console.log(type, "SSSSSSSSSSSSSSS");

    switch (type) {
 
      case "Quarterly Gift":
        localStorage.setItem("SKU", "quarterly-subscription-family");
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-month" +
              params
            );

          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Standard Yearly":
        localStorage.setItem("SKU", "annual-billed-quarterly");

        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-1-month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "One Year Gift":
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-1-Month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-2-Months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-3-Months" +
              params
            );
          default:
            return "";
        }
    }
  };

  const noOrderLinks = (type) => {
    // console.log("bbbbbbbbb");

    // console.log(type, "SSSSSSSSSSSSSSS");
    // eslint-disable-next-line default-case
    switch (type) {
      case "Quarterly":
        localStorage.setItem("SKU", "quarterly-subscription-family");
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-month" +
              params
            );

          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Standard Yearly":
        localStorage.setItem("SKU", "annual-billed-quarterly");

        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-1-month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Prepay Yearly":
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-Month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-2-Months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Every-3-Months" +
              params
            );
          default:
            return "";
        }
    }
  };

  const orderLinks = (type) => {
    // console.log("bbbbbbbbb");

    // console.log(type, "SSSSSSSSSSSSSSS");
    // eslint-disable-next-line default-case
    switch (type) {
      case "Quarterly":
        localStorage.setItem("SKU", "quarterly-subscription-family");
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-1-month " +
              params
            );

          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Standard Yearly":
        localStorage.setItem("SKU", "annual-billed-quarterly");
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-1-month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-2-months " +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Prepay Yearly":
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Medallions-Every-1-Month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Medallions-Every-2-Months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Super-Saver-Prepay-Plan-Medallions-Every-3-Months" +
              params
            );
          default:
            return "";
        }
    }
  };
  const orderLinksGift = (type) => {
    // console.log("bbbbbbbbb");

    // console.log(type, "SSSSSSSSSSSSSSS");
    // eslint-disable-next-line default-case
    switch (type) {
      case "Quarterly Gift":
        localStorage.setItem("SKU", "quarterly-subscription-family");
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-1-month " +
              params
            );

          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-2-months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Flexi-Plan-Medallions-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "Standard Yearly":
        localStorage.setItem("SKU", "annual-billed-quarterly");

        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-1-month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-2-months " +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-Standard-4-Kit-Plan-Medallions-Every-3-months" +
              params
            );
          default:
            return "";
        }
      case "One Year Gift":
        switch (form.receive) {
          case 1:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-Medallion-1-Month" +
              params
            );
          case 2:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-Medallions-2-Months" +
              params
            );
          case 3:
            return (
              "https://aw135.infusionsoft.com/app/orderForms/Homeschool-One-Time-4-Kit-Package-Medallions-3-Months " +
              params
            );
          default:
            return "";
        }
    }
  };

  function homeschoolingSignUpYes() {
    // Your sign-up logic here
    
    // Push data to dataLayer
    dataLayer.push({'event' : 'homeschooling_step_6_upsell',
    'selection_type' : 'yes',
    'upsell_type' : 'Medallion'})
    
  }

  function homeschoolingSignUpNo() {
    // Your sign-up logic here
    
    // Push data to dataLayer
    dataLayer.push({'event' : 'homeschooling_step_6_upsell',
    'selection_type' : 'no',
    'upsell_type' : 'Medallion'})
   
    
  }

  return !loader ? (
    <ScrollToTop>
      <div className="medallion-container">

        <div
          className="medallion-image"
          style={{ backgroundImage: `url(${medallion.medallion.image})` }}
        ></div>
        <div className="medallion-text">
          <div
            dangerouslySetInnerHTML={{ __html: medallion.medallion.header }}
          />
          {/* <p>
            Each Cooper Kit includes 1 collectible medallion, which are powerful
            objects of awe for kids. Parents of multiple children often upgrade
            to get additional medallions. $4 for each upgrade.
          </p> */}
          <div
            dangerouslySetInnerHTML={{ __html: medallion.medallion.paragraph }}
          />
          <button
            className="confirm-button hoverable"
            onClick={() => {
              // gtag('event', "stepSixHomeshoolingMedallionYes", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              homeschoolingSignUpYes()
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren > 1 && form.gifting === false) {
                window.location.href = orderLinks(
                  getSwitchBoxType(form.standardBoxes)
                );
              } else {
                window.location.href = orderLinksGift(
                  getSwitchBoxType(form.giftingBoxes)
                );
              }

          

              localStorage.setItem("name", form.name);
              localStorage.setItem("lastName", form.lastName);
              localStorage.setItem("surprise", form.surprise);
              if (form.gifting === true) {
                localStorage.setItem("isGift", true);
              } else {
                localStorage.setItem("isGift", false);
              }
              localStorage.setItem("numberOfChildren", form.numberOfChildren);
              localStorage.setItem("email", form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  "giftBoxType",
                  form.giftingBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              } else {
                localStorage.setItem(
                  "houseHoldType",
                  form.standardBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              }

              localStorage.setItem("medallion", form.medallionUpsell);
              setLoader(true);
            }}
          >
            Yes, please!
          </button>
          {loader ? <Loader /> : ""}
          <button
            className="hoverable"
            onClick={() => {
              // gtag('event', "stepSixHomeshoolingMedallionNo", {
              //   event_category: "signupFlow",
              //   event_label: "submit",
              // });
              homeschoolingSignUpNo()
              setForm({ ...form, medallionUpsell: true });
              if (form.numberOfChildren > 1 && form.gifting === false) {
                window.location.href = noOrderLinks(
                  getSwitchBoxType(form.standardBoxes)
                );
              } else {
                window.location.href = noOrderLinksGift(
                  getSwitchBoxType(form.giftingBoxes)
                );
              }
              //   if (form.numberOfChildren === 1) {
              //     if (form.gifting === true) {
              //       switch (getSwitchBoxType(form.giftingBoxes)) {
              //         case "Quarterly Gift":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
              //             params;
              //           break;
              //         case "Standard Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
              //             params;
              //           break;
              //         case "One Year Gift":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/yok" +
              //             params;

              //           break;
              //         default:
              //           break;
              //       }
              //     } else {
              //       switch (getSwitchBoxType(form.standardBoxes)) {
              //         case "Quarterly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
              //             params;
              //           break;
              //         case "Standard Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
              //             params;
              //           break;
              //         case "Prepay Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription" +
              //             params;

              //           break;
              //         default:
              //           break;
              //       }
              //     }
              //   } else {
              //     if (form.gifting === true) {
              //       switch (getSwitchBoxType(form.giftingBoxes)) {
              //         case "Quarterly Gift":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
              //             params;
              //           break;
              //         case "Standard Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
              //             params;
              //           break;
              //         case "One Year Gift":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/yok" +
              //             params;

              //           break;
              //         default:
              //           break;
              //       }
              //     } else {
              //       switch (getSwitchBoxType(form.standardBoxes)) {
              //         case "Quarterly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription" +
              //             params;
              //           break;
              //         case "Standard Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription" +
              //             params;
              //           break;
              //         case "Prepay Yearly":
              //           window.location.href =
              //             "https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription" +
              //             params;

              //           break;
              //         default:
              //           break;
              //       }
              //     }
              //   }

              //   // window.location.href =
              //   //   "https://aw135.infusionsoft.com/app/form/quarterly-point-of-sale2" +
              //   //   params;

              //   localStorage.setItem("name", form.name);
              //   localStorage.setItem("lastName", form.lastName);
              //   if (form.gifting === true) {
              //     localStorage.setItem("isGift", true);
              //   } else {
              //     localStorage.setItem("isGift", false);
              //   }
              //   localStorage.setItem("numberOfChildren", form.numberOfChildren);
              //   localStorage.setItem("email", form.email);

              //   if (form.gifting === true) {
              //     localStorage.setItem(
              //       "giftBoxType",
              //       form.giftingBoxes.filter((e) => e.isChecked)[0]["type"]
              //     );
              //   } else {
              //     localStorage.setItem(
              //       "houseHoldType",
              //       form.standardBoxes.filter((e) => e.isChecked)[0]["type"]
              //     );
              //   }
              //   localStorage.setItem("medallion", form.medallionUpsell);
              //   setLoader(true);
              // }}

              localStorage.setItem("name", form.name);
              localStorage.setItem("lastName", form.lastName);
              if (form.gifting === true) {
                localStorage.setItem("isGift", true);
              } else {
                localStorage.setItem("isGift", false);
              }
              localStorage.setItem("numberOfChildren", form.numberOfChildren);
              localStorage.setItem("email", form.email);

              if (form.gifting === true) {
                localStorage.setItem(
                  "giftBoxType",
                  form.giftingBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              } else {
                localStorage.setItem(
                  "houseHoldType",
                  form.standardBoxes.filter((e) => e.isChecked)[0]["type"]
                );
              }
              localStorage.setItem("medallion", form.medallionUpsell);
              setLoader(true);
            }}
          >
            No, thanks
          </button>
        </div>
      </div>
      <div className="upsell-container">
        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className="go-back-box-plans-home"
        >
          <BiChevronLeft />
          Go back
        </button>
      </div>
    </ScrollToTop>
  ) : (
    <Loader />
  );
}

export default MedallionCopy;
