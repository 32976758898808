import React, { useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import ScrollToTop from '../Shared/ScrollToTop';
// import gtag from 'ga-gtag';
import { dataLayer } from '../../dataLayer';
function Fullname({ form, setForm, page, setPage, step3 }) {
  const [message, setMessage] = useState(false);
  const validateUsername = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-,-]+$");

    if (!strongRegex.test(username)) {
      setForm({ ...form, nameValid: false, name: username });
    } else {
      setForm({ ...form, nameValid: true, name: username });
    }
  };
  const validateLastname = (username) => {
    const strongRegex = new RegExp("^[a-z A-Z'-,-]+$");

    if (!strongRegex.test(username)) {
      setForm({ ...form, lastNameValid: false, lastName: username });
    } else {
      setForm({ ...form, lastNameValid: true, lastName: username });
    }
  };

  // const changeHandler = (e) => {

  //     setForm({ ...form, [e.target.name]: e.target.value });

  // };

  if (window.location.pathname.includes('homeschooling')) {
    localStorage.setItem('homeschool', true);
  }
  if (window.location.pathname.includes('birthday')) {
    localStorage.setItem('birthday', true);
  }
  // console.log("child", form.date)

  const n = form.numberOfChildren;

  function handleSignUpName() {
    // Your sign-up logic here

    // Push data to dataLayer
    if (
      form.lastNameValid === true &&
      form.nameValid === true &&
      form.name.length > 0 &&
      form.lastName.length > 0
    ) {
      dataLayer.push({
        event: 'subscription_step_3',
        first_name: form.name,
        Last_name: form.lastName,
      });
    }
  }

  return (
    <ScrollToTop>
      <div className='fullname-main'>
        <h3>{step3.header}</h3>
        <h5>{step3.subheader}</h5>
        <div className='main-input-cotainer'>
          <div className='input-container3'>
            <input
              type='text'
              onChange={(e) => {
                validateUsername(e.target.value);

                setMessage(false);
              }}
              value={form.name}
              required
              name='firstName'
            />
            <label className={form.name && 'filled'}>First Name(s)</label>
          </div>
          <div className='middle-space'></div>
          <div className=' input-container4'>
            <input
              type='text'
              onChange={(e) => {
                validateLastname(e.target.value);
                setMessage(false);
              }}
              value={form.lastName}
              required
              name='lastName'
            />
            <label className={form.lastName && 'filled'}>Last Name(s)</label>
          </div>
        </div>
        <div
          className='errorDiv'
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              textAlign: 'left',
              width: '50%',
              paddingLeft: '5px',
            }}
          >
            {form.nameValid
              ? ''
              : 'Enter a valid name! Name cannot have special characters or numbers.'}
          </p>

          <p
            style={{
              color: 'red',
              fontSize: '12px',
              textAlign: 'left',
              width: '50%',

              paddingLeft: '10px',
            }}
          >
            {form.lastNameValid
              ? ''
              : 'Enter a valid last name! Last name cannot have special characters or numbers.'}
          </p>
        </div>
        {message ? (
          <p className='error-message-2'>Please Fill Both Fields</p>
        ) : (
          ''
        )}

        {/*
           Date Maping 
          {[...Array(n)].map((i,index) => {
       
            return (
                <>
                <label htmlFor={index}>Child {index + 1}</label>
                 <input type="date" id={index} min="2000-01-01"    onChange={changeHandler} className="form-date"/> 
                </>
            )
          })} */}

        <button
          onClick={() => {
            // gtag('event', "stepThree", {
            //   event_category: "signupFlow",
            //   event_label: "submit",
            // });
            handleSignUpName();
            if (
              form.lastNameValid === true &&
              form.nameValid === true &&
              form.name.length > 0 &&
              form.lastName.length > 0
            ) {
              setPage(page + 1);
            } else {
              setMessage(true);
            }
          }}
          formTarget='dummyframe'
          // disabled={
          //   form.lastNameValid === false ||
          //   form.nameValid === false ||
          //   form.name === "" ||
          //   form.lastName === ""
          // }
          className='hoverable fullname-button'
        >
          Continue
        </button>
        <button
          // disabled={disabled}
          onClick={() => {
            setPage(page - 1);
          }}
          className='go-back-box-kitt'
        >
          <BiChevronLeft />
          Go back
        </button>

        <iframe
          name='dummyframe'
          style={{ display: 'none', width: '0', height: '0' }}
          title='dummyframe'
        ></iframe>
      </div>
    </ScrollToTop>
  );
}

export default Fullname;
