import React from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import ScrollToTop from '../Shared/ScrollToTop';
import { v4 as uuidv4 } from 'uuid';
import { dataLayer } from '../../dataLayer';
function StandardBoxes({ form, setForm, page, setPage, sub }) {
  const checked = (id) => {
    const newCards = form.standardBoxes.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isChecked: true,
        };
      } else {
        return {
          ...item,
          isChecked: false,
        };
      }
    });
    setForm({
      ...form,
      standardBoxes: newCards,
    });
  };
  // me filtru se cili box osht checked

  var counter = 0;

  if (form.standardBoxes) {
    // eslint-disable-next-line no-redeclare
    var counter = 0;
    for (var i = 0; i < form.standardBoxes.length; i++) {
      if (form.standardBoxes[i].isChecked === false) {
        counter++;
      }
    }
  }

  function handleClickPlansBirthday(props) {
    var price = props.price.substring(1);
    price = parseFloat(price);

    const userId = uuidv4();
    let itemString = props.id;
    let itemStringConvert = itemString.toString();
    dataLayer.push({ ecommerce: null });

    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        item_list_id: props.title,
        item_list_name: props.title,
        items: [
          {
            item_id: itemStringConvert,
            item_name: props.title,

            discount: props.discount,
            index: props.id,

            price: price,
            quantity: 1,
          },
        ],
      },
    });

    var user_data = {
      user_id: userId, // Add the unique User ID here

      email_address: form.email,
      address: {
        first_name: form.name,
        last_name: form.lastName,
      },
    };
    dataLayer.push({ user_data });
  }

  var params =
    '?name=' +
    form.name +
    '&isGift=' +
    form.gifting +
    '&numberOfChildren=' +
    form.numberOfChildren +
    '&email=' +
    form.email +
    '&medallions=' +
    form.numberOfChildren;

  const link = (props) => {
    var cases = form.standardBoxes[props].type;
    switch (cases) {
      case 'Quarterly':
        window.location.href =
          'https://aw135.infusionsoft.com/app/orderForms/US-Quarterly-Subscription' +
          params;
        break;
      case 'Standard Yearly':
        window.location.href =
          'https://aw135.infusionsoft.com/app/orderForms/US-Standard-Yearly-Subscription' +
          params;
        break;
      case 'Prepay Yearly':
        window.location.href =
          'https://aw135.infusionsoft.com/app/orderForms/US-Prepay-Yearly-Subscription' +
          params;
        break;

      default:
        break;
    }
  };
  return form.standardBoxes ? (
    <ScrollToTop>
      <div className='standard'>
        <h1 className='box-title'>
          {window.location.pathname.includes('homeschooling')
            ? sub.giftingproducts.headerHomeSchooling
            : sub.giftingproducts.header}
        </h1>
        <h2 className='subheading-plans'>FREE U.S. SHIPPING FOR ALL!</h2>

        <div className='standard-boxes'>
          {form.standardBoxes.map((e, index) => (
            <div key={e.id}>
              <div
                className='standard-box hoverable'
                onClick={() => {
                  if (form.numberOfChildren > 1) {
                    setPage(page + 1);
                  } else {
                    link(index);
                  }
                  handleClickPlansBirthday(e);
                  checked(e.id);
                }}
                style={
                  e.isChecked
                    ? {
                        backgroundColor: '#E3F6F8',
                        border: '1px solid #90DAE5',
                        boxShadow: '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        WebkitBoxShadow:
                          '0px 0px 65px 5px rgba(244,222,225,0.18)',
                        MozBoxShadow: '0px 0px 5px 65px rgba(244,222,225,0.18)',
                      }
                    : counter < 3
                    ? { backgroundColor: '#f6f7f8' }
                    : { backgroundColor: '#f6f7f8' }
                }
              >
                {e.kit ? (
                  <p
                    className='product_card_top'
                    dangerouslySetInnerHTML={{
                      __html: e.kit,
                    }}
                  ></p>
                ) : (
                  <p style={{ marginBottom: '44px' }}></p>
                )}
                <div className='product_card_images'>
                  <img src={e.image} alt='cardImage' />
                </div>
                <div className=''>
                  <div className='product_card_header'>
                    <p>{e.title}</p>
                    <p
                      className='product_price'
                      dangerouslySetInnerHTML={{
                        __html: e.price,
                      }}
                    ></p>
                    <p
                      className='product_discount_price'
                      dangerouslySetInnerHTML={{
                        __html: e.discount,
                      }}
                    ></p>
                    {e.price === '$41.99' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: e.kit,
                        }}
                      ></div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    className='product_card_points'
                    dangerouslySetInnerHTML={{
                      __html: e.bottomlabel,
                    }}
                  ></div>
                  <div className='offer-discount'>
                    <p className=''>{e.offer}</p>
                  </div>
                  <div className='product_card_select_button'>Select Plan</div>
                  <div className='product_card_bottom_text'>
                    {e.cancel_text}
                  </div>
                  <div className='plans-save'>{e.save}</div>
                </div>
              </div>
              <div id='shipping-info-mobile'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: e.bottomlabel,
                  }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <h2 className='boxes-h2'>{sub.standardproducts.notice2}</h2>
        <button
          onClick={() => {
            setPage(page - 1);
          }}
          className='go-back-box-plans'
        >
          <BiChevronLeft />
          Go back
        </button>
      </div>
    </ScrollToTop>
  ) : null;
}

export default StandardBoxes;
