import React from "react";

function SecondSection({ secondsection }) {
  return (
    <div className="second-section-hp">
      <div className="main-wrapper homepage-second-section">
        <h1>{secondsection.heading}</h1>
        <h2>{secondsection.paragraph}</h2>
        <div className="second-section-hp-grid">
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.firstbox.image} alt="second" />
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="wellrounded-header">{secondsection.firstbox.heading}</h1>
              <p>{secondsection.firstbox.paragraph}</p>
            </div>
          </div>
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.secondbox.image} alt="second"/>
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="unique-header">{secondsection.secondbox.heading}</h1>
              <p>{secondsection.secondbox.paragraph}</p>
            </div>
          </div>
          <div className="second-section-hp-child">
            <div className="second-section-hp-img">
              <img src={secondsection.thirdbox.image} alt="second"/>
            </div>
            <div className="second-section-hp-child-text">
              <h1 id="unplug-header">{secondsection.thirdbox.heading}</h1>
              <p>{secondsection.thirdbox.paragraph}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondSection;
