import React from 'react';
import '../Components/Shared/Styles/popbox.css';

import header from '../Components/Shared/Assets/Keep Pages/popbox-header.png';
import box1 from '../Components/Shared/Assets/Keep Pages/1-quarterly-subscription-cooper-kid-300x299.jpg';
import box2 from '../Components/Shared/Assets/Keep Pages/yearly-best-value.png';
import box3 from '../Components/Shared/Assets/Keep Pages/gift-one-year-cooper-kid-300x299.jpg';
import giftCard from '../Components/Shared/Assets/Keep Pages/gift-card.png';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Popbox() {
  return (
    <>
      <OfferBanner data={bannerData} />
      <div className='popbox-main'>
        <div className='popbox-main-wrapper'>
          <img src={header} alt='img' className='popbox-header' />

          <div className='popbox-cards-main'>
            <div className='popbox-card'>
              <img src={box1} alt='img' />
              <h1>$65 Per Kit. Free Shipping.</h1>
              <h2>
                Recurring subscription where you get 1 Cooper Kit every 3 months
                billed quarterly. Cancel anytime.
              </h2>
              <h1>Total price: $65 per quarter</h1>
              <h3>
                Save $10. Use <strong>POPBOX</strong> code
              </h3>
              <a
                href='https://aw135.infusionsoft.com/app/orderForms/Popbox-Quarterly'
                target='_blank'
                rel='noreferrer'
              >
                <strong>GET</strong> a Quarterly Subscription
              </a>
              <p>
                The order form may be slow to load. Thank you for your patience.
              </p>
            </div>
            <div className='popbox-card popbox-card-costum'>
              <img src={box2} alt='img' />
              <h1>$59 per Kit. Free Shipping.</h1>
              <h5>Save $24!</h5>
              <h2>
                Recurring subscription where you get 1 Cooper Kit every 3 months
                billed yearly.
              </h2>
              <h1>
                Total price: $236 per year<br></br>
                (paid at once)
              </h1>
              <h3>
                Save $10. Use <strong>POPBOX</strong> code
              </h3>
              <a
                href='https://aw135.infusionsoft.com/app/orderForms/Popbox-Annual'
                target='_blank'
                rel='noreferrer'
              >
                <strong>GET</strong> a Yearly Subscription
              </a>
              <p>
                The order form may be slow to load. Thank you for your patience.
              </p>
            </div>
            <div className='popbox-card'>
              <img src={box3} alt='img' />
              <h1>​$65 per Kit. Free Shipping.</h1>
              <h2>
                One-time fee, no subscription. Receive or gift 1 Cooper Kit
                every 3 months for 1 year.
              </h2>
              <h1>
                Total price: $260 total
                <br></br>(paid at once)
              </h1>
              <h3>
                Save $10. Use <strong>POPBOX</strong> code
              </h3>
              <a
                href='https://aw135.infusionsoft.com/app/orderForms/Popbox-YOK'
                target='_blank'
                rel='noreferrer'
              >
                <strong>GET</strong> a Quarterly Subscription
              </a>
              <p>
                The order form may be slow to load. Thank you for your patience.
              </p>
            </div>
          </div>
          <h1 className='popbox-question'>WHEN WILL I GET MY FUTURE KITS?</h1>
          <p className='popbox-answer'>
            Beyond the kit you take home today, subsequent kits will ship on our
            regular quarterly release schedule in March, June, September, and
            December. Yep. We’ll now have you covered for Spring Break, Father’s
            Day, Back-to-School and the Holiday Season! Get an annual
            subscription and make both fathering AND the holidays fun and easy .
            . . plus save $24.
          </p>
        </div>
        <div className='popbox-giftcard'>
          <img src={giftCard} alt='img' />
          <div className='popbox-giftcard-text'>
            <h1>GIVE A GIFT CARD</h1>
            <h2>
              Give the gift of a family connection. Gift cards are valid towards
              the purchase of any item. Gift cards will be mailed to you from
              our Minneapolis office with free shipping.
            </h2>
            <a
              href='https://aw135.infusionsoft.app/app/orderFormRendering/showLockDownOrderForm?styleThemeId=124&trackInventory=false'
              target='_blank'
              rel='noreferrer'
            >
              $20 Gift Card$50 Gift Card
            </a>
            <p>
              The order form may take a minute to load. <br></br>Thank you for
              your patience.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popbox;
