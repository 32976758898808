import React, { useState, useEffect } from "react";
import "../Styles/navbar.css";
import { Link } from "react-router-dom";
import Logo from "../Assets/Logo.svg";
import { FiPlay } from "react-icons/fi";
import disableScroll from "disable-scroll";
import { Turn as Hamburger } from "hamburger-react";
function Navbar() {
  const [path, setPath] = useState("home");
  const [mobile, setMobile] = useState(false);
  !mobile ? disableScroll.off() : disableScroll.on();

  useEffect(() => {
    if (window.location.pathname === "/peek-inside") {
      setPath("peek-inside");
    } else {
      setPath("home");
    }
    // console.log(window.location.pathname.includes("homeschooling"));
  }, [path]);

  return (
    <>
        {/* <p className="offere-banner">Limited Time Offer: Free Catapult Kit On Sign-Up</p>   */}
      <div className="navbar-main">
    
        <div className="main-wrapper navbar-links">
          <Link
            to={
              window.location.pathname.includes("homeschooling")
                ? "/homeschooling"
                : "/"  &&   window.location.pathname.includes("birthday")
                ? "/birthday"
                : "/" 
            }
            onClick={() => {
              setPath("home");
              setMobile(false);
              // window.location.reload(false);
            }}
            className="hoverable"
          >
            <img src={Logo} alt="logo" className="navbar-logo" />
          </Link>
          <div className="desktop-links">
            <Link
              to={
                window.location.pathname.includes("homeschooling")
                  ? "/homeschooling/peek-inside"
                  : "/peek-inside"
              }
              onClick={() => {
                setPath("peek-inside");
              }}
              className={
                path === "peek-inside" ? "focus hoverable" : "hoverable "
              }
            >
              WHAT YOU GET
            </Link>
            {   window.location.pathname.includes("homeschooling") ? "" : <Link
                  to="/homeschooling"
                >
                 HOMESCHOOLING
                </Link>}
                {  !window.location.pathname.includes("homeschooling") ? "" :    <a
              href="https://cooperandkid.customerhub.net/"
              target="_blank"
              rel="noreferrer"
              className="hoverable"
            >
              LOGIN
            </a>}
            {   window.location.pathname.includes("homeschooling")
                  ?  <Link
                  to={
                    window.location.pathname.includes("homeschooling")
                      ? "/homeschooling/subscribe"
                      : "/subscribe"
                  }
                  className="get-started hoverable"
                  onClick={() => {
                    setPath("get-started");
                  }}
                >
                 GET STARTED
                </Link>
                  : <Link
                  to={
                    window.location.pathname.includes("birthday")
                      ? "/birthday/subscribe"
                      : "/subscribe"
                  }
                  className="get-started hoverable"
                  onClick={() => {
                    setPath("get-started");
                  }}
                >
                 GET STARTED
                </Link>}
                {  window.location.pathname.includes("homeschooling") ? "" :    <a
              href="https://cooperandkid.customerhub.net/"
              target="_blank"
              rel="noreferrer"
              className="hoverable"
            >
              LOGIN
            </a>}
             
          </div>

          <div
            className="burger-icon"
            onClick={() => {
              setMobile(!mobile);
            }}
          >
            <Hamburger className="burger-icon" size={25} color="#601b3a" />
          </div>
        </div>
      </div>
      <div
        className="mobile-navbar"
        style={mobile ? { display: "flex" } : { display: "none" }}
      >
        <Link
          to={
            window.location.pathname.includes("homeschooling")
              ? "/homeschooling/peek-inside"
              : "/peek-inside"
          }
          className="hoverable"
          onClick={() => {
            setMobile(!mobile);
          }}
        >
          WHAT YOU GET
        </Link>
        <Link
          to={"/homeschooling"}
          className="hoverable"
          onClick={() => {
            setMobile(!mobile);
          }}
        >
          Homeschool Program
        </Link>
        <a
          href="https://cooperandkid.customerhub.net/"
          target="_blank"
          className="hoverable"
          rel="noreferrer"
          onClick={() => {
            setMobile(!mobile);
          }}
        >
          LOGIN
        </a>
        <Link
          to={
            window.location.pathname.includes("homeschooling")
              ? "/homeschooling/subscribe"
              : "/subscribe"
          }
          className="get-started hoverable"
          onClick={() => {
            setMobile(!mobile);
          }}
        >
          <FiPlay /> GET STARTED
        </Link>
      </div>
    </>
  );
}

export default Navbar;
