import react from 'react';
import LandingPage from './landingPage';
// import landing from "../Components/Shared/Assets/landing.png"
import '../Components/Shared/Styles/landingPage.css';
import box from '../Components/Shared/Assets/box1.png';
import other2 from '../Components/Shared/Assets/other3.png';
import other3 from '../Components/Shared/Assets/other33.png';
import other4 from '../Components/Shared/Assets/other4.png';
import other5 from '../Components/Shared/Assets/other5.png';
import lib from '../Components/Shared/Assets/lib.png';
import sush from '../Components/Shared/Assets/sush.png';
import fiong from '../Components/Shared/Assets/fiong.png';
import white from '../Components/Shared/Assets/white.png';
import { BsArrowRightShort } from 'react-icons/bs';
import box1 from '../Components/Shared/Assets/box1.png';
import box2 from '../Components/Shared/Assets/box2.png';
import box3 from '../Components/Shared/Assets/box3.png';

import flex from '../Components/Shared/Assets/flex.png';
import pop from '../Components/Shared/Assets/pop.png';
import best from '../Components/Shared/Assets/best.png';
import ea from '../Components/Shared/Assets/ea.png';
import ref from '../Components/Shared/Assets/ref.svg';
import art from '../Components/Shared/Assets/art.png';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const Conference = (props) => {
  // console.log("aa",props.LiveEvents)

  return (
    <div className='full-landing main-wrapper'>
      <img
        src={props.LiveEvents.acf.selfCheckout}
        alt='landing'
        className='self-checkout'
      />
      <div className='landing-message'>
        <AiOutlineExclamationCircle />
        <p>{props.LiveEvents.acf.message}</p>
      </div>

      <div className='art-display'>
        <div className='art-logo'>
          <img src={art} alt='#' />
        </div>
        <div className='landing-badge'>
          <p>Show Special!</p>
          <h3>Deals on ALL Cooper Kit Subscriptions</h3>
          <p className='badge-yellow'>{props.LiveEvents.acf.freeShipping}</p>
          <div className='code-cart'>
            <h1>
              <span>use code</span>WHIRL10
            </h1>
          </div>
        </div>
      </div>
      <h1 className='landing-title'>
        Select Your Family’s Subscription
        <span>FREE U.S. SHIPPING FOR ALL!</span>
      </h1>
      <div className='landing-box conference-landing'>
        {props.LiveEvents.acf.box.map((item, index) => (
          <>
            <a key={index} href={item.link} target='_blank'>
              <img
                className='landing-value landingBadge'
                src={item.badge.url}
              />
              {item.title === 'One Year Gift Package' ? (
                ''
              ) : (
                <img src={ref} alt='#' className='ref' />
              )}
              <div>
                <img src={item.boxImage.url} alt='box' className='change-box' />
                <div className='landig-arrow'>
                  <h1>{item.title}</h1>
                  <BsArrowRightShort />
                </div>
                <div>
                  <p>{item.price}</p>
                  <div className='landin-description'>
                    <p>{item.useCode}</p>
                    <p>{item.bonus}</p>
                    <p>{item.free}</p>
                    <p>{item.pick}</p>
                  </div>{' '}
                  <div
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  ></div>
                  {/* <p>{}</p> */}
                </div>
              </div>
            </a>
          </>
        ))}
      </div>
      <h1 className='otherBox-title'>{props.LiveEvents.acf.otherTitle}</h1>
      <div className='otherbox-display'>
        {props.LiveEvents.acf.otherBox.map((item) => {
          // console.log('item', item.link);
          return (
            <a href={item.link} className='other-box' target='_blank'>
              <img src={item.boxImage.url} alt='' />

              <div className='landig-arrow'>
                <h1>{item.title} </h1>
                <BsArrowRightShort />
              </div>
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
              {/* <p>{item.description} </p> */}
              <p>{item.price} </p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Conference;
