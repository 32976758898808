import React from "react";
// import { Plane } from "react-loader-spinner";
import loaderLeaf from "../Shared/Assets/cooper.json"
import Lottie from "react-lottie-player";
function Loader() {
  return (
    <div className="load-spinner">
      <Lottie
        loop
        animationData={loaderLeaf}
        play
        style={{ width: 450, height: 450 }}
      />
    </div>
  );
}

export default Loader;
