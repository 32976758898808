import LandingPage from './landingPage';
// import landing from "../Components/Shared/Assets/landing.png"
import '../Components/Shared/Styles/landingPage.css';

import { AiOutlineExclamationCircle } from 'react-icons/ai';
import OtherBox from './otherBox';

const FullLandingPage = (props) => {
  // console.log("landing",props.landingPage.acf.otherTitle)
  return (
    <div className='full-landing main-wrapper'>
      <img
        src={props.landingPage.acf.selfCheckout}
        alt='landing'
        className='self-checkout'
      />
      <div className='landing-message'>
        <AiOutlineExclamationCircle />
        <p>{props.landingPage.acf.message}</p>
      </div>

      <div className='landing-badge withCode'>
        <p>Show Special!</p>
        <h3>{props.landingPage.acf.mainheading}</h3>
        <p className='badge-yellow'>{props.landingPage.acf.freeShipping}</p>
        <div>
          <h1>
            <span>use code</span>
            {props.landingPage.acf.code}
          </h1>
        </div>
      </div>
      <h1 className='landing-title'>
        <span>STEP 1</span>
        {props.landingPage.acf.selectTitle}
      </h1>
      <LandingPage landing={props.landingPage} />
      <h1 className='otherBox-title'>{props.landingPage.acf.otherTitle}</h1>
      <OtherBox landing={props.landingPage} />
    </div>
  );
};

export default FullLandingPage;
