import React from 'react';
import Slider from 'react-slick';

import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import star from '../Shared/Assets/Homepage/star.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#C74E70',
        width: '50px',
        height: '40px',
        borderRadius: '10px',
        marginTop: '20px',
      }}
      onClick={onClick}
      className='before-next'
    >
      <FiChevronRight size={25} color='white' />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#C74E70',
        width: '50px',
        height: '40px',
        borderRadius: '10px',
        marginTop: '20px',
      }}
      className='before-prev'
      onClick={onClick}
    >
      <FiChevronLeft size={25} color='white' />
    </div>
  );
}

function SeventhSection({ seventhsection }) {
  const settings = {
    className: 'center',
    infinite: true,
    slidesToShow: 2.33,
    speed: 500,
    dots: true,
    arrows: true,
    slideToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1790,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.66,
          slidesToScroll: 1,

          centerPadding: '180px',

          centerMode: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.66,
          slidesToScroll: 1,

          centerPadding: '80px',

          centerMode: true,
        },
      },
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.65,
          slidesToScroll: 1,

          centerPadding: '70px',

          centerMode: true,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.63,
          slidesToScroll: 1,

          centerPadding: '30px',

          centerMode: true,
        },
      },

      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 1,
          slidesToShow: 1.65,
          slidesToScroll: 1,

          centerPadding: '20px',

          centerMode: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '150px',

          centerMode: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '120px',

          centerMode: true,
        },
      },

      {
        breakpoint: 998,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '50px',

          centerMode: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: false,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: false,
          centerPadding: '0px',
        },
      },
    ],
  };
  return (
    <div className='seventh-section-hp'>
      <h1>What other kids & their grownups say about Cooper & Kid</h1>
      <div className='testimonials-slider'>
        <Slider {...settings}>
          {seventhsection.testimonial.map((e) => (
            <div>
              <div className='seventh-section-slider-card'>
                <div
                  className='section7-slider-img first-costumer'
                  style={{
                    backgroundImage: `url(${e.image})`,
                  }}
                ></div>
                <div className='section7-text-card'>
                  <p>{e.text}</p>
                  <div className='user-info'>
                    <div className='name'>
                      <h3>{e.author}</h3>
                    </div>
                  </div>
                  <div className='testimonial-star'>
                    <img src={star} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default SeventhSection;
