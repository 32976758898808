// import axios from 'axios';
import React, { useEffect } from 'react';
// import ReactHtmlParser from 'react-html-parser';
import Footer from '../Components/Shared/Footer';
import Loader from '../Components/Shared/Loader';
import Navbar from '../Components/Shared/Navbar/Navbar';
import { OfferBanner } from '../layouts/offer-banner/OfferBanner';

const bannerData = {
  text: 'Get a Free Smart Watch with your 1st Cooper Kit!',
  link: '/subscribe',
  style: {
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#90dae5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  },
  linkStyle: { color: '#5c2945', whiteSpace: 'nowrap' },
};

function Thankyou({ body }) {
  // var dvPassport = document.getElementById("infusion-calendar-wrapper");

  useEffect(() => {
    document.getElementById('inf_field_Email').value =
      localStorage.getItem('email');

    for (var i = 0; i < 10; i++) {
      document.getElementsByClassName('infusion-date-input')[i].style.display =
        'none';
      document.getElementsByClassName('calendar-picker-container')[
        i
      ].style.display = 'none';
      // document.getElementsByClassName("calendarLabels")[i].style.display =
      //   "none";
    }
    if (window.localStorage.getItem('isGift') === 'false') {
      document.getElementsByClassName(
        'infusion-field-container'
      )[1].style.display = 'none';
      document.getElementsByClassName(
        'infusion-field-container'
      )[2].style.display = 'none';
      document.getElementsByClassName('text')[3].style.display = 'none';
    } else {
      console.log();
    }
    document.getElementsByClassName('infusion-date-input')[0].style.display =
      document.getElementById('inf_option_3andunder').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[1].style.display =
      document.getElementById('inf_option_4').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[2].style.display =
      document.getElementById('inf_option_5').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[3].style.display =
      document.getElementById('inf_option_6').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[4].style.display =
      document.getElementById('inf_option_7').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[5].style.display =
      document.getElementById('inf_option_8').checked ? 'flex' : 'none';
    document.getElementsByClassName('infusion-date-input')[6].style.display =
      document.getElementById('inf_option_9').checked ? 'flex' : 'none';

    document.getElementsByClassName('infusion-date-input')[7].style.display =
      document.getElementById('inf_option_10').checked ? 'flex' : 'none';

    document.getElementsByClassName('infusion-date-input')[8].style.display =
      document.getElementById('inf_option_11').checked ? 'flex' : 'none';

    document.getElementsByClassName('infusion-date-input')[9].style.display =
      document.getElementById('inf_option_12andolder').checked
        ? 'flex'
        : 'none';
  });

  return body ? (
    <div>
      <OfferBanner data={bannerData} />
      <Navbar />
      {/* {showForm()} */}
      <div
        dangerouslySetInnerHTML={{
          __html: body.acf.form,
        }}
      />
      {/* {addClick()} */}
      <Footer />
    </div>
  ) : (
    <Loader />
  );
}

export default Thankyou;
