import React, { useState } from 'react';
import SwiperCore, { Virtual, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import FirstTab from './Tabs/Tab1';
import SecondTab from './Tabs/Tab2';
import ThirdTab from './Tabs/Tab3';
import FourthTab from './Tabs/Tab4';
import FifthTab from './Tabs/Tab5';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

SwiperCore.use([Virtual, Navigation, Pagination]);
function FifthSection({ promise2, section5 }) {
  const [tab, setTab] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  // const showTab = (e) => {
  //   if (tab[1]) {
  //     return <FirstTab tab1={e.tab1} />;
  //   } else if (tab[2]) {
  //     return <SecondTab tab2={e.tab2} />;
  //   } else if (tab[3]) {
  //     return <ThirdTab tab3={e.tab3} />;
  //   } else if (tab[4]) {
  //     return <FourthTab tab4={e.tab4} />;
  //   } else if (tab[5]) {
  //     return <FifthTab tab5={e.tab5} />;
  //   }
  // };
  const [swiperRef, setSwiperRef] = useState(null);

  // const [slides, setSlides] = useState(
  //   Array.from({ length: 500 }).map((_, index) => `Slide ${index + 1}`)
  // );

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };

  return (
    <div ref={promise2}>
      {/* <div className='fifth-section-pk fifth-section-pk-desktop'>
        <div className='main-wrapper pk-fifth-section'>
          <h1>{section5.subheading}</h1>
          <h2 className='pk-fifth-section-h2'>{section5.heading}</h2>
          <h3>{section5.subheading2}</h3>
          <div className='promises-tabs'>
            <div
              className={tab[1] ? 'tab' : 'tab tabsOpacity'}
              id='tab1'
              onClick={() => {
                setTab({
                  ...tab,
                  1: true,
                  2: false,
                  3: false,
                  4: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab1images.image1.url} alt='img' />
              <img src={section5.tab1images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>
            <div
              className={tab[2] ? 'tab' : 'tab tabsOpacity'}
              id='tab2'
              onClick={() => {
                setTab({
                  ...tab,
                  2: true,
                  1: false,
                  3: false,
                  4: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab2images.image1.url} alt='img' />
              <img src={section5.tab2images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>

            <div
              className={tab[3] ? 'tab' : 'tab tabsOpacity'}
              id='tab3'
              onClick={() => {
                setTab({
                  ...tab,
                  3: true,
                  2: false,
                  1: false,
                  4: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab3images.image1.url} alt='img' />
              <img src={section5.tab3images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>

            <div
              className={tab[4] ? 'tab' : 'tab tabsOpacity'}
              id='tab4'
              onClick={() => {
                setTab({
                  ...tab,
                  4: true,
                  2: false,
                  3: false,
                  1: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab4images.image1.url} alt='img' />
              <img src={section5.tab4images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>

            <div
              className={tab[5] ? 'tab' : 'tab tabsOpacity'}
              id='tab5'
              onClick={() => {
                setTab({
                  ...tab,
                  5: true,
                  2: false,
                  3: false,
                  4: false,
                  1: false,
                });
              }}
            >
              <img src={section5.tab5images.image1.url} alt='img' />
              <img src={section5.tab5images.image2.url} alt='img' />
            </div>
          </div>
          {showTab(section5)}
        </div>
      </div> */}
      <div className='fifth-section-pk fifth-section-pk-mobile'>
        <div className='main-wrapper pk-fifth-section'>
          <h1>{section5.subheading}</h1>
          <h2 className='pk-fifth-section-h2'>{section5.heading}</h2>
          <h3>{section5.subheading2}</h3>
          <div className='promises-tabs'>
            <div
              className={tab[1] ? 'tab' : 'tab tabsOpacity'}
              id='tab1'
              onClick={() => {
                setTab({
                  ...tab,
                  1: true,
                  2: false,
                  3: false,
                  4: false,
                  5: false,
                });
                slideTo(0);
              }}
            >
              <img src={section5.tab1images.image1.url} alt='img' />
              <img src={section5.tab1images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>
            <div
              className={tab[2] ? 'tab' : 'tab tabsOpacity'}
              id='tab2'
              onClick={() => {
                slideTo(2);
                setTab({
                  ...tab,
                  1: false,
                  2: true,
                  3: false,
                  4: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab2images.image1.url} alt='img' />
              <img src={section5.tab2images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>
            <div
              className={tab[3] ? 'tab' : 'tab tabsOpacity'}
              id='tab3'
              onClick={() => {
                slideTo(3);
                setTab({
                  ...tab,
                  1: false,
                  2: false,
                  3: true,
                  4: false,
                  5: false,
                });
              }}
            >
              <img src={section5.tab3images.image1.url} alt='img' />
              <img src={section5.tab3images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>
            <div
              className={tab[4] ? 'tab' : 'tab tabsOpacity'}
              id='tab4'
              onClick={() => {
                slideTo(4);
                setTab({
                  ...tab,
                  1: false,
                  2: false,
                  3: false,
                  4: true,
                  5: false,
                });
              }}
            >
              <img src={section5.tab4images.image1.url} alt='img' />
              <img src={section5.tab4images.image2.url} alt='img' />
            </div>
            <div className='dots'></div>
            <div
              className={tab[5] ? 'tab' : 'tab tabsOpacity'}
              id='tab5'
              onClick={() => {
                slideTo(5);
                setTab({
                  ...tab,
                  1: false,
                  2: false,
                  3: false,
                  4: false,
                  5: true,
                });
              }}
            >
              <img src={section5.tab5images.image1.url} alt='img' />
              <img src={section5.tab5images.image2.url} alt='img' />
            </div>
          </div>

          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={30}
            // pagination={{
            //   type: 'bullets',
            // }}
            navigation={true}
            virtual
            onSlideChange={(swiper) => {
              slideTo(swiper.activeIndex + 1);

              setTab({
                [(swiper.activeIndex % 5) + 1]: true,
                [((swiper.activeIndex + 1) % 5) + 1]: false,
                [((swiper.activeIndex + 2) % 5) + 1]: false,
                [((swiper.activeIndex + 3) % 5) + 1]: false,
                [((swiper.activeIndex + 4) % 5) + 1]: false,
              });
            }}
          >
            <SwiperSlide key={0} virtualIndex={0}>
              <FirstTab tab1={section5.tab1} />
            </SwiperSlide>
            <SwiperSlide key={1} virtualIndex={1}>
              <SecondTab tab2={section5.tab2} />
            </SwiperSlide>
            <SwiperSlide key={2} virtualIndex={2}>
              <ThirdTab tab3={section5.tab3} />
            </SwiperSlide>
            <SwiperSlide key={3} virtualIndex={3}>
              <FourthTab tab4={section5.tab4} />
            </SwiperSlide>
            <SwiperSlide key={4} virtualIndex={4}>
              <FifthTab tab5={section5.tab5} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default FifthSection;
