import React from "react";
function FirstSection({ about }) {
  return (
    <div className="about-firstSection">
      <h1>{about.mainheading}</h1>
      <div className="about-firstSection-text">
        <h1>
          <span>{about.questionmainsub}</span>
          <br></br> {about.questionmain}
        </h1>
        <p>{about.paragraphmain}</p>
      </div>
      <div
        className="first-banner-image-about"
        style={{ backgroundImage: `url(${about.imagemain})` }}
      ></div>
    </div>
  );
}

export default FirstSection;
